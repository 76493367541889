import { WidgetComponent } from "forms/components/widget";
import classes from "./FileInput.module.scss";
import EmptyImage from "assets/img/empty-image.png";
import PlusStroke from "components/ui/icons/PlusStroke";
import PlusFill from "components/ui/icons/PlusFill";
import Redact from "components/ui/icons/Redact";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useEffect, useState } from "react";

const FileInput = ({
  name,
  value,
  inputArgs,
  onChange,
  required,
  onSubmit,
  resDataAfterSubmit,
  form,
}: WidgetComponent) => {
  const elId = `id_${name}`;

  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
  const [dataAfterSubmit, setDataAfterSubmit] = useState<string | null>(null);
  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const withOutPreload = form === "createProjectForm";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files);
      onChange(selectedFile);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      onChange(selectedFile);
    }
  }, [selectedFile]);

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      setIsLoading(false);
      const { data, errorMessage } = resDataAfterSubmit;
      if (
        Object.entries(errorMessage).length !== 0 &&
        errorMessage.constructor === Object
      ) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      } else if (
        Object.entries(data).length !== 0 &&
        data.constructor === Object
      ) {
        if (data[name]) setDataAfterSubmit(data[name]);
      }
    }
  }, [resDataAfterSubmit]);

  useEffect(() => {
    if (selectedFile) {
      setDataAfterSubmit(null);
      setErrorAfterSubmit(null);
      if (!withOutPreload) {
        setIsLoading(true);
        onSubmit?.({ data: selectedFile });
      }
    }
  }, [selectedFile]);

  return (
    <section className={[classes.container, classes[name]].join(" ")}>
      <input
        form={form}
        className={classes.input}
        name={name}
        id={withOutPreload ? "createProjectIcon" : elId}
        onChange={handleChange}
        required={required}
        {...(inputArgs || {})}
        type="file"
      ></input>
      <label
        htmlFor={withOutPreload ? "createProjectIcon" : elId}
        className={
          isLoading ? [classes.label, classes.loading].join(" ") : classes.label
        }
      >
        {form === "createProjectForm" ? (
          selectedFile ? (
            <div className={classes["label__image-container"]}>
              <img
                className={classes.label__image}
                src={URL.createObjectURL(selectedFile[0])}
                alt=""
              />
            </div>
          ) : (
            <div className={classes["label__icon-container"]}>
              <PlusStroke clazz={classes.label__icon} />
            </div>
          )
        ) : (
          <>
            <img
              src={
                dataAfterSubmit
                  ? dataAfterSubmit
                  : value && typeof value === "string"
                    ? value
                    : EmptyImage
              }
              alt=""
            />
            {dataAfterSubmit || value ? (
              <div className={classes.redact + " redact-field"}>
                <Redact />
              </div>
            ) : (
              <PlusFill />
            )}
          </>
        )}
      </label>
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent
            key={idx}
            clazz={
              form ? [classes.error, classes[form]].join(" ") : classes.error
            }
          >
            {i}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default FileInput;
