import Arrow from "components/ui/icons/Arrow";
import { OpenedBlocksContext } from "context/OpenedBlocks";
import classes from "./ArrowButton.module.scss";
import { useContext } from "react";

type arrowButtonProps = {
  changeFormState: () => void;
  changeProfileState: () => void;
  isFormOpen: boolean;
};

const ArrowButton = ({
  changeFormState,
  changeProfileState,
  isFormOpen,
}: arrowButtonProps) => {
  const { isBlockOpen } = useContext(OpenedBlocksContext);

  const classNameButton = isBlockOpen.isProfileOpen
    ? [classes.arrow, classes["arrow--open"]].join(" ")
    : classes.arrow;

  const buttonHandler = () => {
    if (isFormOpen) {
      changeFormState();
      changeProfileState();
    } else {
      changeProfileState();
    }
  };

  return (
    <button
      type="button"
      className={classNameButton}
      onClick={() => buttonHandler()}
    >
      <Arrow clazz={classes.arrow__icon} />
    </button>
  );
};

export default ArrowButton;
