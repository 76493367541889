interface IconProps {
  clazz?: string;
}

const Clock2 = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4999 10.0297C17.5 10.0198 17.5 10.0099 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1223 17.5 17.4679 14.1742 17.4998 10.0593C17.4999 10.0528 17.5 10.0463 17.5 10.0398C17.5 10.0364 17.5 10.033 17.4999 10.0297ZM15.7798 9.20604C15.4296 6.63271 13.4012 4.59304 10.8333 4.22574V9.20494L15.7798 9.20604ZM9.16667 4.22574V10.0381C9.16667 10.4983 9.53965 10.8713 9.99981 10.8714L15.7685 10.8727C15.3473 13.6804 12.9251 15.8333 10 15.8333C6.77834 15.8333 4.16667 13.2217 4.16667 10C4.16667 7.06127 6.33975 4.6301 9.16667 4.22574Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Clock2;
