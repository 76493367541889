import { useEffect, useState } from "react";
import classes from "./PlanningManager.module.scss";
import Filters from "./filters/Filters";
import Main from "./main/Main";
import Supervisor from "./supervisor/Supervisor";
import { TPageInfo } from "../dto";
import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import { useSearchParams } from "react-router-dom";
import { monday, sunday } from "helpers/currentWeek";
import { createParams } from "helpers/createParams";

const PlanningManager = () => {
  const [isSupervisorEditUserEmployment, setIsSupervisorEditUserEmployment] =
    useState(false);
  const [pageInfo, setPageInfo] = useState<TPageInfo | null>(null);
  const [searchParams] = useSearchParams();

  const { apiGet } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const pageInfoParams = createParams(searchParams, {
        date_start: searchParams.get("date_start") || monday,
        date_end: searchParams.get("date_end") || sunday,
      });
      
      const pageInfoRes = await apiGet(endpoints.planningTotal, pageInfoParams);
      setPageInfo(pageInfoRes.data);
    };

    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <Filters
        isSupervisorEditUserEmployment={isSupervisorEditUserEmployment}
        setIsSupervisorEditUserEmployment={setIsSupervisorEditUserEmployment}
      />
      {isSupervisorEditUserEmployment ? (
        <Supervisor pageInfo={pageInfo} />
      ) : (
        <Main pageInfo={pageInfo} />
      )}
    </div>
  );
};

export default PlanningManager;
