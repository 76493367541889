interface IconProps {
  clazz?: string;
}

const AZ = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="10"
      height="21"
      viewBox="0 0 10 21"
      fill="none"
    >
      <path d="M8.20817 9H10L5.83663 0H4.1502L0 9H1.76548L2.67457 6.91714H7.2859L8.20817 9ZM3.25428 5.60571L4.98024 1.67143L6.71937 5.60571H3.25428Z" />
      <path d="M3.44902 19.3146L9.82646 13.3315V12H0.130152V13.6854H6.35575L0 19.6685V21H10V19.3146H3.44902Z" />
    </svg>
  );
};

export default AZ;
