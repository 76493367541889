import classes from "./ProjectsItem.module.scss";
import { Link } from "react-router-dom";
import EmptyImage from "assets/img/empty-image.png";

interface ProjectsItemProps {
  project: {
    name: string;
    icon?: string;
    id: number;
    date_start: string;
    date_end: string;
    planned_hours: string;
    spent_hours: string;
  };
  isSingleColumn: boolean;
}

const ProjectsItem = ({ project, isSingleColumn }: ProjectsItemProps) => {
  return (
    <Link to={`/projects/${project.id}`}>
      <div
        className={
          isSingleColumn
            ? [classes.container, classes["single--column"]].join(" ")
            : classes.container
        }
      >
        <div className={classes.description}>
          <span className={classes.description__title}>{project.name}</span>
          <div className={classes["description__image-wrapper"]}>
            <img
              src={project.icon ? project.icon : EmptyImage}
              alt=""
              className={classes.description__image}
            />
          </div>
        </div>
        {isSingleColumn ? (
          <div className={classes.logging}>
            <p className={classes.logging__text}>
              Отработанное время: {project.spent_hours || "-"} ч.
            </p>
            <p className={classes.logging__text}>
              Запланированное время: {project.planned_hours || "-"} ч.
            </p>
          </div>
        ) : null}
        <div className={classes.planning}>
          <p className={classes.planning__text}>
            Дата начала: {project.date_start}
          </p>
          <p className={classes.planning__text}>
            Дата окончания: {project.date_end ? project.date_end : "В работе"}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectsItem;
