interface IconProps {
  clazz?: string;
}

const PenIcon = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26144 1.17159C10.1557 0.275964 11.6062 0.275415 12.5011 1.17037L14.5777 3.24691C15.465 4.13423 15.4742 5.5707 14.5983 6.46934L6.90405 14.3637C6.31611 14.9669 5.50973 15.3071 4.6677 15.3071L2.37424 15.307C1.3082 15.3069 0.456858 14.4183 0.501693 13.3524L0.600155 11.0115C0.63307 10.229 0.958302 9.48731 1.5115 8.93328L9.26144 1.17159ZM11.6179 2.05492C11.2111 1.64812 10.5518 1.64837 10.1453 2.05548L8.75942 3.44348L12.3259 7.00997L13.7038 5.5962C14.102 5.18773 14.0978 4.53479 13.6944 4.13146L11.6179 2.05492ZM2.39538 9.81716L7.87621 4.32803L11.4534 7.9052L6.00956 13.4906C5.6568 13.8525 5.17297 14.0566 4.66775 14.0566L2.3743 14.0565C2.01895 14.0565 1.73517 13.7603 1.75011 13.405L1.84858 11.0641C1.86832 10.5946 2.06347 10.1496 2.39538 9.81716ZM15.0957 15.246C15.4407 15.246 15.7204 14.966 15.7204 14.6207C15.7204 14.2754 15.4407 13.9955 15.0957 13.9955H9.99426C9.64921 13.9955 9.3695 14.2754 9.3695 14.6207C9.3695 14.966 9.64921 15.246 9.99426 15.246H15.0957Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PenIcon;
