export const splitDate = (date: string) => {
  const splitedDate = date.split(".").reverse().join("-");
  return splitedDate;
};

export const newDateFormat = (date: string) => {
  const formattedToNewDate = new Date(splitDate(date));
  return formattedToNewDate;
};

export const dateToRFC = (date: Date) => {
  const dateTimezoneCorrection = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  );
  const toRFC = `${dateTimezoneCorrection.toISOString().split(".")[0]}Z`;
  return toRFC;
};
