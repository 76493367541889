import { previousMonday, addDays, isMonday } from "date-fns";

export function getCheckedWeek(startDate: Date) {
  const weekStart = isMonday(startDate) ? startDate : previousMonday(startDate);
  const days = [];

  for (let i = 0; i <= 6; i++) {
    days.push(addDays(weekStart, i));
  }
  return days;
}
