interface IconProps {
  clazz?: string;
}

const Lock = ({ clazz }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clazz}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 13C3.25 10.3766 5.37665 8.25 8 8.25H16C18.6234 8.25 20.75 10.3766 20.75 13V17C20.75 19.6234 18.6234 21.75 16 21.75H8C5.37665 21.75 3.25 19.6234 3.25 17V13ZM8 9.75C6.20507 9.75 4.75 11.2051 4.75 13V17C4.75 18.7949 6.20507 20.25 8 20.25H16C17.7949 20.25 19.25 18.7949 19.25 17V13C19.25 11.2051 17.7949 9.75 16 9.75H8Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.25C12.4142 13.25 12.75 13.5858 12.75 14L12.75 16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16L11.25 14C11.25 13.5858 11.5858 13.25 12 13.25Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 7C7.25 4.37665 9.37665 2.25 12 2.25C14.6234 2.25 16.75 4.37665 16.75 7V9H15.25V7C15.25 5.20507 13.7949 3.75 12 3.75C10.2051 3.75 8.75 5.20507 8.75 7V9H7.25V7Z"
        fill="#181818"
      />
    </svg>
  );
};

export default Lock;
