interface IconProps {
  clazz?: string;
}

const Display = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1404_57864)">
        <path
          d="M17.5 10.625V13.75H2.5V3.75H8.75V2.5H2.5C2.16848 2.5 1.85054 2.6317 1.61612 2.86612C1.3817 3.10054 1.25 3.41848 1.25 3.75V13.75C1.25 14.0815 1.3817 14.3995 1.61612 14.6339C1.85054 14.8683 2.16848 15 2.5 15H7.5V17.5H5V18.75H15V17.5H12.5V15H17.5C17.8315 15 18.1495 14.8683 18.3839 14.6339C18.6183 14.3995 18.75 14.0815 18.75 13.75V10.625H17.5ZM11.25 17.5H8.75V15H11.25V17.5Z"
          fill="#8A8CD9"
        />
        <path
          d="M18.75 6.25V5H17.4368C17.3562 4.60879 17.2009 4.2368 16.9794 3.90444L17.9106 2.97319L17.0269 2.08944L16.0956 3.02069C15.7632 2.79913 15.3912 2.64379 15 2.56319V1.25H13.75V2.56319C13.3588 2.64378 12.9868 2.7991 12.6544 3.02063L11.7232 2.08937L10.8394 2.97312L11.7707 3.90438C11.5491 4.23675 11.3938 4.60876 11.3132 5H10V6.25H11.3132C11.3938 6.64121 11.5491 7.0132 11.7706 7.34556L10.8394 8.27681L11.7231 9.16056L12.6544 8.22931C12.9868 8.45087 13.3588 8.60621 13.75 8.68681V10H15V8.68681C15.3912 8.60622 15.7632 8.4509 16.0956 8.22937L17.0268 9.16062L17.9106 8.27688L16.9793 7.34562C17.2009 7.01325 17.3562 6.64124 17.4368 6.25H18.75ZM14.375 7.5C14.0042 7.5 13.6416 7.39003 13.3333 7.18401C13.025 6.97798 12.7846 6.68514 12.6427 6.34253C12.5008 5.99992 12.4637 5.62292 12.536 5.25921C12.6084 4.89549 12.787 4.5614 13.0492 4.29917C13.3114 4.03695 13.6455 3.85838 14.0092 3.78603C14.3729 3.71368 14.7499 3.75081 15.0925 3.89273C15.4351 4.03464 15.728 4.27496 15.934 4.58331C16.14 4.89165 16.25 5.25416 16.25 5.625C16.2495 6.12211 16.0517 6.59871 15.7002 6.95022C15.3487 7.30173 14.8721 7.49945 14.375 7.5Z"
          fill="#8A8CD9"
        />
      </g>
      <defs>
        <clipPath id="clip0_1404_57864">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Display;
