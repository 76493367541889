interface IconProps {
  clazz?: string;
}

const Close = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="white" />
      <path
        d="M6 9.99988L10 6M6 6.00012L10 10"
        stroke="#00C2FA"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Close;
