interface IconProps {
  clazz?: string;
}

const Arrow2 = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16 16L19.2929 12.7071C19.6834 12.3166 19.6834 11.6834 19.2929 11.2929L16 8M19 12L4.99998 12"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Arrow2;
