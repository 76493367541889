interface IconProps {
  clazz?: string;
}

const UserListIcon = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8927 11.381C12.8927 13.4323 11.2298 15.0953 9.17842 15.0953C7.12708 15.0953 5.46414 13.4323 5.46414 11.381C5.46414 9.32963 7.12708 7.66669 9.17842 7.66669C11.2298 7.66669 12.8927 9.32963 12.8927 11.381ZM16.9167 10.1428C16.9167 9.63 17.3324 9.21427 17.8452 9.21427H27.75C28.2628 9.21427 28.6786 9.63 28.6786 10.1428C28.6786 10.6557 28.2628 11.0714 27.75 11.0714H17.8452C17.3324 11.0714 16.9167 10.6557 16.9167 10.1428ZM16.9167 16.3333C16.9167 15.8205 17.3324 15.4048 17.8452 15.4048H27.75C28.2628 15.4048 28.6786 15.8205 28.6786 16.3333C28.6786 16.8462 28.2628 17.2619 27.75 17.2619H17.8452C17.3324 17.2619 16.9167 16.8462 16.9167 16.3333ZM20.3213 21.5953C19.8084 21.5953 19.3927 22.011 19.3927 22.5238C19.3927 23.0367 19.8084 23.4524 20.3213 23.4524H27.7499C28.2627 23.4524 28.6784 23.0367 28.6784 22.5238C28.6784 22.011 28.2627 21.5953 27.7499 21.5953H20.3213ZM9.17857 25C12.5975 25 15.3691 23.337 15.3691 21.2857C15.3691 19.2344 12.5975 17.5714 9.17857 17.5714C5.75967 17.5714 2.9881 19.2344 2.9881 21.2857C2.9881 23.337 5.75967 25 9.17857 25Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default UserListIcon;
