interface IconProps {
  clazz?: string;
}

const AddUser = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 7C13.25 5.20507 11.7949 3.75 10 3.75C8.20507 3.75 6.75 5.20507 6.75 7C6.75 8.79493 8.20507 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7ZM10 2.25C12.6234 2.25 14.75 4.37665 14.75 7C14.75 9.62335 12.6234 11.75 10 11.75C7.37665 11.75 5.25 9.62335 5.25 7C5.25 4.37665 7.37665 2.25 10 2.25ZM3.75 16.9347C3.75 16.3913 4.0916 15.9065 4.60334 15.7238C8.09317 14.4774 11.9068 14.4774 15.3967 15.7238C15.9084 15.9065 16.25 16.3913 16.25 16.9347V18.2502C16.25 18.9812 15.6025 19.5427 14.8789 19.4394L13.9244 19.303C11.3214 18.9312 8.67864 18.9312 6.07556 19.303L5.12114 19.4394C4.39746 19.5427 3.75 18.9812 3.75 18.2502V16.9347ZM15.9012 14.3112C12.0851 12.9483 7.91491 12.9483 4.09884 14.3112C2.99011 14.7071 2.25 15.7574 2.25 16.9347V18.2502C2.25 19.894 3.70594 21.1568 5.33327 20.9243L6.28769 20.7879C8.75006 20.4362 11.2499 20.4362 13.7123 20.7879L14.6667 20.9243C16.2941 21.1568 17.75 19.894 17.75 18.2502V16.9347C17.75 15.7574 17.0099 14.7071 15.9012 14.3112ZM21.75 11C21.75 11.4142 21.4142 11.75 21 11.75H19.75V13C19.75 13.4142 19.4142 13.75 19 13.75C18.5858 13.75 18.25 13.4142 18.25 13V11.75H17C16.5858 11.75 16.25 11.4142 16.25 11C16.25 10.5858 16.5858 10.25 17 10.25H18.25V9C18.25 8.58579 18.5858 8.25 19 8.25C19.4142 8.25 19.75 8.58579 19.75 9V10.25H21C21.4142 10.25 21.75 10.5858 21.75 11Z"
      />
    </svg>
  );
};

export default AddUser;
