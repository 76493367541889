import { useState, useContext } from "react";
import Button from "components/ui/button/Button";
import NumberInput from "forms/components/simpleWidgets/number-input/NumberInput";
import { UserInfoContext } from "context/UserInfoContext";
import classes from "../ManegementForms.module.scss";

type RowsSachType = {
  date: string;
  editor: string;
  price: string;
  time: string;
};

const FormUserSach = ({ data }: { data?: RowsSachType[] }) => {
  const [rows, setRows] = useState(data || []);
  const { userInfoContext } = useContext(UserInfoContext);
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");

  const onAddItem = () => {
    if (price !== "" && time !== "") {
      setRows((prev) => [
        ...prev,
        {
          date: "10.02.2023",
          editor: userInfoContext?.data.first_name || "unset",
          price: price,
          time: time,
        },
      ]);
      setPrice("");
      setTime("");
    }
  };

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div
          className={`${classes.formItem} ${classes.pd32}`}
          data-type="user-sach"
        >
          <h3 className={classes.subtitle}>Стоимость административного часа</h3>
          <div className={classes.fieldsSach}>
            <NumberInput
              name={"price"}
              value={price}
              onChange={(text) => setPrice(text)}
              label="Сумма"
              theme="outline"
              placeholder="Введите сумму"
            />
            <NumberInput
              name={"time"}
              value={time}
              onChange={(text) => setTime(text)}
              label="Часы от полной ставки"
              placeholder="В часах"
              theme="outline"
            />
            <Button
              type="button"
              clazz={classes.buttonAdd}
              theme="fill"
              onClick={onAddItem}
            >
              Подтвердить
            </Button>
          </div>
          {rows.length > 0 && (
            <div className={classes.tableWrap}>
              <h3 className={classes.subtitle}>История изменений</h3>
              <table className={classes.tabel}>
                <tr>
                  <th>Дата</th>
                  <th>Кем изменено</th>
                  <th>Стоимость</th>
                </tr>
                {rows.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.editor}</td>
                    <td>{item.price} ₽</td>
                  </tr>
                ))}
              </table>
            </div>
          )}
        </div>
      </form>
      <div className={classes.actions}>
        <Button type="button" clazz={classes.button} theme="fill">
          Создать
        </Button>
      </div>
    </div>
  );
};

export default FormUserSach;
