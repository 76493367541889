//TODO Pass all requests with a token through this hook to handle 401 code in axios config

import axios from "axios";
import { getArrayError, getFormError } from "helpers/getResponseErrors";
import jwtDecode from "jwt-decode";
import { ApiDataType, TokenType } from "types";

export interface RestMethodType {
  (
    url: string,
    data?: object | [] | string | number,
    params?: object,
    form?: boolean,
    headers?: object,
  ): Promise<ApiDataType>;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
  }

  export interface AxiosResponseEror {
    response: { data: unknown; status: number };
  }
}

axios.interceptors.response.use(undefined, (err) => {
  const {
    config,
    message,
    response: { status },
  } = err;

  if (!config || !config.retry) {
    return Promise.reject(err);
  }

  if (!(message.includes("Network Error") || status === 401)) {
    return Promise.reject(err);
  }

  config.retry -= 1;
  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      if (status === 401) {
        const accessToken = localStorage.getItem("accessToken");
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});

export const useApi = () => {
  const apiGet: RestMethodType = async (url, params) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const decodedRefreshToken = refreshToken
        ? jwtDecode<TokenType>(refreshToken)
        : false;
      const isValidRefreshToken = decodedRefreshToken
        ? Date.now() < decodedRefreshToken.exp * 1000
        : false;

      const headers = Object.assign(
        {},
        isValidRefreshToken &&
          accessToken && { Authorization: `Bearer ${accessToken}` },
      );

      const res = await axios.get(url, {
        headers: headers ? headers : undefined,
        params,
        retry: 3,
        retryDelay: 1000,
      });
      return {
        data: res.data,
        errorMessage: [],
      };
    } catch (err) {
      return getArrayError(err);
    }
  };

  const apiPost: RestMethodType = async (url, data, params, form, headers) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const res = await axios.post(url, data, {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        retry: 3,
        retryDelay: 1000,
      });
      return {
        data: res.data,
        errorMessage: [],
      };
    } catch (err) {
      if (form) {
        return getFormError(err);
      }
      return getArrayError(err);
    }
  };

  const apiPut: RestMethodType = async (url, data, params, form) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const res = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        retry: 3,
        retryDelay: 1000,
      });
      return {
        data: res.data,
        errorMessage: [],
      };
    } catch (err) {
      if (form) {
        return getFormError(err);
      }
      return getArrayError(err);
    }
  };

  const apiPatch: RestMethodType = async (url, data, params, form, headers) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const res = await axios.patch(url, data, {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        retry: 3,
        retryDelay: 1000,
      });
      return {
        data: res.data,
        errorMessage: [],
      };
    } catch (err) {
      if (form) {
        return getFormError(err);
      }
      return getArrayError(err);
    }
  };

  const apiDelete: RestMethodType = async (url, params) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const res = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        retry: 3,
        retryDelay: 1000,
      });
      return {
        data: res.data,
        errorMessage: [],
      };
    } catch (err) {
      return getArrayError(err);
    }
  };

  return {
    apiGet,
    apiPut,
    apiPatch,
    apiPost,
    apiDelete,
  };
};
