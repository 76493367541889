import { PlannedInfo } from "components/planning/dto";
import { createContext } from "react";

interface PlannedInfoContextType {
  plannedInfo: PlannedInfo[] | null;
  setPlannedInfo: React.Dispatch<
    React.SetStateAction<PlannedInfo[] | null>
  > | null;
}

const defaultValuesPlannedInfo = {
  setPlannedInfo: null,
  plannedInfo: null,
};

export const PlannedInfoContext = createContext<PlannedInfoContextType>(
  defaultValuesPlannedInfo,
);
