interface IconProps {
  clazz?: string;
}

const DoubleLine = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3 7H13"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 11H13"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default DoubleLine;
