import classes from "./ProjectInfo.module.scss";
import EmptyImage from "assets/img/empty-image.png";
import Clock from "components/ui/icons/Clock";
import User from "components/ui/icons/User";
import Display from "components/ui/icons/Display";
import { TProjectInfo } from "components/planning/dto";

interface Props {
  data: TProjectInfo;
}

const ProjectInfo = ({ data }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.project}>
        <img src={data.icon ? data.icon : EmptyImage} alt="avatar" />
        <div className={classes.project__info}>
          <div className={classes.project__name}>{data.name}</div>
          <div className={classes.project__manager}>
            {`Менеджер: ${data.manager}`}
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.employees}>
          <User clazz={classes.employees__icon} />
          <span className={classes.employees__text}>
            {data.involved_employee_count}
          </span>
        </div>
        <div className={classes.planned_hours}>
          <Clock clazz={classes.planned_hours__icon} />
          <span
            className={classes.planned_hours__text}
          >{`${data.employment_next_time_unit} час`}</span>
        </div>
        <div className={classes.progress_container}>
          <div className={classes.progress}>
            <div
              className={classes["progress--bar"]}
              style={{
                width: `${
                  data.employment_percentage !== null &&
                  data.employment_percentage <= 100
                    ? data.employment_percentage
                    : 100
                }%`,
              }}
            ></div>
          </div>
          <div
            className={classes["progress--text"]}
          >{`${data.employment_current_time_unit} час`}</div>
        </div>
        <div className={classes.type}>
          <Display clazz={classes.type__icon} />
          <span className={classes.type__text}>{data.type}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
