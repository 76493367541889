type Props = {
  className: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: () => void;
  children: React.ReactNode | React.ReactNode[];
};

const TableInputContainer = ({
  className,
  onFocus,
  onBlur,
  onKeyDown,
  children,
}: Props) => {
  return (
    <div
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.nativeEvent.code === "Enter" && onKeyDown) onKeyDown();
      }}
    >
      {children}
    </div>
  );
};

export default TableInputContainer;
