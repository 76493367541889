export const createParams = (
  searchParams: URLSearchParams,
  params: Record<string, string>,
) => {
  const newSearchParams = new URLSearchParams(searchParams);
  Object.keys(params).forEach((key) => {
    if (params[key] === "all") {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, params[key]);
    }
  });
  return newSearchParams;
};
