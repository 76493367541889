import axios from "axios";
import { getFormError } from "helpers/getResponseErrors";
import { endpoints } from "API/endpoints";
import { FormDataType } from "types";

class AuthApi {
  static async login(data: FormDataType) {
    try {
      const response = await axios.post(endpoints.urlToken, data);
      return response;
    } catch (e: unknown) {
      return getFormError(e);
    }
  }

  static async getConfirmCode(data: FormDataType) {
    try {
      const response = await axios.post(endpoints.urlGetConfirmCode, data);
      return response;
    } catch (e: unknown) {
      return getFormError(e);
    }
  }

  static async verifyConfirmCode(data: FormDataType) {
    try {
      const response = await axios.post(endpoints.urlVerifyConfirmCode, data);
      return response;
    } catch (e: unknown) {
      return getFormError(e);
    }
  }

  static async resetPassword(data: FormDataType) {
    try {
      const response = await axios.post(endpoints.urlResetPassword, data);
      return response;
    } catch (e: unknown) {
      return getFormError(e);
    }
  }
}

export { AuthApi };
