interface IconProps {
  clazz?: string;
}

const Сalendar2 = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8.83334H15.4667V12.8333C15.4667 14.6743 13.9594 16.1667 12.1 16.1667H5.36667C3.50731 16.1667 2 14.6743 2 12.8333V8.83334Z"
        stroke="#E2E2E2"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34667 4.83334H14.12C14.8637 4.83334 15.4667 5.4303 15.4667 6.16668V8.83334H2V6.16668C2 5.4303 2.60292 4.83334 3.34667 4.83334Z"
        stroke="#E2E2E2"
        strokeWidth="1.5"
      />
      <path
        d="M12.7729 3.5V5.5"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.36669 3.5V5.5"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Сalendar2;
