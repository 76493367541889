interface IconProps {
  clazz?: string;
}

const Preview = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 5.58569L6.70711 2.2928C6.31658 1.90227 5.68342 1.90227 5.29289 2.2928L2 5.58569M6 2.58569L6 16.5857"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 18.4143L5.29289 21.7072C5.68342 22.0977 6.31658 22.0977 6.70711 21.7072L10 18.4143M6 21.4143L6 7.41431"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <mask id="path-3-inside-1_74_16547" fill="white">
        <rect x="14" y="2" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="14"
        y="2"
        width="8"
        height="8"
        rx="1"
        strokeWidth="3"
        mask="url(#path-3-inside-1_74_16547)"
      />
      <mask id="path-4-inside-2_74_16547" fill="white">
        <rect x="14" y="14" width="8" height="8" rx="1" />
      </mask>
      <rect
        x="14"
        y="14"
        width="8"
        height="8"
        rx="1"
        strokeWidth="3"
        mask="url(#path-4-inside-2_74_16547)"
      />
    </svg>
  );
};

export default Preview;
