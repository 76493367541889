import classes from "../ManegementForms.module.scss";
import FormColumn, { MOCK_LIST_ONE, MOCK_LIST_TWO } from "./FormColumn";

const FormUserLevel = () => {
  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn
              title={"Добавить должность"}
              mock={MOCK_LIST_ONE}
              initValues={[{ name: "HR-менеджмент", id: "4", selected_link: "4" }]}
            />
            <FormColumn
              title={"Добавить грейд"}
              mock={MOCK_LIST_TWO}
              initValues={[
                { name: "Junior", id: "23", selected_link: "23" },
                { name: "Middle", id: "34", selected_link: "34" },
                { name: "Senior", id: "45", selected_link: "45" },
              ]}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormUserLevel;
