import classes from "./Header.module.scss";
import Logo from "assets/img/logo.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import EmptyImage from "assets/img/empty-image.png";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { UserInfoContext } from "context/UserInfoContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext, useEffect } from "react";

const Header = () => {
  const context = useContext(AuthContext);
  const { apiGet } = useApi();
  const { userInfoContext, setUserInfoContext } = useContext(UserInfoContext);

  useEffect(() => {
    const getShortUserInfo = async () => {
      const res = await apiGet(endpoints.shortCurrentUserInfo);
      setUserInfoContext?.(res);
    };
    if (context.isAuth) getShortUserInfo();
  }, [context.isAuth]);

  return (
    <header className={classes.header}>
      <div className={classes["logo-container"]}>
        <img
          src={Logo}
          alt="terabit logo"
          className={classes["logo-container__logo"]}
        />
      </div>
      {context.isAuth ? (
        userInfoContext ? (
          userInfoContext.errorMessage.length ? (
            userInfoContext.errorMessage.map((i, idx) => {
              return <ErrorComponent key={idx}>{i}</ErrorComponent>;
            })
          ) : (
            <Link to={"/"} className={classes.user}>
              <span className={classes.user__name}>
                {userInfoContext.data.first_name}
              </span>
              <div className={classes["photo-container"]}>
                <img
                  src={
                    userInfoContext.data.photo
                      ? userInfoContext.data.photo
                      : EmptyImage
                  }
                  alt=""
                  className={classes["photo-container__photo"]}
                />
              </div>
            </Link>
          )
        ) : (
          <div className={classes.skeletons}>
            <Skeleton
              containerClassName={classes["skeleton-container"]}
              style={{
                display: "block",
                width: "80px",
                height: "16px",
              }}
            />
            <Skeleton
              containerClassName={classes["skeleton-container"]}
              circle={true}
              style={{
                display: "block",
                width: "36px",
                height: "36px",
              }}
            />
          </div>
        )
      ) : null}
    </header>
  );
};

export default Header;
