import classes from "./Description.module.scss";
import Arrow from "components/ui/icons/Arrow";
import Redact from "components/ui/icons/Redact";
import Check from "components/ui/icons/Check";
import Cross from "components/ui/icons/Cross";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import Popup from "components/common/popup/Popup";
import { Editor } from "@toast-ui/react-editor";
import { useEffect, useRef, useState } from "react";

interface DescriptionProps {
  redaktPermit: boolean;
}

const Description = ({ redaktPermit }: DescriptionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRedact, setIsRedact] = useState(false);
  const [descriptionText, setDescriptionText] = useState<string | null>(null);
  const [error, setError] = useState<string[] | null>(null);

  const editorRef = useRef<Editor>(null);
  const { apiGet, apiPut } = useApi();

  const id = window.location.pathname.split("/projects/")[1];

  const fetchDescription = async () => {
    const res = await apiGet(`${endpoints.projects}${id}/`);
    if (res.errorMessage.length) {
      setError(res.errorMessage);
    } else {
      setDescriptionText(res.data.description);
    }
  };

  const setDescription = async () => {
    const editorInstance = editorRef.current?.getInstance();
    if (editorInstance) {
      setDescriptionText(editorInstance?.getMarkdown());
      const data = {
        description: editorInstance?.getMarkdown(),
      };
      const res = await apiPut(`${endpoints.project}${id}/description/`, data);
      if (res.data) {
        setIsRedact(false);
      } else {
        setError(res.errorMessage);
      }
    }
  };

  useEffect(() => {
    fetchDescription();
  }, []);

  return descriptionText !== null || error ? (
    <div
      className={
        isOpen
          ? [classes.container, classes["container--open"]].join(" ")
          : classes.container
      }
    >
      <h3 className={classes.title}>Описание проекта</h3>
      {isRedact ? (
        <div
          className={[
            classes.editor,
            "journal-item-editor",
            "editor-global",
          ].join(" ")}
        >
          <Editor
            initialValue={descriptionText || " "}
            previewStyle="vertical"
            height={"auto"}
            minHeight={"60px"}
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            ref={editorRef}
            placeholder="Добавьте описание"
            usageStatistics={false}
            toolbarItems={[
              [
                "heading",
                "bold",
                "italic",
                "strike",
                "link",
                "ul",
                "ol",
                "quote",
                "image",
              ],
            ]}
          />
        </div>
      ) : (
        !error && (
          <p className={descriptionText ? classes.description : classes.empty}>
            {descriptionText || "Здесь пока ничего нет"}
          </p>
        )
      )}
      {descriptionText && !isRedact && (
        <button
          title={isOpen ? "Свернуть" : "Подробнее"}
          type="button"
          className={classes["arrow__button"]}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsRedact(false);
          }}
        >
          <Arrow clazz={classes["arrow__icon"]} />
        </button>
      )}
      {redaktPermit && (
        <div className={classes.controls}>
          {isRedact && (
            <button
              title="Отмена"
              className={classes.close}
              onClick={() => {
                setIsRedact(false);
              }}
            >
              <Cross clazz={classes.close__icon} />
            </button>
          )}
          {isRedact ? (
            <button
              title="Сохранить"
              className={classes.done}
              onClick={setDescription}
            >
              <Check clazz={classes.done__icon} />
            </button>
          ) : (
            (isOpen || !descriptionText) && (
              <button
                title="Редактировать"
                className={classes.redact}
                onClick={() => setIsRedact(true)}
              >
                <Redact clazz={classes.redact__icon} />
              </button>
            )
          )}
        </div>
      )}
      {error?.length && (
        <Popup clazz={classes.popup} clickCallback={setError}>
          {error.map((i, idx) => {
            return <ErrorComponent key={idx}>{i}</ErrorComponent>;
          })}
        </Popup>
      )}
    </div>
  ) : (
    <Skeleton count={1} style={{ display: "block", height: "106px" }} />
  );
};

export default Description;
