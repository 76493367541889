interface IconProps {
  clazz?: string;
}

const HR = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.1875C2 5.21795 5.21795 2 9.1875 2H21.6875C25.657 2 28.875 5.21795 28.875 9.1875V21.6875C28.875 25.657 25.657 28.875 21.6875 28.875H9.1875C5.21795 28.875 2 25.657 2 21.6875V9.1875ZM9.1875 3.875C6.25349 3.875 3.875 6.25349 3.875 9.1875V21.6875C3.875 24.6215 6.25349 27 9.1875 27H21.6875C24.6215 27 27 24.6215 27 21.6875V9.1875C27 6.25349 24.6215 3.875 21.6875 3.875H9.1875ZM9 10.1C9.49706 10.1 9.9 10.5029 9.9 11V14.6H13.1V11C13.1 10.5029 13.5029 10.1 14 10.1C14.4971 10.1 14.9 10.5029 14.9 11V15.5V20C14.9 20.4971 14.4971 20.9 14 20.9C13.5029 20.9 13.1 20.4971 13.1 20V16.4H9.9V20C9.9 20.4971 9.49706 20.9 9 20.9C8.50294 20.9 8.1 20.4971 8.1 20V15.5V11C8.1 10.5029 8.50294 10.1 9 10.1ZM17.94 15.4682V11.9824C18.5791 11.915 19.3001 11.9179 19.9008 12.0654C20.3196 12.1683 20.6069 12.3237 20.782 12.5023C20.9353 12.6586 21.06 12.8884 21.06 13.2967C21.06 14.2387 20.3025 14.8847 18.9651 15.2607C18.6204 15.3576 18.2699 15.4248 17.94 15.4682ZM17.94 20V17.3613C18.2039 17.3333 18.4811 17.293 18.7638 17.2382L20.6921 20.4805C20.9575 20.9267 21.5343 21.0733 21.9805 20.8079C22.4267 20.5425 22.5733 19.9657 22.3079 19.5195L20.6013 16.65C21.8044 16.0741 22.94 15.0496 22.94 13.2967C22.94 12.4344 22.6481 11.7201 22.1243 11.186C21.6223 10.6741 20.9721 10.3927 20.3492 10.2397C19.1182 9.93732 17.7128 10.0673 16.8053 10.2594C16.3708 10.3514 16.06 10.7349 16.06 11.179V16.4733V20C16.06 20.5191 16.4809 20.94 17 20.94C17.5191 20.94 17.94 20.5191 17.94 20Z"
        fill="#181818"
      />
    </svg>
  );
};

export default HR;
