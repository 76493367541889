import classes from "./ErrorComponent.module.scss";

interface ErrorPropsType {
  children: string | React.ReactNode | React.ReactNode[];
  clazz?: string;
  title?: string;
}

const ErrorComponent = ({ children, clazz, title }: ErrorPropsType) => {
  return (
    <div title={title} className={clazz || classes.container}>
      {children}
    </div>
  );
};

export default ErrorComponent;
