import Button from "components/ui/button/Button";
import classes from "../ManegementForms.module.scss";
import FormColumn, { MOCK_LIST_ONE, MOCK_LIST_TWO } from "./FormColumn";

const FormUserKnowledge = () => {
  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn
              title={"Добавить область знания"}
              mock={MOCK_LIST_ONE}
            />
            <FormColumn title={"Добавить навык"} mock={MOCK_LIST_TWO} />
          </div>
        </div>
      </form>
      <div className={classes.actions}>
        <Button type="button" clazz={classes.button} theme="fill">
          Создать
        </Button>
      </div>
    </div>
  );
};

export default FormUserKnowledge;
