interface IconProps {
  clazz?: string;
}

const PlusRound = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#F2F2F2" />
      <path
        d="M5.17166 7.99973L10.8284 7.99981M7.99992 5.17147L8 10.8282"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.17166 7.99973L10.8284 7.99981M7.99992 5.17147L8 10.8282"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default PlusRound;
