import classes from "./DayOffTime.module.scss";
import { WidgetComponent } from "forms/components/widget";
import Check from "components/ui/icons/Check";
import CloseCross from "components/ui/icons/CloseCross";
import { useState } from "react";

const DayOffTime = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  resDataAfterSubmit,
}: WidgetComponent) => {
  const [showInputs, setShowInputs] = useState(false);
  const [timeValues, setTimeValues] = useState({ from: "", to: "" });

  return (
    <section className={classes.container}>
      <label
        htmlFor="from"
        className={showInputs ? classes["label--active"] : classes.label}
        onClick={() => setShowInputs(!showInputs)}
      >
        {label}
      </label>
      {showInputs ? (
        <div className={classes.timeInputs}>
          <div className={classes.timeInputs__from}>
            <input
              type="time"
              placeholder="00:00"
              className={classes.input}
              id="from"
              onChange={(e) =>
                setTimeValues({
                  ...timeValues,
                  from: e.target.value,
                })
              }
            />
            <label className={classes.timeLabel} htmlFor="from">
              Со скольки
            </label>
          </div>
          <div className={classes.timeInputs__to}>
            <input
              type="time"
              className={classes.input}
              id="to"
              onChange={(e) =>
                setTimeValues({
                  ...timeValues,
                  to: e.target.value,
                })
              }
            />
            <label className={classes.timeLabel} htmlFor="to">
              До скольки
            </label>
          </div>
          <div
            onClick={() => {
              onChange(timeValues);
              setShowInputs(false);
            }}
          >
            <Check clazz={classes.check} />
          </div>
          <div onClick={() => setShowInputs(false)}>
            <CloseCross clazz={classes.cross} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default DayOffTime;
