interface IconProps {
  clazz?: string;
}

const CloseCross = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0007" cy="12" r="11.6667" fill="#FF7676" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70136 8.70025C9.06585 8.33576 9.65681 8.33576 10.0213 8.70025L15.301 13.98C15.6655 14.3445 15.6655 14.9354 15.301 15.2999C14.9365 15.6644 14.3456 15.6644 13.9811 15.2999L8.70136 10.0202C8.33687 9.65569 8.33687 9.06474 8.70136 8.70025Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.301 8.69996C15.6655 9.06445 15.6655 9.6554 15.301 10.0199L10.0212 15.2996C9.65675 15.6641 9.0658 15.6641 8.70131 15.2996C8.33682 14.9351 8.33682 14.3442 8.70131 13.9797L13.981 8.69996C14.3455 8.33547 14.9365 8.33547 15.301 8.69996Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseCross;
