import classes from "./MembersRedact.module.scss";
import Search from "components/common/search/Search";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import MembersList from "../members-list/MembersList";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType } from "types";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import Popup from "components/common/popup/Popup";
import { useState } from "react";

interface EmployeeType {
  id: number;
  project: number;
  employee: {
    photo?: string | undefined;
    first_name: string;
    last_name?: string | undefined;
    position: string;
    department?: string | undefined;
    inProject?: boolean | undefined;
    id: string;
    idInProject?: string | number;
  };
}

const MembersRedact = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [members, setMembers] = useState<ApiDataType | null>(null);
  const [actionError, setActionError] = useState<string[] | null>(null);

  const { apiGet, apiPost, apiDelete } = useApi();
  const projectId = window.location.pathname.split("/projects/")[1];

  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const search = useDebounce(_search, 500);

  const getMembers = async (searchQuery?: string) => {
    const params = { search: searchQuery };
    const res = await apiGet(
      `${endpoints.project}${projectId}/project_available_employees/`,
      params,
    );
    if (res.errorMessage.length) {
      setMembers(res);
    } else {
      const projectEmployees = res.data.project_employees.map(
        (i: EmployeeType) => {
          i.employee.inProject = true;
          i.employee.idInProject = i.id;
          return i.employee;
        },
      );
      const allMembers = [...projectEmployees, ...res.data.other_employees];
      setMembers({
        data: allMembers,
        errorMessage: res.errorMessage,
      });
    }
  };

  const redactUser = (
    id: string,
    action: string,
    idInProject?: string | number,
  ) => {
    if (action === "add") {
      addMemberToProject(id);
    } else if (action === "remove") {
      if (idInProject) removeMemberFromProject(idInProject);
    }
    setActionError(null);
  };

  const addMemberToProject = async (id: string) => {
    const data = { project: projectId, employee: id };
    const res = await apiPost(endpoints.projectMembers, data);
    if (res.errorMessage.length) {
      setActionError(res.errorMessage);
    } else {
      getMembers(searchQuery);
    }
  };

  const removeMemberFromProject = async (idInProject?: string | number) => {
    const res = await apiDelete(`${endpoints.projectMembers}${idInProject}/`);
    if (res.errorMessage.length) {
      setActionError(res.errorMessage);
    } else {
      getMembers(searchQuery);
    }
  };

  useEffectOnlyOnUpdate(() => {
    getMembers(searchQuery);
    setActionError(null);
  }, [searchQuery]);

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        <Search placeholder="Имя сотрудника" onInput={search} />
        {actionError && (
          <Popup clazz={classes.popup} clickCallback={setActionError}>
            <ErrorComponent clazz={classes.error}>
              {"Action error! "}
              {actionError.map((i, idx) => {
                return i;
              })}
            </ErrorComponent>
          </Popup>
        )}
      </div>
      {members?.errorMessage.length === 0 && members?.data.length === 0 ? (
        <p className={classes["help-text"]}>Не найдено</p>
      ) : !members ? (
        <p className={classes["help-text"]}>Введите поисковый запрос</p>
      ) : (
        <MembersList
          members={members}
          containerClass={classes["members-container"]}
          redact={true}
          redactCallback={redactUser}
        />
      )}
    </div>
  );
};

export default MembersRedact;
