import classes from "./LoggingPage.module.scss";
import ProfileCard from "components/common/profile-card/ProfileCard";
import Logging from "components/logging/Logging";
import { useEffect } from "react";

const LoggingPage = () => {
  useEffect(() => {
    document.title = "ERP - Logging";
  }, []);

  return (
    <section className={classes.mainContainer}>
      {window.innerWidth > 830 && <ProfileCard nonCollapsibleBlock={true} />}
      <Logging />
    </section>
  );
};

export default LoggingPage;
