import Reports from "components/reports/Reports";
import classes from "./ReportsPage.module.scss";
import { useEffect } from "react";

const ReportsPage = () => {
  useEffect(() => {
    document.title = "ERP - Reports";
  }, []);

  return (
    <section className={classes.mainContainer}>
      <Reports />
    </section>
  );
};

export default ReportsPage;
