import { AuthContext } from "context/AuthContext";
import { OpenedBlocksContext } from "./context/OpenedBlocks";
import Router from "components/router/Router";
import { useAuthTokens } from "hooks/useAuthTokens/useAuthTokens";
import jwtDecode from "jwt-decode";
import { TokenType, userType } from "types";
import { UserInfoContext } from "context/UserInfoContext";
import { ApiDataType } from "types";
import { useApi } from "hooks/useApi/useApi";
import { UserRoleContext } from "context/RoleContext";
import { endpoints } from "API/endpoints";
import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PlannedInfoContext } from "context/PlanningPageContext";
import { PlannedInfo } from "components/planning/dto";

function App() {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [plannedInfo, setPlannedInfo] = useState<PlannedInfo[] | null>(null);
  const [isBlockOpen, setIsBlockOpen] = useState({
    isProfileOpen: false,
    isVacationOpen: false,
    isDayOffOpen: false,
    isMentorOpen: true,
  });
  const [userInfoContext, setUserInfoContext] = useState<ApiDataType<
    userType,
    string[]
  > | null>(null);

  const [userRoleContext, setUserRoleContext] = useState<ApiDataType>({
    data: [],
    errorMessage: [],
  });

  const effectRan = useRef(false);

  const { fetchTokens } = useAuthTokens();
  const navigate = useNavigate();

  useEffect(() => {
    if (effectRan.current === false) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        const decodedRefreshToken = jwtDecode<TokenType>(refreshToken);
        if (Date.now() < decodedRefreshToken.exp * 1000) {
          fetchTokens();
          setIsAuth(true);
        } else {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
        }
      }

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const { apiGet } = useApi();

  useLayoutEffect(() => {
    const getUserRole = async () => {
      const res = await apiGet(endpoints.userRoles);
      if (res.errorMessage.length) {
        navigate("/403", {
          state: {
            code: 403,
            message: `${res.errorMessage[0]}. Доступ ограничен!`,
          },
        });
      } else {
        setUserRoleContext(res);
      }
    };
    if (isAuth) getUserRole();
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
      }}
    >
      <OpenedBlocksContext.Provider value={{ isBlockOpen, setIsBlockOpen }}>
        <UserRoleContext.Provider value={{ userRoleContext }}>
          <UserInfoContext.Provider
            value={{ userInfoContext, setUserInfoContext }}
          >
            <PlannedInfoContext.Provider
              value={{ plannedInfo, setPlannedInfo }}
            >
              <Router />
            </PlannedInfoContext.Provider>
          </UserInfoContext.Provider>
        </UserRoleContext.Provider>
      </OpenedBlocksContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
