import { OpenedBlocksContext } from "context/OpenedBlocks";
import classes from "./ProfileCard.module.scss";
import MinSizeUserComponent from "./profile-card-components/min-size-user-component/MinSizeUserComponent";
import RedactContainer from "./profile-card-components/redact-container/RedactContainer";
import ArrowButton from "./profile-card-components/arrow-button/ArrowButton";
import UserFormComponent from "./profile-card-components/user-form-component/UserFormComponent";
import BigSizeUserComponent from "./profile-card-components/big-size-user-component/BigSizeUserComponent";
import { useContext, useState } from "react";

export interface ProfileCardProps {
  nonCollapsibleBlock?: boolean;
}

const ProfileCard = ({ nonCollapsibleBlock = false }: ProfileCardProps) => {
  const { isBlockOpen, setIsBlockOpen } = useContext(OpenedBlocksContext);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const changeFormState = () => {
    setIsFormOpen(!isFormOpen);
  };

  const changeProfileState = () => {
    setIsBlockOpen?.({
      ...isBlockOpen,
      isProfileOpen: !isBlockOpen.isProfileOpen,
      isDayOffOpen: false,
      isVacationOpen: false,
    });
  };

  const minSizeContainer =
    isBlockOpen.isDayOffOpen || isBlockOpen.isVacationOpen;

  let targetComponent;

  if (minSizeContainer && !nonCollapsibleBlock) {
    targetComponent = (
      <MinSizeUserComponent changeProfileState={changeProfileState} />
    );
  } else {
    if (!nonCollapsibleBlock) {
      targetComponent = (
        <div className={classes.container}>
          {isBlockOpen.isProfileOpen ? (
            <RedactContainer
              changeFormState={changeFormState}
              isFormOpen={isFormOpen}
            />
          ) : null}

          {isFormOpen ? <UserFormComponent /> : <BigSizeUserComponent />}
          <ArrowButton
            changeFormState={changeFormState}
            changeProfileState={changeProfileState}
            isFormOpen={isFormOpen}
          />
        </div>
      );
    }
    if (nonCollapsibleBlock) {
      targetComponent = <BigSizeUserComponent nonCollapsibleBlock={true} />;
    }
  }

  return <>{targetComponent}</>;
};

export default ProfileCard;
