import axios from "axios";

export const getArrayError = (err: unknown) => {
  console.error(err);
  const customText = "Упсс.. Что-то пошло не так";

  if (axios.isAxiosError(err)) {
    const message: string[] = [];

    if (
      Object.entries(err?.response?.data).length &&
      err?.response?.data.constructor === Object
    ) {
      for (const [key, value] of Object.entries(err.response.data)) {
        message.push(`${key}: ${value}`);
      }
    } else {
      message.push(err.message || customText);
    }

    return {
      data: null,
      errorMessage: message,
      status: err?.response?.status,
    };
  } else {
    return {
      data: null,
      errorMessage: [customText],
    };
  }
};

export const getFormError = (err: unknown) => {
  console.error(err);
  const customText = "Упсс.. Что-то пошло не так";

  if (axios.isAxiosError(err)) {
    console.error(err);
    if (err.response?.status === 400) {
      return {
        data: null,
        errorMessage: err.response.data,
      };
    }

    if (err.response?.status === 401 && err?.response?.data) {
      const message: string[] = [];
      for (const [key, value] of Object.entries(err.response.data)) {
        message.push(`${key}: ${value}`);
      }

      return {
        data: null,
        errorMessage: message,
      };
    }

    return {
      data: null,
      errorMessage: [err.message || customText],
    };
  } else {
    console.error(err);
    return {
      data: null,
      errorMessage: [customText],
    };
  }
};
