import classes from "./User.module.scss";
import Display from "components/ui/icons/Display";
import EmptyImage from "assets/img/empty-image.png";
import { TEmployee } from "components/planning/dto";

interface Props {
  data: TEmployee;
  projectCount: number;
  employmentPercentage: number;
  isUnassigned: boolean;
}

const User = ({
  data,
  projectCount,
  employmentPercentage,
  isUnassigned,
}: Props) => {
  return (
    <div
      className={`${classes.container} ${
        isUnassigned && employmentPercentage < 100 ? classes.unassigned : ""
      }`}
    >
      <div className={classes.user}>
        <div className={classes.avatar}>
          <img src={data?.photo ? data.photo : EmptyImage} alt="avatar" />
        </div>
        <div>
          <div
            className={classes.name}
          >{`${data.first_name} ${data.last_name}`}</div>
          <div className={classes.position}>{data.position}</div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.progress}>
          <div className={classes.progress__bar}>
            <div
              className={classes["progress__bar--fill"]}
              style={{
                width: `${
                  employmentPercentage <= 100 ? employmentPercentage : 100
                }%`,
              }}
            ></div>
          </div>
          <div
            className={classes.progress__text}
          >{`${employmentPercentage}%`}</div>
        </div>
        <div className={classes.projects}>
          <Display clazz={classes.projects__display} />
          <div className={classes.projects__number}>{projectCount}</div>
        </div>
      </div>
    </div>
  );
};

export default User;
