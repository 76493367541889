export const onClickOnCell = (
  indexCell: number,
  index: number,
  canChangeDate: boolean,
  setShowInputForEdit: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  const readOnlyDate = indexCell === 3 && !canChangeDate;

  if (readOnlyDate) {
    return;
  } else {
    setShowInputForEdit([index, indexCell]);
  }
};
