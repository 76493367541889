import classes from "./AuthConfirmCode.module.scss";
import Form from "forms/components/form";
import Countdown from "react-countdown";
import { useNavigate, Navigate } from "react-router-dom";
import { AuthFormContext } from "context/AuthFormContext";
import { AuthApi } from "API/authApi/AuthAPI";
import { endpoints } from "API/endpoints";
import { AuthContext } from "context/AuthContext";
import { ApiDataType, FormDataType } from "types";
import { useContext, useState } from "react";

const AuthConfirmCode = () => {
  const navigate = useNavigate();

  const { isAuth } = useContext(AuthContext);
  const { user, setToken } = useContext(AuthFormContext);

  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isShowTitle, setIsShowTitle] = useState(false);
  const [key, setKey] = useState(0);
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const renderer = ({ seconds }: { seconds: number }) => {
    return <span className={classes.btn__text}>({seconds}s)</span>;
  };

  const completed = () => {
    setIsBtnDisabled(false);
  };

  const sendConfirmCode = async () => {
    setIsBtnDisabled(true);
    setKey(key + 1);

    const userData = {
      user: user,
    };

    const res = await AuthApi.getConfirmCode(userData);
    if ("status" in res && res.status && res.status === 200) {
      setIsShowTitle(true);
    }
  };

  const onSubmit = async (formData: FormDataType) => {
    setToken?.(typeof formData.token === "string" ? formData.token : "");

    const userData = Object.assign({}, { user: user }, formData);

    const res = await AuthApi.verifyConfirmCode(userData);
    if ("status" in res && res.status && res.status === 200) {
      navigate("/auth/new-password");
    } else {
      setResDataAfterSubmit(res?.data || res);
    }
  };

  if (isAuth) {
    return <Navigate to="/" replace={true}></Navigate>;
  } else if (user === "") {
    return <Navigate to="/auth/recovery-password" replace={true} />;
  }

  const skeleton = {
    count: 3,
    style: {
      height: "36px",
    },
  };

  return (
    <div className={classes.container}>
      {isShowTitle && <span className={classes.titleForm}>Код отправлен</span>}
      <Form
        formId="codeForm"
        apiUrl={endpoints.confirmCode}
        classValueForm={classes.form}
        classValueBtn={classes.formBtn}
        btnText="Сменить пароль"
        onSubmit={onSubmit}
        skeleton={skeleton}
        resDataAfterSubmit={resDataAfterSubmit}
      >
        <>
          <span onClick={() => navigate("/auth")} className={classes.link}>
            Вернуться к авторизации
          </span>
          <button
            className={classes.btnSendCode}
            disabled={isBtnDisabled}
            onClick={() => sendConfirmCode()}
          >
            Отправить повторно &#160;
            {isBtnDisabled && (
              <Countdown
                date={Date.now() + 60000}
                renderer={renderer}
                onComplete={completed}
                key={key}
              />
            )}
          </button>
        </>
      </Form>
    </div>
  );
};

export default AuthConfirmCode;
