import classes from "./AuthNewPassword.module.scss";
import { AuthApi } from "API/authApi/AuthAPI";
import { endpoints } from "API/endpoints";
import { AuthContext } from "context/AuthContext";
import { AuthFormContext } from "context/AuthFormContext";
import Form from "forms/components/form";
import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ApiDataType, FormDataType } from "types";

const AuthNewPassword = () => {
  const navigate = useNavigate();

  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const { isAuth } = useContext(AuthContext);
  const { user, token, setToken, setUser } = useContext(AuthFormContext);

  const onSubmit = async (formData: FormDataType) => {
    const userData = Object.assign(
      {},
      { user: user },
      { token: token },
      formData,
    );

    const res = await AuthApi.resetPassword(userData);
    if ("status" in res && res.status && res.status === 200) {
      setToken?.("");
      setUser?.("");
      navigate("/auth", { replace: true });
    } else {
      setResDataAfterSubmit(res?.data || res);
    }
  };

  if (isAuth) {
    return <Navigate to="/" replace={true}></Navigate>;
  } else if (user === "" || token === "") {
    return <Navigate to="/auth/recovery-password" replace={true} />;
  }

  const skeleton = {
    count: 4,
    style: {
      height: "36px",
    },
    containerClassName: classes["skeleton-container"],
  };

  return (
    <div className={classes.container}>
      <span className={classes.titleForm}>Введите новый пароль</span>
      <Form
        formId="newPasswordForm"
        apiUrl={endpoints.resetPassword}
        classValueForm={classes.form}
        classValueBtn={classes.formBtn}
        btnText="Сохранить пароль"
        onSubmit={onSubmit}
        skeleton={skeleton}
        resDataAfterSubmit={resDataAfterSubmit}
      />
    </div>
  );
};

export default AuthNewPassword;
