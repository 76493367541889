import classes from "./ProjectCase.module.scss";
import ProjectDetails from "./project-details/ProjectDetails";
import ProjectInfo from "./project-info/ProjectInfo";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const ProjectCase = () => {
  const [permitForRedakt, setPermitForRedact] = useState<boolean>();

  const { apiGet } = useApi();
  const navigate = useNavigate();

  const projectId = window.location.pathname.split("/projects/")[1];

  useEffect(() => {
    const fetchPermit = async () => {
      const res = await apiGet(`${endpoints.permit}project/${projectId}`);
      if (res.status === 404) {
        navigate("/404");
      } else if (res.data.can_edit_project) {
        setPermitForRedact(true);
      } else {
        setPermitForRedact(false);
      }
    };
    fetchPermit();
  }, []);

  return permitForRedakt !== undefined ? (
    <div className={classes.container}>
      <ProjectDetails redaktPermit={permitForRedakt} />
      <ProjectInfo redaktPermit={permitForRedakt} />
    </div>
  ) : null;
};

export default ProjectCase;
