import classes from "./AuthConfirmCodePage.module.scss";
import Logo from "assets/img/logo.svg";
import AuthConfirmCode from "components/auth/auth-confirm-code/AuthConfirmCode";

const AuthConfirmCodePage = () => {
  return (
    <section className={classes.container}>
      <div className={classes["img-container"]}>
        <img
          src={Logo}
          alt="terabit logo"
          className={classes["img-container__logo"]}
        />
      </div>
      <AuthConfirmCode />
    </section>
  );
};

export default AuthConfirmCodePage;
