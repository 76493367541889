import { useState } from "react";
import { IManagementEmployeFull } from "types";
import classes from "../ManegementForms.module.scss";
import FormUserExpertise from "../components/FormUserExpertise";
import FormUserLevel from "../components/FormUserLevel";

type Form = "user-expertise" | "user-level";

export const QualificationForm = ({
  initData,
  employData,
  employSkills,
  setEmployData,
}: {
  initData?: IManagementEmployeFull | null;
  employData?: any | null
  employSkills?: any | null
  setEmployData?: (item: any) => void
}) => {
  const [formTab, setFormTab] = useState<Form>("user-expertise");
  const FORM_TABS = {
    "user-expertise": <FormUserExpertise employData={employData} setEmployData={setEmployData} employSkills={employSkills}/>,
    "user-level": <FormUserLevel/>,
  };

  return (
    <div className={classes.body}>
      <div className={`${classes.btnGroup} ${classes.btnGroupPadding}`}>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-expertise" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-expertise")}
        >
          Область знаний сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-level" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-level")}
        >
          Должность и грейд
        </button>
      </div>

      {FORM_TABS[formTab]}
    </div>
  );
};

export default QualificationForm;
