interface IconProps {
  clazz?: string;
}

const Employment = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4375 15C3.4375 8.61421 8.61421 3.4375 15 3.4375C21.3858 3.4375 26.5625 8.61421 26.5625 15C26.5625 21.3858 21.3858 26.5625 15 26.5625C8.61421 26.5625 3.4375 21.3858 3.4375 15ZM15 1.5625C7.57867 1.5625 1.5625 7.57867 1.5625 15C1.5625 22.4213 7.57867 28.4375 15 28.4375C22.4213 28.4375 28.4375 22.4213 28.4375 15C28.4375 7.57867 22.4213 1.5625 15 1.5625ZM15.9375 10C15.9375 9.48223 15.5178 9.0625 15 9.0625C14.4822 9.0625 14.0625 9.48223 14.0625 10V14.6655C14.0625 15.1879 14.3236 15.6758 14.7583 15.9656L18.23 18.28C18.6608 18.5673 19.2428 18.4508 19.53 18.02C19.8173 17.5892 19.7008 17.0072 19.27 16.72L15.9375 14.4983V10Z"
      />
    </svg>
  );
};

export default Employment;
