import Header from "components/common/header/Header";
import classes from "./AuthLayout.module.scss";

interface AuxProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuxProps) => {
  return (
    <>
      <Header />
      <main className={classes.container}>{children}</main>
    </>
  );
};
export default AuthLayout;
