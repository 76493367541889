import { useState } from "react";
import { IManagementEmployeFull } from "types";
import classes from "../ManegementForms.module.scss";
import FormUserData from "../components/FormUserData";
import FormUserKnowledge from "../components/FormUserKnowledge";
import FormUserSach from "../components/FormSach";

type Form = "user-data" | "user-knowledge" | "user-sach";

export const ManegementForm = ({
  initData,
}: {
  initData?: IManagementEmployeFull | null;
}) => {
  const [formTab, setFormTab] = useState<Form>("user-data");
  const FORM_TABS = {
    "user-data": <FormUserData data={initData || undefined}/>,
    "user-knowledge": <FormUserKnowledge />,
    "user-sach": <FormUserSach />,
  };

  return (
    <div className={classes.body}>
      <div className={`${classes.btnGroup} ${classes.btnGroupPadding}`}>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-data" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-data")}
        >
          Данные сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-knowledge" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-knowledge")}
        >
          Область знаний сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-sach" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-sach")}
        >
          САЧ сотрудника
        </button>
      </div>
      {FORM_TABS[formTab]}
    </div>
  );
};
