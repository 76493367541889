import { useEffect } from "react";
import classes from "./PageNotFound.module.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PageNotFound = () => {
  const { state } = useLocation();

  useEffect(() => {
    document.title = `ERP - ${state?.code || "404"}`;
  }, []);

  return (
    <div className={classes.container}>
      <p className={classes.title}>{state?.code || "404"}</p>
      <p className={classes.text}>
        {state?.message || "Упсс.. СТРАНИЦА НЕ НАЙДЕНА"}
      </p>
      <Link to="/" className={classes.link}>
        Back to the Future
      </Link>
    </div>
  );
};

export default PageNotFound;
