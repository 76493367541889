import classes from "./ProjectDetails.module.scss";
import TopBlock from "./top-block/TopBlock";
import TopBlockMin from "components/project-case/project-details/top-block-min/TopBlockMin";
import Redact from "components/ui/icons/Redact";
import Cross from "components/ui/icons/Cross";
import AddUser from "components/ui/icons/AddUser";
import Arrow from "components/ui/icons/Arrow";
import MembersRedact from "./members-redact/MembersRedact";
import MembersProject from "./members-project/MembersProject";
import Check from "components/ui/icons/Check";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType, ProjectType } from "types";
import EditProject from "./edit-project/EditProject";
import { useEffect, useState } from "react";

interface ProjectDetailsPropsType {
  redaktPermit: boolean;
}

const ProjectDetails = ({ redaktPermit }: ProjectDetailsPropsType) => {
  const [isTopBlokMinOpen, setIsTopBlokMinOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isMembersOpen, setIsMembersOpen] = useState(false);
  const [isMembersRedact, setIsMembersRedact] = useState(false);
  const [projectInfo, setProjectInfo] = useState<ApiDataType<
    ProjectType,
    string[]
  > | null>(null);
  const { apiGet } = useApi();

  const st1 = () => {
    setIsTopBlokMinOpen(false);
    setIsEditFormOpen(false);
    setIsMembersOpen(false);
    setIsMembersRedact(false);
  };

  const st2 = () => {
    setIsTopBlokMinOpen(false);
    setIsEditFormOpen(true);
    setIsMembersOpen(false);
    setIsMembersRedact(false);
  };

  const st3 = () => {
    setIsTopBlokMinOpen(true);
    setIsEditFormOpen(false);
    setIsMembersOpen(true);
    setIsMembersRedact(false);
  };

  const st4 = () => {
    setIsTopBlokMinOpen(true);
    setIsEditFormOpen(false);
    setIsMembersOpen(false);
    setIsMembersRedact(true);
  };

  const fetchProjectInfo = async (id: string) => {
    const res = await apiGet(`${endpoints.projects}${id}/`);
    setProjectInfo(res);
  };

  useEffect(() => {
    if (!isEditFormOpen) {
      const id = window.location.pathname.split("/projects/")[1];
      fetchProjectInfo(id);
    }
  }, [isEditFormOpen]);

  return (
    <div className={classes.container}>
      <div className={classes["details-container"]}>
        {redaktPermit && (
          <div className={classes["redact-controls"]}>
            {isEditFormOpen && (
              <button
                type="button"
                title="Отмена"
                className={classes.cross}
                onClick={st1}
              >
                <Cross clazz={classes.cross__icon} />
              </button>
            )}
            <button
              type="button"
              title="Редактировать иформацию о проекте"
              className={classes.redact}
              onClick={isEditFormOpen ? st1 : st2}
            >
              <Redact clazz={classes["redact__icon"]} />
            </button>
          </div>
        )}
        {isTopBlokMinOpen ? (
          <TopBlockMin closeBlock={st1} projectInfo={projectInfo} />
        ) : isEditFormOpen ? (
          <EditProject successCallback={st1} />
        ) : (
          <TopBlock projectInfo={projectInfo} />
        )}
      </div>
      {isEditFormOpen ? (
        <button
          title="Посмотреть всех участников"
          type="button"
          className={classes["members-min__button"]}
          onClick={st3}
        >
          Участники проекта
        </button>
      ) : isMembersRedact ? (
        <div className={classes["members-redact"]}>
          <div className={classes["members-redact__controls"]}>
            <button
              type="button"
              title="Отмена"
              className={classes.cross}
              onClick={st1}
            >
              <Cross clazz={classes.cross__icon} />
            </button>
            <p className={classes["members-redact__title"]}>Редактирование</p>
            <button type="button" className={classes.check} onClick={st1}>
              <Check clazz={classes.check__icon} />
            </button>
          </div>
          <MembersRedact />
          <button
            title="Скрыть"
            type="button"
            className={classes["arrow__button"]}
            onClick={st1}
          >
            <Arrow clazz={classes["arrow__icon"]} />
          </button>
        </div>
      ) : (
        <div
          className={
            isMembersOpen
              ? [classes["members-container"], classes["members--open"]].join(
                " ",
              )
              : classes["members-container"]
          }
        >
          <div className={classes.controls}>
            {isMembersOpen && (
              <button
                title="Свернуть"
                type="button"
                className={classes["close-members"]}
                onClick={st1}
              >
                <Cross clazz={classes["close-members__icon"]} />
              </button>
            )}
            <div
              className={
                !redaktPermit
                  ? [
                    classes["controls-block"],
                    classes["controls-block--full"],
                  ].join(" ")
                  : classes["controls-block"]
              }
            >
              {!isMembersOpen && (
                <button
                  title="Посмотреть всех участников"
                  type="button"
                  className={classes["arrow__button"]}
                  onClick={st3}
                >
                  <Arrow clazz={classes["arrow__icon"]} />
                </button>
              )}
              <p className={classes["controls-block__title"]}>
                Участники проекта
              </p>
            </div>
            {redaktPermit && (
              <button
                title="Редактировать участников"
                type="button"
                className={classes["controls-redact__button"]}
                onClick={st4}
              >
                <AddUser clazz={classes["controls-redact__icon"]} />
              </button>
            )}
          </div>
          <MembersProject isMembersOpen={isMembersOpen} />
          {isMembersOpen && (
            <button
              title="Скрыть"
              type="button"
              className={classes["arrow__button"]}
              onClick={st1}
            >
              <Arrow clazz={classes["arrow__icon"]} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
