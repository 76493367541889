interface IconProps {
  clazz?: string;
}

const Cross = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="black"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4234 3.57737C16.7489 3.90281 16.7489 4.43044 16.4234 4.75588L4.75646 16.4229C4.43103 16.7483 3.90339 16.7483 3.57795 16.4229C3.25251 16.0974 3.25252 15.5698 3.57795 15.2443L15.2449 3.57737C15.5704 3.25193 16.098 3.25193 16.4234 3.57737Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4232 16.423C16.0978 16.7484 15.5702 16.7484 15.2447 16.423L3.57775 4.75604C3.25231 4.4306 3.25231 3.90296 3.57775 3.57753C3.90319 3.25209 4.43083 3.25209 4.75626 3.57753L16.4232 15.2445C16.7487 15.5699 16.7487 16.0976 16.4232 16.423Z"
      />
    </svg>
  );
};

export default Cross;
