import classes from "./ProjectsHeader.module.scss";
import Search from "components/common/search/Search";
import Controls from "components/projects/sorting/Sorting";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

interface ProjectsHeaderProps {
  handleRouterParams: (
    searchQuery?: string | null,
    ordering?: string[] | null,
  ) => void;
  isSingleColumn: boolean;
  handlerPreview: () => void;
}

const ProjectsHeader = ({
  handleRouterParams,
  isSingleColumn,
  handlerPreview,
}: ProjectsHeaderProps) => {
  const [searchParams] = useSearchParams();

  const searchQuery = useRef<string>("");
  const sortByName = useRef<string | undefined>(undefined);
  const sortByDate = useRef<string | undefined>(undefined);
  const ordering = useRef<string[] | null>(null);

  const handlerSortByName = () => {
    if (!sortByName.current) {
      const orderingNew = ordering.current ? [...ordering.current] : [];
      orderingNew?.push("-name");
      ordering.current = orderingNew;
      sortByName.current = "-name";
    } else if (sortByName.current === "-name") {
      if (ordering.current?.includes("-name")) {
        const index = [...ordering.current].indexOf("-name");
        const orderingNew = [...ordering.current];
        orderingNew.splice(index, 1, "name");
        ordering.current = orderingNew;
      }
      sortByName.current = "name";
    } else if (sortByName.current === "name") {
      if (ordering.current?.includes("name")) {
        const orderingNew = [...ordering.current].filter((i) => i !== "name");
        ordering.current = orderingNew;
      }
      sortByName.current = "";
    }
    handleRouterParams(searchQuery.current, ordering.current);
  };

  const handlerByDate = () => {
    if (!sortByDate.current) {
      const orderingNew = ordering.current ? [...ordering.current] : [];
      orderingNew.push("date_start");
      ordering.current = orderingNew;
      sortByDate.current = "date_start";
    } else if (sortByDate.current === "date_start") {
      if (ordering.current?.includes("date_start")) {
        const index = [...ordering.current].indexOf("date_start");
        const orderingNew = [...ordering.current];
        orderingNew.splice(index, 1, "-date_start");
        ordering.current = orderingNew;
      }
      sortByDate.current = "-date_start";
    } else if (sortByDate.current === "-date_start") {
      if (ordering.current?.includes("-date_start")) {
        const orderingNew = [...ordering.current].filter(
          (i) => i !== "-date_start",
        );
        ordering.current = orderingNew;
      }
      sortByDate.current = "";
    }
    handleRouterParams(searchQuery.current, ordering.current);
  };

  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchQuery.current = e.target.value;
    handleRouterParams(searchQuery.current, ordering.current);
  };
  const search = useDebounce(_search, 500);

  useEffect(() => {
    const searchParam = searchParams.get("search");
    const orderingParam = searchParams.get("ordering");

    searchQuery.current = searchParam ? searchParam : "";
    sortByName.current = orderingParam
      ?.split(",")
      .find((i) => i === "name" || i === "-name");
    sortByDate.current = orderingParam
      ?.split(",")
      .find((i) => i === "date_start" || i === "-date_start");
    ordering.current = orderingParam?.split(",") || null;
  }, [searchParams]);

  return (
    <div className={classes.container}>
      <Search
        onInput={search}
        placeholder="Название проекта"
        clazz={classes.search}
        initialValue={searchParams.get("search") || ""}
        autoFocus={searchParams.get("search") ? true : false}
      />
      <Controls
        changeSortByName={handlerSortByName}
        sortByName={sortByName.current}
        changeSortByDate={handlerByDate}
        sortByDate={sortByDate.current}
        changePreview={handlerPreview}
        isSingleColumn={isSingleColumn}
      />
    </div>
  );
};

export default ProjectsHeader;
