interface IconProps {
  clazz?: string;
}

const LogOut = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83569 18C7.83569 20.6234 9.96234 22.75 12.5857 22.75H17.5857C20.209 22.75 22.3357 20.6234 22.3357 18L22.3357 6C22.3357 3.37665 20.209 1.25 17.5857 1.25H12.5857C9.96234 1.25 7.83569 3.37665 7.83569 6V8C7.83569 8.41421 8.17148 8.75 8.58569 8.75C8.99991 8.75 9.33569 8.41421 9.33569 8V6C9.33569 4.20507 10.7908 2.75 12.5857 2.75L17.5857 2.75C19.3806 2.75 20.8357 4.20507 20.8357 6L20.8357 18C20.8357 19.7949 19.3806 21.25 17.5857 21.25H12.5857C10.7908 21.25 9.33569 19.7949 9.33569 18V16C9.33569 15.5858 8.99991 15.25 8.58569 15.25C8.17148 15.25 7.83569 15.5858 7.83569 16V18ZM4.39645 12.75L6.11612 14.4697C6.40901 14.7626 6.40901 15.2374 6.11612 15.5303C5.82322 15.8232 5.34835 15.8232 5.05546 15.5303L2.76256 13.2374C2.07915 12.554 2.07915 11.446 2.76256 10.7626L5.05546 8.46967C5.34835 8.17678 5.82322 8.17678 6.11612 8.46967C6.40901 8.76257 6.40901 9.23744 6.11612 9.53033L4.39645 11.25L15.5857 11.25C15.9999 11.25 16.3357 11.5858 16.3357 12C16.3357 12.4142 15.9999 12.75 15.5857 12.75L4.39645 12.75Z"
      />
    </svg>
  );
};

export default LogOut;
