interface IconProps {
  clazz?: string;
}

const EditTask = ({ clazz }: IconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clazz}
    >
      <g clipPath="url(#clip0_9_28621)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1353 2.85438C10.9401 2.04832 12.2456 2.04782 13.051 2.85328L14.9199 4.72217C15.7185 5.52076 15.7268 6.81358 14.9385 7.62235L8.01364 14.7273C7.4845 15.2702 6.75875 15.5763 6.00093 15.5763L3.93682 15.5762C2.97738 15.5762 2.21117 14.7764 2.25152 13.8171L2.34014 11.7103C2.36976 11.006 2.66247 10.3385 3.16035 9.8399L10.1353 2.85438ZM12.2561 3.64938C11.89 3.28326 11.2966 3.28349 10.9308 3.64988L9.68348 4.89908L12.8933 8.10892L14.1335 6.83653C14.4918 6.46891 14.488 5.88126 14.125 5.51827L12.2561 3.64938ZM3.95584 10.6354L8.88859 5.69518L12.108 8.91463L7.20861 13.9415C6.89112 14.2672 6.45567 14.4509 6.00098 14.4509L3.93687 14.4508C3.61706 14.4508 3.36165 14.1842 3.3751 13.8644L3.46372 11.7576C3.48149 11.3351 3.65712 10.9346 3.95584 10.6354ZM15.3861 15.5213C15.6967 15.5213 15.9484 15.2694 15.9484 14.9586C15.9484 14.6478 15.6967 14.3959 15.3861 14.3959H10.7948C10.4843 14.3959 10.2325 14.6478 10.2325 14.9586C10.2325 15.2694 10.4843 15.5213 10.7948 15.5213H15.3861Z"
          fill="#181818"
        />
      </g>
      <defs>
        <clipPath id="clip0_9_28621">
          <rect width="18" height="18" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditTask;
