interface IconProps {
  clazz?: string;
}

const CloseModal = ({ clazz }: IconProps) => {
  return (
    <svg className={clazz} width="24" height="24" viewBox="0 0 24 24">
      <rect width="24" height="24" rx="12" fill="#F2F2F2" />
      <path
        d="M9 14.9998L15 9M9 9.00018L15 15"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M9 14.9998L15 9M9 9.00018L15 15"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CloseModal;
