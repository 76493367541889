import classes from "./Auth.module.scss";
import { AuthApi } from "API/authApi/AuthAPI";
import { endpoints } from "API/endpoints";
import { AuthContext } from "context/AuthContext";
import Form from "forms/components/form";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthTokens } from "hooks/useAuthTokens/useAuthTokens";
import { ApiDataType, FormDataType } from "types";
import { useContext, useState } from "react";

const Auth = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);
  const { refreshTokens } = useAuthTokens();

  const navigate = useNavigate();

  const onSubmit = async (formData: FormDataType) => {
    const res = await AuthApi.login(formData);

    if ("status" in res && res.status && res.status === 200) {
      localStorage.setItem("accessToken", res.data.access);
      localStorage.setItem("refreshToken", res.data.refresh);
      refreshTokens();

      if (setIsAuth) {
        setIsAuth(true);
      }

      navigate("/", { replace: true });
    } else {
      setResDataAfterSubmit(res?.data || res);
    }
  };

  if (isAuth) {
    return <Navigate to="/" replace={true} />;
  }

  const skeleton = {
    count: 3,
    style: {
      height: "36px",
    },
  };

  return (
    <div className={classes.container}>
      <Form
        formId="authForm"
        apiUrl={endpoints.login}
        classValueForm={classes.form}
        classValueBtn={classes.formBtn}
        btnText="Войти"
        onSubmit={onSubmit}
        skeleton={skeleton}
        resDataAfterSubmit={resDataAfterSubmit}
      >
        <span
          onClick={() => navigate("/auth/recovery-password")}
          className={classes.link}
        >
          Забыли пароль?
        </span>
      </Form>
    </div>
  );
};

export default Auth;
