/*
    Поле отвечает за определение виджета и трансляцию в форму событий изменения данных виджета
 */

import { TField } from "../dto";
import { WidgetComponent } from "./widget";
import { widgets as simpleWidgets } from "./simpleWidgets";
import TextInput from "./simpleWidgets/text-input/TextInput";
import { ApiDataType, FormDataType } from "types";

export type FieldComponent = {
  field: TField;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string | number | string[] | null | Date;
  widgets?: { [value: string]: React.FC<WidgetComponent> };
  onChange: (value: any) => void;
  onSubmit?: (data: FormDataType, reset?: () => void) => void;
  resDataAfterSubmit?: ApiDataType | null;
  form?: string;
  className?: string;
};

const widgetForField = (
  widgets: { [value: string]: React.FC<WidgetComponent> },
  field: TField,
): React.FC<WidgetComponent> => {
  return widgets[field.type] || widgets.default;
};

const Field = ({
  field,
  readOnly,
  disabled,
  value,
  widgets,
  onChange,
  onSubmit,
  resDataAfterSubmit,
  form,
  className,
}: FieldComponent) => {
  //компонент Field может получать widgets или не получать
  // simplewidgets - объект с перечислением всех существующих инпутов

  let Widget: React.FC<WidgetComponent> = widgetForField(
    widgets || simpleWidgets,
    field,
  );
  //в функцию попадет либо widgets из пропсов, либо если его нет объект с перечислением инпутов

  if (widgets && !Widget) {
    Widget = widgetForField(simpleWidgets, field) || TextInput;
  }
  const finalValue = value === undefined ? field.initial_value : value;

  return (
    <Widget
      form={form}
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
      helpText={field.helpText}
      disabled={disabled}
      readOnly={readOnly}
      multiple={field.multiple}
      value={finalValue || ""}
      onChange={onChange}
      choices={field.choices}
      choices_url={field.choices_url}
      fieldClass={field.fieldClass}
      required={field.required}
      rows={field.rows}
      onSubmit={onSubmit}
      resDataAfterSubmit={resDataAfterSubmit}
      widgetArgs={field.widget_args}
      className={className}
    />
  );
};

export default Field;
