import classes from "./Vacation.module.scss";
import { OpenedBlocksContext } from "context/OpenedBlocks";
import Cross from "components/ui/icons/Cross";
import Button from "components/ui/button/Button";
import Form from "forms/components/form";
import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import { ApiDataType, FormDataType } from "types";
import { UserInfoContext } from "context/UserInfoContext";
import { splitDate } from "helpers/dates";
import { useContext, useState } from "react";

const Vacation = () => {
  const { isBlockOpen, setIsBlockOpen } = useContext(OpenedBlocksContext);
  const { userInfoContext } = useContext(UserInfoContext);

  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const minimalSizeBlock =
    isBlockOpen.isProfileOpen || isBlockOpen.isDayOffOpen;

  const changeVacationState = () => {
    setIsBlockOpen?.({
      ...isBlockOpen,
      isVacationOpen: !isBlockOpen.isVacationOpen,
      isProfileOpen: false,
      isDayOffOpen: false,
    });
  };

  const { apiPost } = useApi();

  const onSubmit = async (data: FormDataType) => {
    const userId = userInfoContext?.data.id;

    const vacationData = Object.assign(
      {},
      { employee: userId },
      data,
      {
        start_date: splitDate((data.start_date as Date).toLocaleDateString()),
      },
      {
        end_date: splitDate((data.end_date as Date).toLocaleDateString()),
      },
    );

    // const vacationData = {
    //   employee: userId,
    //   start_date: splitDate(data.start_date.toLocaleDateString()),
    //   end_date: splitDate(data.end_date.toLocaleDateString()),
    // };

    // console.log(vacationData, vacationData2);

    const res = await apiPost(
      endpoints.vacation,
      vacationData,
      undefined,
      true,
    );
    setResDataAfterSubmit(res);
    if (res.data) {
      changeVacationState();
    }
  };

  return (
    <div
      className={
        minimalSizeBlock ? classes["container--minSize"] : classes.container
      }
    >
      {isBlockOpen.isVacationOpen ? (
        <div
          className={
            isBlockOpen.isVacationOpen
              ? [classes.calendars, classes["calendars--open"]].join(" ")
              : classes.calendars
          }
        >
          <div className={classes.header}>
            <button
              type="button"
              className={classes.cross}
              onClick={() => changeVacationState()}
            >
              <Cross clazz={classes.cross__icon} />
            </button>
            <span className={classes.header__title}>Отпуск</span>
          </div>
          <Form
            formId="vacationForm"
            apiUrl={endpoints.vacationForm}
            btnText="Отправить"
            classValueForm={classes.form}
            classValueBtn={classes.formBtn}
            onSubmit={onSubmit}
            resDataAfterSubmit={resDataAfterSubmit}
          />
        </div>
      ) : (
        <Button clazz={classes.btn} onClick={() => changeVacationState()}>
          Запланировать отпуск
        </Button>
      )}
    </div>
  );
};

export default Vacation;
