import classes from "./NoMentor.module.scss";
import EmptyMentor from "assets/img/empty-mentor.png";

type Props = {
  size: string;
};

const NoMentor = ({ size }: Props) => {
  let mentorClassName, titleClassName, avatarClassName, descriptionClassName;

  if (size === "full") {
    mentorClassName = classes.mentor;
    titleClassName = classes.mentor__title;
    avatarClassName = classes.mentor__avatar;
    descriptionClassName = classes.mentor__description;
  } else {
    mentorClassName = classes["mentor--medSize"];
    titleClassName = classes["mentor__title--medSize"];
    avatarClassName = classes["mentor__avatar--medSize"];
    descriptionClassName = classes["mentor__description--medSize"];
  }

  return (
    <div className={mentorClassName}>
      <span className={titleClassName}>Ментор</span>
      <img src={EmptyMentor} alt="mentor avatar" className={avatarClassName} />
      <span className={descriptionClassName}>Наставник не назначен</span>
    </div>
  );
};

export default NoMentor;
