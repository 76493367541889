interface IconProps {
  clazz?: string;
}

const User = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1_16452)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.75C13.7949 3.75 15.25 5.20507 15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7C8.75 5.20507 10.2051 3.75 12 3.75ZM16.75 7C16.75 4.37665 14.6234 2.25 12 2.25C9.37665 2.25 7.25 4.37665 7.25 7C7.25 9.62335 9.37665 11.75 12 11.75C14.6234 11.75 16.75 9.62335 16.75 7Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3967 15.7238C13.9068 14.4775 10.0932 14.4775 6.60334 15.7238C6.0916 15.9066 5.75 16.3913 5.75 16.9347V18.2502C5.75 18.9813 6.39746 19.5428 7.12114 19.4394L8.07556 19.3031C10.6786 18.9312 13.3214 18.9312 15.9244 19.3031L16.8789 19.4394C17.6025 19.5428 18.25 18.9813 18.25 18.2502V16.9347C18.25 16.3913 17.9084 15.9066 17.3967 15.7238ZM6.09884 14.3112C9.91491 12.9483 14.0851 12.9483 17.9012 14.3112C19.0099 14.7072 19.75 15.7574 19.75 16.9347V18.2502C19.75 19.8941 18.2941 21.1568 16.6667 20.9244L15.7123 20.788C13.2499 20.4362 10.7501 20.4362 8.28769 20.788L7.33327 20.9244C5.70594 21.1568 4.25 19.8941 4.25 18.2502V16.9347C4.25 15.7574 4.99011 14.7072 6.09884 14.3112Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_16452">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default User;
