import classes from "./ProfileProjectItem.module.scss";
import { Link } from "react-router-dom";
import Arrow2 from "components/ui/icons/Arrow2";
import EmptyImage from "assets/img/empty-image.png";

interface ProfileProjectsItemProps {
  project: {
    name: string;
    icon?: string;
    id: number;
  };
}

const ProfileProjectItem = ({ project }: ProfileProjectsItemProps) => {
  return (
    <Link to={`/projects/${project.id}`} className={classes.container}>
      <Arrow2 clazz={classes.arrow} />
      <span className={classes.text}>{project.name}</span>
      <div className={classes.image}>
        <img
          src={project.icon ? project.icon : EmptyImage}
          alt=""
          className={classes["image__logo"]}
        />
      </div>
    </Link>
  );
};

export default ProfileProjectItem;
