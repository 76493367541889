import "./EditorPreview.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import { useEffect, useRef } from "react";

interface EditorPreviewProps {
  value: string;
  open?: boolean;
}

const EditorPreview = ({ value, open }: EditorPreviewProps) => {
  const previewEditorRef = useRef<Editor>(null);
  const previewContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewContainerRef.current) {
      previewContainerRef.current
        .querySelector(".ProseMirror.toastui-editor-contents")
        ?.removeAttribute("contenteditable");
    }
  }, []);

  const classNameEditor = open
    ? ["editor-preview", "editor-global", "editor-preview--open"].join(" ")
    : ["editor-preview", "editor-global"].join(" ");

  return (
    <div ref={previewContainerRef} className={classNameEditor}>
      <Editor
        initialValue={value || " "}
        previewStyle="vertical"
        initialEditType="wysiwyg"
        useCommandShortcut={false}
        ref={previewEditorRef}
        hideModeSwitch={true}
        autofocus={false}
        height={"auto"}
        minHeight={"24px"}
        usageStatistics={false}
      />
    </div>
  );
};

export default EditorPreview;
