import { useEffect, useRef } from "react";

type Timer = ReturnType<typeof setTimeout>;

export function useDebounce<T>(func: (value: T) => void, delay = 1000) {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = (value: T) => {
    const newTimer = setTimeout(() => {
      func(value);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  };

  return debouncedFunction;
}
