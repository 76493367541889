/*
    Форма отвечает за получение данных полей из апи, layout полей, интерактивность всей формы в целом,
    самбит данных.
 */
import useApiForm from "../hooks/useApiForm";
import Field from "./field";
import { FormResponse } from "../dto";
import classes from "./Form.module.scss";
import { ApiDataType, FormDataType } from "types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import { SkeletonType } from "types";
import { useEffect, useState } from "react";

export type FormComponent = {
  apiUrl: string;
  testData?: () => FormResponse;
  onSubmit?: (data: FormDataType, reset?: () => void) => void;
  classValueForm?: string;
  classValueBtn?: string;
  btnText?: string;
  formId?: string;
  showBtn?: boolean;
  novalidate?: boolean;
  resDataAfterSubmit?: ApiDataType | null;
  loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>;
  skeleton?: SkeletonType;
  children?: React.ReactNode;
  classNameFields?: string;
};

const Form = ({
  apiUrl,
  testData,
  onSubmit,
  classValueForm,
  classValueBtn,
  btnText,
  showBtn = true,
  formId,
  novalidate = false,
  resDataAfterSubmit,
  loadingCallback,
  skeleton,
  children,
  classNameFields,
}: FormComponent) => {
  const form = useApiForm(apiUrl, testData);

  const [nonFieldErrors, setNonFieldErrors] = useState<string[] | null>(null);

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      const mess = resDataAfterSubmit.errorMessage;
      if (Object.keys(mess).length !== 0 && mess.constructor === Object) {
        if (mess.non_field_errors) {
          setNonFieldErrors(mess.non_field_errors);
        }
      }
    }
  }, [resDataAfterSubmit]);

  useEffect(() => {
    if (form.isLoading) {
      loadingCallback?.(true);
    } else {
      loadingCallback?.(false);
    }
  }, [form.isLoading]);

  const submit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(form.data, form.reset);
    }
  };

  if (form.isLoading) {
    const defaultStyle = {
      display: "block",
      width: "100%",
    };

    const style = { ...defaultStyle, ...skeleton?.style };
    const containerClassName = skeleton?.containerClassName
      ? [classes["skeleton-container"], skeleton.containerClassName].join(" ")
      : classes["skeleton-container"];

    return (
      <Skeleton
        containerClassName={containerClassName}
        count={skeleton?.count || 1}
        style={style}
        circle={skeleton?.circle}
      />
    );
  }

  return (
    <>
      <form
        id={formId}
        onSubmit={submit}
        onReset={form.reset}
        className={classValueForm}
        noValidate={novalidate}
      >
        {form.fields.map(
          (field) =>
            field.hidden !== true && (
              <Field
                field={field}
                form={formId}
                key={field.name}
                disabled={field.disabled}
                onChange={(value) => form.updateData(field, value)}
                value={field.initial_value}
                onSubmit={onSubmit}
                className={classNameFields}
                resDataAfterSubmit={
                  (resDataAfterSubmit?.data !== null &&
                    resDataAfterSubmit?.data[field.name]) ||
                  resDataAfterSubmit?.errorMessage[field.name]
                    ? resDataAfterSubmit
                    : undefined
                }
              />
            ),
        )}
        {showBtn && btnText && (
          <button
            type={"submit"}
            form={formId}
            className={classValueBtn}
            disabled={form.isSending}
          >
            {btnText}
          </button>
        )}
        {children}
      </form>
      {resDataAfterSubmit?.errorMessage &&
      Array.isArray(resDataAfterSubmit.errorMessage)
        ? resDataAfterSubmit.errorMessage.map((i, idx) => {
          return (
            <ErrorComponent
              key={idx}
              clazz={
                formId
                  ? [classes.error, classes[`${formId}Error`]].join(" ")
                  : classes.error
              }
            >
              {i}
            </ErrorComponent>
          );
        })
        : null}
      {nonFieldErrors?.map((i, idx) => {
        return (
          <ErrorComponent
            key={idx}
            clazz={
              formId
                ? [classes.error, classes[`${formId}Error`]].join(" ")
                : classes.error
            }
          >
            {i}
          </ErrorComponent>
        );
      })}
    </>
  );
};

export default Form;
