import classes from "./TelInput.module.scss";
import { WidgetComponent } from "forms/components/widget";
import Phone from "components/ui/icons/Phone";

const TelInput = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  required,
  resDataAfterSubmit,
  form,
  theme = "shadow",
}: WidgetComponent) => {
  const elId = `id_${name}`;

  return (
    <section
      className={`${classes.container} ${classes["container-" + theme]}`}
    >
      {label ? (
        <label htmlFor={elId}>
          {placeholder === "Новый телефон" ? (
            <Phone clazz={classes.icon} />
          ) : (
            <></>
          )}
          {label}
        </label>
      ) : null}
      <input
        form={form}
        className={`${classes.input} ${classes["input-" + theme] || ""}`}
        placeholder={placeholder}
        name={name}
        id={elId}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        {...(inputArgs || {})}
        type="tel"
      ></input>
    </section>
  );
};

export default TelInput;
