import classes from "./Sidebar.module.scss";
import Menu from "components/common/sidebar/menu/Menu";
import Burger from "components/ui/icons/Burger/Burger";
import LogOut from "components/ui/icons/LogOut";
import { AuthContext } from "context/AuthContext";
import Bug from "components/ui/icons/Bug";
import { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/useApi/useApi";
import { UserInfoContext } from "context/UserInfoContext";
import { endpoints } from "API/endpoints";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import ErrorComponent from "../error-component/ErrorComponent";
import EmptyImage from "assets/img/empty-image.png";

const Sidebar = () => {
  const { setIsAuth } = useContext(AuthContext);

  const [isOpenSidebar, setOpenSidebar] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const mediaQuery = window.matchMedia("(max-width: 1281px)");

  const context = useContext(AuthContext);
  const { apiGet } = useApi();
  const { userInfoContext, setUserInfoContext } = useContext(UserInfoContext);

  useEffect(() => {
    const getShortUserInfo = async () => {
      const res = await apiGet(endpoints.shortCurrentUserInfo);
      setUserInfoContext?.(res);
    };
    if (context.isAuth) getShortUserInfo();
  }, [context.isAuth]);

  const toggleSidebar = () => {
    if (!mediaQuery.matches) setOpenSidebar(!isOpenSidebar);
  };

  useEffect(() => {
    const resizeThrottler = () => setOpenSidebar(false);
    window.addEventListener("resize", resizeThrottler);
    return () => window.removeEventListener("resize", resizeThrottler);
  }, []);

  const logout = () => {
    if (isLogout) {
      setIsAuth?.(false);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    } else {
      setIsLogout(true);
    }
  };

  return (
    <article
      className={`${classes.container} ${
        isOpenSidebar ? classes["container--open"] : ""
      }`}
    >
      <div className={classes["top-container"]}>
        <Menu openSidebar={isOpenSidebar} />
        <button
          type="button"
          className={classes.burger}
          onClick={toggleSidebar}
        >
          <Burger isOpen={isOpenSidebar} />
        </button>
      </div>
      <div className={classes["buttom-container"]}>
        <a
          href="https://forms.gle/zq7ieDx1VCRGL4Xp7"
          target="_blank"
          className={classes.bug}
          rel="noreferrer"
        >
          <Bug clazz={classes.bug__icon} />
          <span className={classes.title}>Отчет об ошибке</span>
        </a>
        {context.isAuth &&
          (userInfoContext ? (
            userInfoContext.errorMessage.length ? (
              userInfoContext.errorMessage.map((i, idx) => {
                return <ErrorComponent key={idx}>{i}</ErrorComponent>;
              })
            ) : (
              <Link to={"/"} className={classes["photo-container"]}>
                <img
                  src={
                    userInfoContext.data.photo
                      ? userInfoContext.data.photo
                      : EmptyImage
                  }
                  alt="User"
                  className={classes["photo-container__photo"]}
                />
              </Link>
            )
          ) : (
            <Skeleton
              containerClassName={classes["skeleton-container"]}
              circle={true}
              style={{
                display: "block",
                width: "36px",
                height: "36px",
              }}
            />
          ))}
        <button
          onClick={() => logout()}
          title="Выход"
          className={
            isLogout
              ? [classes.button, classes["button--confirm"]].join(" ")
              : classes.button
          }
        >
          <LogOut clazz={classes.logout} />
          <span className={classes.title}>
            {isLogout ? "Подтвердить" : "Выход"}
          </span>
        </button>
      </div>
    </article>
  );
};

export default Sidebar;
