import classes from "./EditProject.module.scss";
import Form from "forms/components/form";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import FormData from "form-data";
import { ApiDataType, FormDataType } from "types";
import { splitDate } from "helpers/dates";
import { useState } from "react";

interface EditProjectType {
  successCallback: () => void;
}

const EditProject = ({ successCallback }: EditProjectType) => {
  const [resDataAfterSubmitPhoto, setResDataAfterSubmitPhoto] =
    useState<ApiDataType | null>(null);
  const [resDataAfterSubmitForm, setResDataAfterSubmitForm] =
    useState<ApiDataType | null>(null);

  const { apiPut, apiPatch } = useApi();

  const id = window.location.pathname.split("/projects/")[1];
  const photoFormURL = `${endpoints.project}${id}/icon/update_form/`;
  const formURL = `${endpoints.projects}${id}/update_form/`;
  const photoSkeleton = {
    style: {
      width: "110px",
      height: "110px",
      margin: "0 auto",
    },
    circle: true,
    containerClassName: classes["photo-skeleton"],
  };
  const formSkeleton = {
    count: 12,
    style: {
      width: "272px",
      height: "32px",
      margin: "0 auto",
    },
  };

  const onSubmitPhoto = async (files: FormDataType) => {
    if (files.data instanceof FileList) {
      const formData = new FormData();

      formData.append("icon", files.data[0]);
      const res = await apiPut(
        `${endpoints.project}${id}/icon/`,
        formData,
        undefined,
        true,
      );
      setResDataAfterSubmitPhoto(res);
    }
  };

  const onSubmitForm = async (form: FormDataType) => {
    const formData = new FormData();

    if (form?.date_start instanceof Date)
      form.date_start = splitDate(form.date_start.toLocaleDateString());
    if (form?.date_end instanceof Date)
      form.date_end = splitDate(form.date_end.toLocaleDateString());
    if (Array.isArray(form.statuses)) {
      form.statuses.map((status) => formData.append("statuses", `${status}`));
      delete form.statuses;
    }

    for (const key in form) {
      formData.append(key, `${form[key]}`);
    }

    const res = await apiPatch(
      `${endpoints.projects}${id}/`,
      formData,
      undefined,
      true,
      {
        "Content-Type": "multipart/form-data",
      },
    );
    if (res.data) {
      successCallback();
    }
    setResDataAfterSubmitForm(res);
  };

  return (
    <div>
      <Form
        formId="projectEditPhotoForm"
        apiUrl={photoFormURL}
        classValueForm={classes["photo-form"]}
        onSubmit={onSubmitPhoto}
        showBtn={false}
        resDataAfterSubmit={resDataAfterSubmitPhoto}
        skeleton={photoSkeleton}
      />
      <Form
        formId="projectEditForm"
        apiUrl={formURL}
        btnText="Сохранить"
        classValueForm={classes.form}
        skeleton={formSkeleton}
        onSubmit={onSubmitForm}
        resDataAfterSubmit={resDataAfterSubmitForm}
      />
    </div>
  );
};

export default EditProject;
