import { useNavigate } from "react-router-dom";
import classes from "./ProfileProjects.module.scss";
import Button from "components/ui/button/Button";
import ProfileProjectItem from "./profile-project-item/ProfileProjectItem";

interface ProfileProjectsProps {
  project?: {
    name: string;
    logo?: string;
    id: number;
  }[];
}

const ProfileProjects = ({ project }: ProfileProjectsProps) => {
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes["projects-list"]}>
        {project?.slice(0, 6).map((i, idx) => (
          <ProfileProjectItem key={idx} project={i} />
        ))}
      </div>
      <Button type="button" onClick={() => navigate("/projects")}>
        Bce проекты
      </Button>
    </div>
  );
};

export default ProfileProjects;
