interface IconProps {
  clazz?: string;
}

const Education = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clipPath="url(#clip0_1_17195)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.54579 3.75303C6.8875 2.49899 10.6125 2.49899 13.9542 3.75303L15 4.14548L16.0458 3.75303C19.3875 2.49899 23.1125 2.49899 26.4542 3.75303C27.6228 4.19157 28.4375 5.27759 28.4375 6.53634V23.8497C28.4375 25.7433 26.4596 26.8665 24.7896 26.2398C22.5183 25.3874 19.9817 25.3874 17.7104 26.2398L15.3465 27.1269C15.237 27.168 15.1188 27.1866 15 27.1838C14.8812 27.1866 14.763 27.168 14.6535 27.1269L12.2896 26.2398C10.0183 25.3874 7.48172 25.3874 5.21045 26.2398C3.5404 26.8665 1.5625 25.7433 1.5625 23.8497V6.53634C1.5625 5.27759 2.37717 4.19157 3.54579 3.75303ZM15.9375 24.9024L17.0517 24.4843C19.7477 23.4726 22.7523 23.4726 25.4483 24.4843C26.0592 24.7136 26.5625 24.2608 26.5625 23.8497V6.53634C26.5625 6.11664 26.2879 5.6933 25.7954 5.50849C22.8784 4.41384 19.6215 4.41384 16.7046 5.50849L15.9375 5.79634V24.9024ZM14.0625 5.79634L13.2954 5.50849C10.3785 4.41384 7.12155 4.41384 4.20456 5.50849C3.71207 5.6933 3.4375 6.11664 3.4375 6.53634V23.8497C3.4375 24.2608 3.94078 24.7136 4.55168 24.4843C7.24767 23.4726 10.2523 23.4726 12.9483 24.4843L14.0625 24.9024V5.79634Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_17195">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Education;
