interface IconProps {
  clazz?: string;
}

const PlusFill = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <circle cx="12.0703" cy="12" r="10" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12C16 12.4419 15.6418 12.8 15.2 12.8H8.8C8.35817 12.8 8 12.4419 8 12C8 11.5582 8.35817 11.2 8.8 11.2H15.2C15.6418 11.2 16 11.5582 16 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 16C11.5584 16 11.2002 15.6418 11.2002 15.2L11.2002 8.8C11.2002 8.35817 11.5584 8 12.0002 8C12.442 8 12.8002 8.35817 12.8002 8.8L12.8002 15.2C12.8002 15.6418 12.442 16 12.0002 16Z"
      />
    </svg>
  );
};

export default PlusFill;
