import classes from "./ProfilePage.module.scss";
import ProfileCard from "components/common/profile-card/ProfileCard";
import Vacation from "components/common/profile-card/vacation/Vacation";
import DayOff from "components/common/profile-card/day-off/DayOff";
import Mentor from "components/common/profile-card/mentor/Mentor";
import { OpenedBlocksContext } from "context/OpenedBlocks";
import Skills from "components/profile/skills/Skills";
import ProfileProjects from "components/profile/profile-projects/ProfileProjects";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { ApiDataType, ProjectType } from "types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext, useEffect, useState } from "react";

const ProfilePage = () => {
  const { isBlockOpen } = useContext(OpenedBlocksContext);
  const { apiGet } = useApi();
  const [projects, setProjects] = useState<ApiDataType<
    { data: ProjectType[] },
    string[]
  > | null>(null);

  const changePositionOfComponents =
    isBlockOpen.isProfileOpen ||
    isBlockOpen.isDayOffOpen ||
    isBlockOpen.isVacationOpen;

  let targetLayout;

  if (!changePositionOfComponents) {
    targetLayout = (
      <>
        <Vacation />
        <DayOff />
        <Mentor />
      </>
    );
  } else if (isBlockOpen.isProfileOpen) {
    targetLayout = (
      <div className={classes.position}>
        <Vacation />
        <DayOff />
        <Mentor />
      </div>
    );
  } else if (isBlockOpen.isVacationOpen) {
    targetLayout = (
      <>
        <Vacation />
        <div className={classes.calendarOpen}>
          <DayOff />
        </div>
      </>
    );
  } else {
    targetLayout = (
      <>
        <DayOff />
        <div className={classes.calendarOpen}>
          <Vacation />
        </div>
      </>
    );
  }

  useEffect(() => {
    const getProjects = async () => {
      const res = await apiGet(endpoints.projects);
      setProjects(res);
    };
    getProjects();

    document.title = "ERP - Profile";
  }, []);

  return (
    <section className={classes.mainContainer}>
      <div className={classes.profileContainer}>
        <ProfileCard />
        {targetLayout}
        {/* <Future /> */}
      </div>
      <Skills />
      {projects ? (
        projects.errorMessage.length ? (
          projects.errorMessage.map((i, idx) => {
            return <ErrorComponent key={idx}>{i}</ErrorComponent>;
          })
        ) : projects.data.data.length ? (
          <ProfileProjects project={projects.data.data} />
        ) : null
      ) : (
        <Skeleton
          count={5}
          style={{
            display: "block",
            width: "100%",
            height: "124px",
          }}
        />
      )}
    </section>
  );
};

export default ProfilePage;
