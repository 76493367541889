import classes from "./Journal.module.scss";
import PlusFill from "components/ui/icons/PlusFill";
import CrossFill from "components/ui/icons/CrossFill";
import JournalItem from "components/project-case/project-info/journal-item/JournalItem";
import "./JournalEditor.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import Check from "components/ui/icons/Check";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { UserInfoContext } from "context/UserInfoContext";
import { ApiDataType } from "types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Popup from "components/common/popup/Popup";
import { useContext, useEffect, useRef, useState } from "react";

interface JournalProps {
  redaktPermit: boolean;
}

interface JournalItemType {
  author: {
    first_name: string;
    id: number;
    last_name: string;
    photo: string | null;
    position: string;
  };
  date: string;
  id: number;
  project: number;
  text: string;
  error?: string[];
}

const Journal = ({ redaktPermit }: JournalProps) => {
  const [isOpenNewEntry, setIsOpenNewEntry] = useState(false);
  const [newEditorValue, setNewEditorValue] = useState("");
  const [error, setError] = useState<string[] | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [journalList, setJournalList] = useState<ApiDataType<
    JournalItemType[],
    string[]
  > | null>(null);
  const newEditorRef = useRef<Editor>(null);
  const { apiPost, apiGet, apiDelete, apiPatch } = useApi();
  const { userInfoContext } = useContext(UserInfoContext);

  const projectId = window.location.pathname.split("/projects/")[1];

  const edidNote = async (noteId: number, action: string, text?: string) => {
    if (action === "delete") {
      return await remove(noteId);
    } else if (action === "redact") {
      return await redact(noteId, text);
    }
  };

  const redact = async (noteId: number, text?: string) => {
    const data = {
      text,
    };
    const params = {
      project: projectId,
    };
    const res = await apiPatch(
      `${endpoints.projectJornal}${noteId}/`,
      data,
      params,
    );
    return res;
  };

  const remove = async (noteId: number) => {
    const params = {
      project: projectId,
    };
    const res = await apiDelete(`${endpoints.projectJornal}${noteId}/`, params);
    if (!res.errorMessage.length) fetchJornalList();
    return res;
  };

  const save = () => {
    const editorInstance = newEditorRef.current?.getInstance();
    if (editorInstance) {
      setNewEditorValue(editorInstance?.getMarkdown());
    }
  };

  const sendNewEntry = async () => {
    const res = await apiPost(`${endpoints.projectJornal}`, {
      text: newEditorValue,
      project: projectId,
    });

    if (res.errorMessage.length) {
      setError(res.errorMessage);
    } else {
      setNewEditorValue("");
      newEditorRef.current?.getInstance().reset();
      fetchJornalList();
    }
  };

  const onEditorChange = () => {
    if (error?.length) setError([]);
    if (newEditorRef.current?.getInstance().getMarkdown() && isDisabled) {
      setIsDisabled(false);
    } else if (
      !newEditorRef.current?.getInstance().getMarkdown() &&
      !isDisabled
    ) {
      setIsDisabled(true);
    }
  };

  const fetchJornalList = async () => {
    const res = await apiGet(`${endpoints.projectJornal}`, {
      project: projectId,
    });
    if (res.data?.data.length) res.data.data.reverse();
    setJournalList({
      data: res.data?.data || [],
      errorMessage: res.errorMessage,
    });
  };

  useEffectOnlyOnUpdate(() => {
    if (newEditorValue.trim()) {
      sendNewEntry();
    }
  }, [newEditorValue]);

  useEffect(() => {
    fetchJornalList();
  }, []);

  return journalList ? (
    <div className={classes.container}>
      <h3 className={classes.title}>Дневник проекта</h3>
      {redaktPermit && (
        <div className={classes.controls}>
          {isOpenNewEntry ? (
            <button
              type="button"
              title="Закрыть"
              className={classes["close-new-entry"]}
              onClick={() => {
                setIsOpenNewEntry(false);
                setNewEditorValue("");
                newEditorRef.current?.getInstance().reset();
              }}
            >
              <CrossFill clazz={classes["close-new-entry__icon"]} />
            </button>
          ) : (
            <button
              type="button"
              title="Новая запись"
              className={classes["new-entry"]}
              onClick={() => setIsOpenNewEntry(true)}
            >
              <PlusFill clazz={classes["new-entry__icon"]} />
            </button>
          )}
        </div>
      )}
      <div className={classes.body}>
        {isOpenNewEntry && (
          <div className={classes["entry"]}>
            <p className={classes["entry-title"]}>
              <span className={classes["entry-title__date"]}>
                {new Intl.DateTimeFormat("ru", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }).format(new Date())}
              </span>
              <span className={classes["entry-title__user"]}>
                {userInfoContext?.data.first_name}{" "}
                {userInfoContext?.data.last_name}
              </span>
            </p>
            <div
              className={[
                classes.editor,
                "journal-editor",
                "editor-global",
              ].join(" ")}
            >
              <Editor
                onChange={onEditorChange}
                initialValue={" "}
                previewStyle="vertical"
                height={"auto"}
                minHeight={"60px"}
                initialEditType="wysiwyg"
                useCommandShortcut={true}
                ref={newEditorRef}
                placeholder="Добавьте запись"
                usageStatistics={false}
                toolbarItems={[
                  [
                    "heading",
                    "bold",
                    "italic",
                    "strike",
                    "link",
                    "ul",
                    "ol",
                    "quote",
                    "image",
                  ],
                ]}
              />
              <button
                title={"Сохранить"}
                type="button"
                disabled={isDisabled}
                className={classes["save"]}
                onClick={save}
              >
                <Check clazz={classes["save__icon"]} />
              </button>
            </div>
            {error?.length && (
              <Popup clazz={classes.popup} clickCallback={setError}>
                {error.map((i, idx) => (
                  <ErrorComponent key={idx}>{i}</ErrorComponent>
                ))}
              </Popup>
            )}
          </div>
        )}
        {journalList.errorMessage.length ? (
          journalList.errorMessage.map((i, idx) => {
            return <ErrorComponent key={idx}>{i}</ErrorComponent>;
          })
        ) : journalList.data.length ? (
          <div className={classes.list}>
            {journalList.data.map((i: JournalItemType) => (
              <JournalItem
                key={i.id}
                note={i}
                redaktPermit={redaktPermit}
                redactCallback={edidNote}
              />
            ))}
          </div>
        ) : (
          !isOpenNewEntry && <p className={classes.empty}>Записей нет</p>
        )}
      </div>
    </div>
  ) : (
    <Skeleton count={2} style={{ display: "block", height: "102px" }} />
  );
};

export default Journal;
