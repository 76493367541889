import { addDays, subDays } from "date-fns";

export const getSelectWeeks = (checkedWeek: Date[]) => {
  const prevMonday = subDays(checkedWeek[0], 7);
  const prevSunday = subDays(checkedWeek[6], 7);
  const nextMonday = addDays(checkedWeek[0], 7);
  const nextSunday = addDays(checkedWeek[6], 7);
  const lastMonday = addDays(checkedWeek[0], 14);
  const lastSunday = addDays(checkedWeek[6], 14);

  const selectWeeks = [
    {
      name: "prev",
      label: `${prevMonday.toLocaleDateString()} - ${prevSunday.toLocaleDateString()}`,
      value: prevMonday,
    },
    {
      name: "current",
      label: `${checkedWeek[0].toLocaleDateString()} - ${checkedWeek[6].toLocaleDateString()}`,
      value: checkedWeek[0],
    },
    {
      name: "next",
      label: `${nextMonday.toLocaleDateString()} - ${nextSunday.toLocaleDateString()}`,
      value: nextMonday,
    },
    {
      name: "last",
      label: `${lastMonday.toLocaleDateString()} - ${lastSunday.toLocaleDateString()}`,
      value: lastMonday,
    },
  ];

  return selectWeeks;
};

export const getSelectDefaultValue = (checkedWeek: Date[]) => {
  const defaultValue = {
    name: "current",
    label: `${checkedWeek[0].toLocaleDateString()} - ${checkedWeek[6].toLocaleDateString()}`,
    value: checkedWeek[0],
    isdisabled: false,
  };

  return defaultValue;
};
