import { useEffect } from "react";
import classes from "./EducationPage.module.scss";

const EducationPage = () => {
  useEffect(() => {
    document.title = "ERP - Education";
  }, []);

  return (
    <section className={classes.mainContainer}>РАЗДЕЛ В РАЗРАБОТКЕ</section>
  );
};

export default EducationPage;
