interface IconProps {
  clazz?: string;
}

const Redact = ({ clazz }: IconProps) => {
  return (
    <svg className={clazz} width="20" height="20" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_18_5930)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2614 3.17159C12.1557 2.27596 13.6062 2.27542 14.5011 3.17037L16.5777 5.24691C17.465 6.13423 17.4742 7.5707 16.5983 8.46934L8.90405 16.3637C8.31611 16.9669 7.50973 17.3071 6.6677 17.3071L4.37424 17.307C3.3082 17.3069 2.45686 16.4183 2.50169 15.3524L2.60015 13.0115C2.63307 12.229 2.9583 11.4873 3.5115 10.9333L11.2614 3.17159ZM13.6179 4.05492C13.2111 3.64812 12.5518 3.64837 12.1453 4.05548L10.7594 5.44348L14.3259 9.00997L15.7038 7.5962C16.102 7.18773 16.0978 6.53479 15.6944 6.13146L13.6179 4.05492ZM4.39538 11.8172L9.87621 6.32803L13.4534 9.9052L8.00956 15.4906C7.6568 15.8525 7.17297 16.0566 6.66775 16.0566L4.3743 16.0565C4.01895 16.0565 3.73517 15.7603 3.75011 15.405L3.84858 13.0641C3.86832 12.5946 4.06347 12.1496 4.39538 11.8172ZM17.0957 17.246C17.4407 17.246 17.7204 16.966 17.7204 16.6207C17.7204 16.2754 17.4407 15.9955 17.0957 15.9955H11.9943C11.6492 15.9955 11.3695 16.2754 11.3695 16.6207C11.3695 16.966 11.6492 17.246 11.9943 17.246H17.0957Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_5930">
          <rect width="20" height="20" rx="8" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Redact;
