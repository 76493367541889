import Checkbox from "./checkbox/Checkbox";
import DateInput from "./date-input/DateInput";
import DayOffTime from "./dayoff-time/DayOffTime";
import EmailInput from "./email-input/EmailInput";
import PasswordInput from "./password-input/PasswordInput";
import SelectWrapper from "./select-input/SelectWrapper";
import TelInput from "./tel-input/TelInput";
import TextInput from "./text-input/TextInput";
import Textarea from "./textarea/Textarea";
import TimeInput from "./time-input/TimeInput";
import FileInput from "./file-input/FileInput";
import NumberInput from "./number-input/NumberInput";

export const widgets = {
  input: TextInput,
  text: TextInput,
  select: SelectWrapper,
  date: DateInput,
  datetime: DateInput,
  time: TimeInput,
  default: TextInput,
  password: PasswordInput,
  email: EmailInput,
  checkbox: Checkbox,
  tel: TelInput,
  textarea: Textarea,
  dayofftime: DayOffTime,
  file: FileInput,
  number: NumberInput,
};
