import classes from "./TopBlock.module.scss";
import { Link } from "react-router-dom";
import EmptyImage from "assets/img/empty-image.png";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ApiDataType, ProjectType } from "types";

export interface ProjectInfoType {
  projectInfo: ApiDataType<ProjectType | null, string[]> | null;
}

const TopBlock = ({ projectInfo }: ProjectInfoType) => {
  return projectInfo ? (
    <div className={classes.container}>
      {projectInfo.errorMessage.length ? (
        projectInfo.errorMessage.map((i, idx) => {
          return <ErrorComponent key={idx}>{i}</ErrorComponent>;
        })
      ) : (
        <>
          <div className={classes.image}>
            {projectInfo?.data?.icon ? (
              <img
                src={projectInfo.data.icon}
                alt=""
                className={classes.image__photo}
              />
            ) : (
              <img className={classes.image__photo} src={EmptyImage} alt="" />
            )}
          </div>
          <h1 className={classes.title}>{projectInfo?.data?.name}</h1>
          <div className={classes.info}>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Дата начала:</span>
              <span className={classes.info__text}>
                {projectInfo?.data?.date_start || "-"}
              </span>
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Дата окончания:</span>
              <span className={classes.info__text}>
                {projectInfo?.data?.date_end || "-"}
              </span>
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Отработанное время:</span>
              <span className={classes.info__text}>
                {projectInfo?.data?.spent_hours || "-"} ч.
              </span>
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>
                Запланированное время:
              </span>
              <span className={classes.info__text}>
                {projectInfo?.data?.planned_hours || "-"} ч.
              </span>
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>WIKI:</span>
              {projectInfo?.data?.wiki ? (
                <a
                  href={projectInfo.data.wiki}
                  className={classes.info__text}
                  target="_blank"
                  rel="noreferrer"
                >
                  {projectInfo.data.wiki}
                </a>
              ) : (
                <span className={classes.info__text}>{"-"}</span>
              )}
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Telegram:</span>
              {projectInfo?.data?.telegram ? (
                <a
                  href={`https://t.me/${projectInfo.data.telegram}`}
                  className={classes.info__text}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://t.me/${projectInfo.data.telegram}`}
                </a>
              ) : (
                <span className={classes.info__text}>{"-"}</span>
              )}
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Сайт проекта:</span>
              {projectInfo?.data?.link ? (
                <a
                  href={projectInfo.data.link}
                  className={classes.info__text}
                  target="_blank"
                  rel="noreferrer"
                >
                  {projectInfo.data.link}
                </a>
              ) : (
                <span className={classes.info__text}>{"-"}</span>
              )}
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Менеджер проекта:</span>
              <span className={classes["info__text--manager"]}>
                {projectInfo?.data?.manager}
              </span>
            </p>
            <p className={classes.info__item}>
              <span className={classes.info__title}>Статус проекта:</span>
              <span className={classes["info__text--statuses"]}>
                {projectInfo?.data?.statuses.join(", ")}
              </span>
            </p>
          </div>
          <Link
            to={`/reports?project=${projectInfo?.data?.id}`}
            className={classes.link}
          >
            Аналитика проекта
          </Link>
        </>
      )}
    </div>
  ) : (
    <Skeleton
      style={{ display: "block", height: "100%", minHeight: "502px" }}
    />
  );
};

export default TopBlock;
