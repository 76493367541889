import Cross from "components/ui/icons/Cross";
import Redact from "components/ui/icons/Redact";
import classes from "./RedactContainer.module.scss";

type redactContainerProps = {
  changeFormState: () => void;
  isFormOpen: boolean;
};

const RedactContainer = ({
  changeFormState,
  isFormOpen,
}: redactContainerProps) => {
  const classNameCross = isFormOpen
    ? classes["cross__icon"]
    : classes["cross__icon--close"];

  return (
    <div className={classes["redact-container"]}>
      <button
        type="button"
        title="Отмена"
        className={classes.cross}
        onClick={changeFormState}
      >
        <Cross clazz={classNameCross} />
      </button>
      <button
        type="button"
        title="Редактировать профиль"
        className={classes.redact}
        onClick={changeFormState}
      >
        <Redact clazz={classes["redact__icon"]} />
      </button>
    </div>
  );
};

export default RedactContainer;
