interface IconProps {
  clazz?: string;
}

const LinkIcone = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6 10L10 6"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.8889 8.77778L12.6667 8C13.9553 6.71134 13.9553 4.622 12.6667 3.33333V3.33333C11.378 2.04467 9.28866 2.04467 8 3.33333L7.22222 4.11111M8.77778 11.8889L8 12.6667C6.71134 13.9553 4.622 13.9553 3.33333 12.6667V12.6667C2.04467 11.378 2.04467 9.28866 3.33333 8L4.11111 7.22222"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default LinkIcone;
