import { createContext } from "react";

export type IsBlockOpen = {
  isProfileOpen: boolean;
  isVacationOpen: boolean;
  isDayOffOpen: boolean;
  isMentorOpen: boolean;
};

interface OpenedBlocksContextType {
  isBlockOpen: IsBlockOpen;
  setIsBlockOpen: React.Dispatch<React.SetStateAction<IsBlockOpen>> | null;
}

const defaultValuesIsBlockOpen = {
  setIsBlockOpen: null,
  isBlockOpen: {
    isProfileOpen: false,
    isVacationOpen: false,
    isDayOffOpen: false,
    isMentorOpen: true,
  },
};

export const OpenedBlocksContext = createContext<OpenedBlocksContextType>(
  defaultValuesIsBlockOpen,
);
