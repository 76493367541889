interface IconProps {
  clazz?: string;
}

const PlusStroke = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
    >
      <g clipPath="url(#clip0_83_17236)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.5 75.45C61.1455 75.45 75.45 61.1455 75.45 43.5C75.45 25.8545 61.1455 11.55 43.5 11.55C25.8545 11.55 11.55 25.8545 11.55 43.5C11.55 61.1455 25.8545 75.45 43.5 75.45ZM43.5 79C63.1061 79 79 63.1061 79 43.5C79 23.8939 63.1061 8 43.5 8C23.8939 8 8 23.8939 8 43.5C8 63.1061 23.8939 79 43.5 79Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.8125 43.5001C56.8125 44.9706 55.6205 46.1626 54.15 46.1626H32.85C31.3795 46.1626 30.1875 44.9706 30.1875 43.5001C30.1875 42.0296 31.3795 40.8376 32.85 40.8376H54.15C55.6205 40.8376 56.8125 42.0296 56.8125 43.5001Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.5004 56.8123C42.0299 56.8123 40.8379 55.6202 40.8379 54.1498L40.8379 32.8498C40.8379 31.3793 42.0299 30.1873 43.5004 30.1873C44.9708 30.1873 46.1629 31.3793 46.1629 32.8498L46.1629 54.1498C46.1629 55.6202 44.9708 56.8123 43.5004 56.8123Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_83_17236">
          <rect
            width="71"
            height="71"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusStroke;
