import classes from "./Burger.module.scss";

interface IconProps {
  isOpen: boolean;
}

const Burger = ({ isOpen }: IconProps) => {
  return (
    <div
      className={`${classes.burger__icon} ${
        isOpen ? classes["burger__icon--open"] : ""
      }`}
    >
      <div className={classes.burger__line}></div>
      <div className={classes.burger__line}></div>
      <div className={classes.burger__line}></div>
    </div>
  );
};

export default Burger;
