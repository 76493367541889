import { createContext } from "react";

interface AuthFormContextType {
  user: string;
  setUser?: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken?: React.Dispatch<React.SetStateAction<string>>;
}

const defaultValue = {
  user: "",
  token: "",
};

export const AuthFormContext = createContext<AuthFormContextType>(defaultValue);
