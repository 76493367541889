import MembersList from "../members-list/MembersList";
import { ApiDataType } from "types";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { useEffect, useState } from "react";

const MembersProject = ({ isMembersOpen }: { isMembersOpen: boolean }) => {
  const [members, setMembers] = useState<ApiDataType | null>(null);

  const { apiGet } = useApi();

  const getMembers = async (id: string) => {
    const res = await apiGet(
      `${endpoints.project}${id}/project_available_employees/`,
    );
    if (res.errorMessage.length) {
      setMembers(res);
    } else {
      setMembers({
        data: res.data.project_employees,
        errorMessage: res.errorMessage,
      });
    }
  };

  useEffect(() => {
    const id = window.location.pathname.split("/projects/")[1];
    getMembers(id);
  }, []);
  return members && <MembersList isOpen={isMembersOpen} members={members} />;
};

export default MembersProject;
