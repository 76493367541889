import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import jwtDecode from "jwt-decode";
import { TokenType } from "types";
import { useContext } from "react";

const ProtectedRoute = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const context = useContext(AuthContext);
  const refreshToken = localStorage.getItem("refreshToken");
  const decodedRefreshToken = refreshToken
    ? jwtDecode<TokenType>(refreshToken)
    : false;
  const isValid = decodedRefreshToken
    ? Date.now() < decodedRefreshToken.exp * 1000
    : false;

  return (
    <>{!context.isAuth && !isValid ? <Navigate to="/auth" /> : children}</>
  );
};

export default ProtectedRoute;
