import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import classes from "./Reports.module.scss";
import Calendar from "components/ui/icons/Сalendar";
import Check from "components/ui/icons/Check";
import { useSearchParams } from "react-router-dom";
import CrossFill from "components/ui/icons/CrossFill";
import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import { UserRoleContext } from "context/RoleContext";
import { splitDate } from "helpers/dates";
import ReportUploading from "./report-uploading/ReportUploading";
import Skeleton from "react-loading-skeleton";
import ReportsTable from "components/tables/ReportsTable/ReportsTable";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Choice } from "forms/dto";
import RightArrow from "components/ui/icons/RightArrow";
import { createParams } from "helpers/createParams";

const departmentList = [
  {
    name: "Belgorod",
    value: "2",
    label: "Belgorod",
  },
];

interface OptionType {
  isdisabled?: boolean;
  name?: string;
  label: string;
  value: string | number;
}

interface BreadcrumbsType {
  department?: string;
  project?: string;
  employee?: string;
}

const Reports = () => {
  const { userRoleContext } = useContext(UserRoleContext);
  const { apiGet } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [apiTable, setApiTable] = useState<string | null>(null);
  const [projectsList, setProjectsList] = useState<Choice[] | null>(null);
  const [membersList, setMembersList] = useState<Choice[] | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>();
  const [groupBy, setGroupBy] = useState<string>("task");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>({
    department: "Все проекты",
  });

  // << Обработчики для селектов

  const changeDateStartValue = (value: number | string | Date | string[]) => {
    if (value instanceof Date) {
      setSearchParams(
        createParams(searchParams, {
          date_after: splitDate(value.toLocaleDateString()),
        }),
      );
    }
  };

  const changeDateEndValue = (value: number | string | Date | string[]) => {
    if (value instanceof Date) {
      setSearchParams(
        createParams(searchParams, {
          date_before: splitDate(value.toLocaleDateString()),
        }),
      );
    }
  };

  const onChangeProjectSelect = (value: number | string | Date | string[]) => {
    if (value) {
      setSearchParams(
        createParams(searchParams, { project: value.toString() }),
      );
    }
  };

  const onChangeGroupBySellect = (value: number | string | Date | string[]) => {
    if (typeof value === "string") {
      setGroupBy(value);
    }
  };

  const onChangeDepartmentSellect = (
    value: number | string | Date | string[],
  ) => {
    if (value) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangeMemberSelect = (value: number | string | Date | string[]) => {
    if (value) {
      setSearchParams(
        createParams(searchParams, { employee: value.toString() }),
      );
    }
  };

  //  Обработчики для селектов >>

  const onClickBreadcrumbs = (index: number) => {
    const breadcrumbKeys = Object.keys(breadcrumbs);
    const slicedArray = breadcrumbKeys.splice(index + 1);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    slicedArray.forEach((item) => newSearchParams.delete(`${item}`));
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const getProjectsList = async () => {
      const res = await apiGet(endpoints.projects);
      setProjectsList(res.data.data);
    };

    const getMembersList = async () => {
      const res = await apiGet(endpoints.userChoices);
      const newOptions: Choice[] = [];

      if (res.data) {
        res.data.forEach((item: OptionType & { id: number }) => {
          newOptions.push({
            name: item.name,
            label: item.name || "",
            value: item.value || item.id,
          });
        });

        setMembersList(newOptions);
      } else if (res.errorMessage && res.errorMessage.length !== 0) {
        console.error(res.errorMessage);
      }
    };

    getMembersList();
    getProjectsList();
  }, []);

  useLayoutEffect(() => {
    if (searchParams.has("department") && departmentList) {
      const depart = departmentList.find(
        (i) => i.value?.toString() === searchParams.get("department"),
      );
      breadcrumbs.department = depart?.name;
    } else {
      breadcrumbs.department = "Все проекты";
    }

    if (searchParams.has("project") && projectsList) {
      const proj = projectsList.find(
        (i) => i.id?.toString() === searchParams.get("project"),
      );
      breadcrumbs.project = proj?.name;
    } else {
      delete breadcrumbs.project;
    }

    if (searchParams.has("employee") && membersList) {
      const empl = membersList.find(
        (i) => i.value.toString() === searchParams.get("employee"),
      );
      breadcrumbs.employee = empl?.name;
    } else {
      delete breadcrumbs.employee;
    }

    setBreadcrumbs(breadcrumbs);
    if (resetSelect) setResetSelect(false);
  });

  useEffect(() => {
    setApiTable(`${endpoints.reports}${groupBy}/?${searchParams}`);
    setResetSelect(true);
  }, [searchParams, groupBy]);

  // << Классы для инпутов
  const [isCalendarsOpen, setIsCalendarsOpen] = useState(false);

  let classNameInputs;

  if (isCalendarsOpen) {
    userRoleContext.data?.can_view_by_department
      ? (classNameInputs = classes.inputsOpenTM)
      : (classNameInputs = classes.inputsOpen);
  } else {
    userRoleContext.data?.can_view_by_department
      ? (classNameInputs = classes.inputsTM)
      : (classNameInputs = classes.inputs);
  }
  //  Классы для инпутов >>

  const uploadingParams = {
    date_after: searchParams.get("date_after"),
    date_before: searchParams.get("date_before"),
    project: searchParams.get("project"),
    employee: searchParams.get("employee"),
    ordering: sortBy,
  };

  const skeleton = {
    count: 1,
    style: {
      height: "100px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const skeletonTable = {
    count: 8,
    style: {
      height: "40px",
      marginBottom: "16px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeletonTable,
  };

  return Object.entries(userRoleContext.data).length ? (
    <div className={classes.container}>
      <div className={classes.breadcrumbs}>
        {Object.values(breadcrumbs).map((breadcrumb, index) => (
          <span
            onClick={() => onClickBreadcrumbs(index)}
            className={classes.breadcrumb}
            key={index}
          >
            {" "}
            {index !== 0 && <RightArrow />} {breadcrumb}
          </span>
        ))}
      </div>
      <div className={classes.filter}>
        <div className={classNameInputs}>
          {userRoleContext.data?.can_view_by_department && (
            <SelectWrapper
              name="department"
              placeholder="Проекты / Офис"
              value={searchParams.get("department")}
              onChange={onChangeDepartmentSellect}
              reset={resetSelect}
              // Для этого селекта нужны данные с бека
              choices={[
                {
                  name: "Все подразделения",
                  value: "all",
                  label: "all",
                },
                ...departmentList,
              ]}
            />
          )}
          {projectsList && (
            <SelectWrapper
              name="projects"
              placeholder="Выбрать проект"
              value={Number(searchParams.get("project"))}
              onChange={onChangeProjectSelect}
              reset={resetSelect}
              choices={[
                {
                  label: "Все проекты",
                  value: "all",
                  name: "Все проекты",
                },
                ...projectsList,
              ]}
            />
          )}
          {isCalendarsOpen ? (
            <div
              className={
                userRoleContext.data?.can_view_by_department
                  ? classes.openedCalendarsContainerTM
                  : classes.openedCalendarsContainer
              }
            >
              <div className={classes.openedCalendars}>
                <DateInput
                  name="reportStartDateOpen"
                  label="Дата начала:"
                  value={searchParams.get("date_after")}
                  onChange={(value) => changeDateStartValue(value)}
                />
                <DateInput
                  name="reportFinishDateOpen"
                  label="Дата окончания:"
                  value={searchParams.get("date_before")}
                  onChange={(value) => changeDateEndValue(value)}
                />
              </div>
              <div className={classes.controls}>
                <div role="button" onClick={() => setIsCalendarsOpen(false)}>
                  <Check clazz={classes.check} />
                </div>
                <div
                  role="button"
                  onClick={() => {
                    setIsCalendarsOpen(false);
                    setSearchParams(undefined);
                  }}
                >
                  <CrossFill clazz={classes.close} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <DateInput
                name="from"
                label="с"
                value={searchParams.get("date_after")}
                placeholder="Выбрать дату"
                onChange={(value) => changeDateStartValue(value)}
              />
              <DateInput
                name="to"
                label="до"
                value={searchParams.get("date_before")}
                placeholder="Выбрать дату"
                onChange={(value) => changeDateEndValue(value)}
              />
              <div
                className={classes.calendar}
                onClick={() => setIsCalendarsOpen(true)}
              >
                <Calendar clazz={classes.calendar__icon} />
              </div>
            </>
          )}
          {userRoleContext.data?.can_view_by_department ? (
            <SelectWrapper
              name="representation"
              placeholder="Представление"
              value={groupBy}
              onChange={onChangeGroupBySellect}
              choices={[
                {
                  name: "По задачам",
                  label: "По задачам",
                  value: "task",
                },
                {
                  name: "По участникам",
                  label: "По участникам",
                  value: "employee",
                },
                {
                  name: "По проектам",
                  label: "По проектам",
                  value: "project",
                },
                {
                  name: "По подразделениям",
                  label: "По подразделениям",
                  value: "department",
                },
              ]}
            />
          ) : null}
          {userRoleContext.data?.can_view_by_projects_report && membersList ? (
            <SelectWrapper
              disabled={false}
              name="employees"
              placeholder="Участники"
              onChange={onChangeMemberSelect}
              value={Number(searchParams.get("employee"))}
              reset={resetSelect}
              choices={[
                {
                  label: "Все участники",
                  value: "all",
                  name: "Все участники",
                },
                ...membersList,
              ]}
            />
          ) : null}
        </div>
        <ReportUploading
          groupBy={groupBy}
          params={uploadingParams}
          fileName={"Report"}
        />
      </div>
      <div className={classes.table}>
        {apiTable && (
          <ReportsTable
            apiUrl={apiTable}
            sortByCallback={setSortBy}
            skeleton={skeletonTable}
            onlyOnceSkeleton={true}
          />
        )}
      </div>
    </div>
  ) : (
    <Skeleton
      containerClassName={skeleton.containerClassName}
      count={skeleton.count}
      style={skeleton.style}
    />
  );
};

export default Reports;
