import Lock from "components/ui/icons/Lock";
import { WidgetComponent } from "forms/components/widget";
import classes from "./PasswordInput.module.scss";
import Eye from "components/ui/icons/Eye";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useRef, useState } from "react";

const PasswordInput = ({
  label,
  name,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  placeholder,
  onChange,
  required,
  resDataAfterSubmit,
  form,
}: WidgetComponent) => {
  const [isShow, setIsShow] = useState(false);
  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );
  const passwordContainer = useRef(null);
  const elId = `id_${name}`;

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorAfterSubmit(null);
    onChange(e.target.value);
  };

  return (
    <section
      className={[classes.container, classes[name]].join(" ")}
      data-error={errorAfterSubmit ? true : undefined}
      ref={passwordContainer}
    >
      {label && (
        <label htmlFor={elId}>
          {name === "oldPassword" && <Lock clazz={classes.icon} />}
          {label}
        </label>
      )}
      <div className={classes.wrapper}>
        <input
          form={form}
          className={classes.input}
          placeholder={placeholder}
          name={name}
          id={elId}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          defaultValue={!(value instanceof Date) && value ? value : ""}
          {...(inputArgs || {})}
          type={isShow ? "text" : "password"}
        ></input>
        <div className={classes.eye} onClick={() => setIsShow(!isShow)}>
          <Eye
            clazz={
              isShow
                ? [classes.eye__icon, classes["eye__icon--show"]].join(" ")
                : classes.eye__icon
            }
          />
        </div>
      </div>
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default PasswordInput;
