import classes from "./ProjectInfo.module.scss";
import Description from "./description/Description";
import Journal from "./journal/Journal";

interface ProjectInfoPropsType {
  redaktPermit: boolean;
}

// eslint-disable-next-line react/prop-types
const ProjectInfo = ({ redaktPermit }: ProjectInfoPropsType) => {
  return (
    <div className={classes.container}>
      <Description redaktPermit={redaktPermit} />
      <Journal redaktPermit={redaktPermit} />
    </div>
  );
};

export default ProjectInfo;
