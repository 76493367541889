import Arrow from "components/ui/icons/Arrow";
import classes from "./MinSizeUserComponent.module.scss";
import { OpenedBlocksContext } from "context/OpenedBlocks";
import { ApiDataType, userType } from "types";
import EmptyImage from "assets/img/empty-image.png";
import { UserInfoContext } from "context/UserInfoContext";
import { useContext, useEffect, useState } from "react";

type minSizeContainerProps = {
  changeProfileState: () => void;
};

const MinSizeUserComponent = ({
  changeProfileState,
}: minSizeContainerProps) => {
  const { isBlockOpen } = useContext(OpenedBlocksContext);
  const { userInfoContext } = useContext(UserInfoContext);

  const buttonClassName = isBlockOpen.isProfileOpen
    ? [classes.arrow, classes["arrow--open"]].join(" ")
    : classes.arrow;

  const [userInfo, setUserInfo] = useState<ApiDataType<
    userType,
    string[]
  > | null>(null);

  useEffect(() => {
    setUserInfo(userInfoContext);
  }, [isBlockOpen, userInfoContext]);

  return (
    <div className={classes.minSizeContainer}>
      <div className={classes.wrapper}>
        <img
          src={userInfo?.data.photo ? userInfo.data.photo : EmptyImage}
          alt="user avatar"
          className={classes.minSizeAvatar}
        />
        <div className={classes.minSizeInfoContainer}>
          <span
            className={classes.name}
          >{`${userInfo?.data.first_name} ${userInfo?.data.last_name}`}</span>
          <span className={classes.job}>{userInfo?.data.position}</span>
        </div>
      </div>
      <button
        type="button"
        className={buttonClassName}
        onClick={() => changeProfileState()}
      >
        <Arrow clazz={classes.arrow__icon} />
      </button>
    </div>
  );
};

export default MinSizeUserComponent;
