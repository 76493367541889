import classes from "./MembersList.module.scss";
import MembersItem from "components/project-case/project-details/members-item/MembersItem";
import { ApiDataType, EmployeeType } from "types";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface MembersListType {
  isOpen?: boolean;
  containerClass?: string;
  redact?: boolean;
  redactCallback?: (id: string, action: string) => void;
  members: ApiDataType<{ employee: EmployeeType }[], string[]> | null;
}

const MembersList = ({
  isOpen,
  containerClass,
  redact = false,
  redactCallback = undefined,
  members,
}: MembersListType) => {
  const container = isOpen
    ? [classes.container, containerClass, classes["container--open"]].join(" ")
    : [classes.container, containerClass].join(" ");

  return (
    <div className={container}>
      {members ? (
        members.errorMessage.length ? (
          members.errorMessage.map((i, idx) => {
            return <ErrorComponent key={idx}>{i}</ErrorComponent>;
          })
        ) : members.data.length ? (
          members.data.map((i, idx) => {
            return (
              <MembersItem
                key={idx}
                redact={redact}
                redactCallback={redactCallback}
                user={i.employee || i}
              />
            );
          })
        ) : null
      ) : (
        <Skeleton
          count={3}
          style={{ display: "block", height: "50px", width: "100%" }}
        />
      )}
    </div>
  );
};

export default MembersList;
