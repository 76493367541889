import { useDebounce } from "hooks/useDebounce/useDebounce";
import { useEffect, useState } from "react";
import classes from "../ManegementForms.module.scss";
import Search from "components/common/search/Search";
import FieldEditItemInfo from "components/ui/field-edit-item-info/FieldEditItemInfo";
import { useApi } from "hooks/useApi/useApi";

type SelectedType = { id: string; name: string; selected_link: string };

export const MOCK_LIST_ONE = [
  { label: "Project-менеджмент", name: "1", value: "1" },
  { label: "Back разработка", name: "2", value: "2" },
  { label: "PR-менеджмент", name: "3", value: "3" },
  { label: "HR-менеджмент", name: "4", value: "4" },
  { label: "Лаврова Ирина", name: "5", value: "5" },
  { label: "Дизайн", name: "6", value: "6" },
];

export const MOCK_LIST_TWO = [
  { label: "Java script", name: "1", value: "1" },
  { label: "React", name: "2", value: "2" },
  { label: "CSS", name: "3", value: "3" },
  { label: "PHP", name: "4", value: "4" },
  { label: "Figma", name: "5", value: "5" },
  { label: "Adobe Photoshop", name: "6", value: "6" },
];

const FormColumn = ({
  title,
  initValues,
  mock,
  employData,
  setEmployData
}: {
  title: string;
  initValues?: any;
  mock?: any;
  employData?: any,
  setEmployData?: any
}) => {
  const [search, setSearch] = useState("");
  const { apiPost, apiGet, apiDelete } = useApi();
  const [mocking, setMocking] = useState<any[]>([]);
  const [selected, setSearchSelected] = useState<any[]>(
    initValues || [],
  );
  const [searchAutocomliteList, setSearchAutocompliteList] = useState<any>([]);
  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearch(e.target.value.trim());
    } else {
      setSearch("");
    }
  };
  const onSearchActive = (
    type: "add" | "sub" | "clear",
    value?: null | any,
  ) => {
    if (type === "add" && value) {
      setSearchSelected((prev) => [...prev, value]);
    } else if (type === "sub" && value) {
      setSearchSelected((prev) =>
        prev.filter((item) => value.id !== item.id),
      );
    } else {
      setSearchSelected([]);
    }
  };
  const onCreate = (value: string) => {
    return 0;
  };

  const onDeleteField = async (value: SelectedType) => {
    try {
      await apiDelete(`${process.env.REACT_APP_API_URL}api/employee/competence_category/${value.id}`);
      
      // Обновление состояния только после успешного удаления
      setSearchSelected((prev: any) =>
        prev.filter((item:any) => value.id !== item.id)
      );
      setEmployData(selected);
    } catch (error) {
      console.error("Error deleting the item:", error);
    }
  };

  const onSearchDebounce = useDebounce(_search, 500);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (search && search?.length >= 0 && mock) {
  //     console.log(mock, 'asd');
  //     setSearchAutocompliteList(mock);
  //   } else {
  //     setSearchAutocompliteList([]);
  //   }
  //   }, 300)
  // }, [search, employData]);
  useEffect(() => {
    const getEmployeesList = async () => {
      try {
        const responsePost = await apiPost(`${process.env.REACT_APP_API_URL}api/employee/competence_category/`, 
          {name: search,
          id: mock?.id, 
          education_link: mock?.education_link});   
        const resolve = await apiGet(`${process.env.REACT_APP_API_URL}api/employee/competence_category/`);
        
        if (resolve.data?.data) {
          setEmployData([...resolve.data.data, responsePost]);
          console.log([...resolve.data.data, responsePost]);
        }
        /* eslint-disable-next-line no-unsafe-optional-chaining */
        setTimeout(() => {
          /* eslint-disable-next-line no-unsafe-optional-chaining */
          if (search && search?.length >= 0 && [...resolve.data?.data, responsePost]) {
            /* eslint-disable-next-line no-unsafe-optional-chaining */
          setSearchAutocompliteList([...resolve.data?.data, responsePost]);
        } else {
          setSearchAutocompliteList([]);
        }
        }, 300);
        /* eslint-disable-next-line no-unsafe-optional-chaining */
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    };

    if (search) {
      getEmployeesList();
    }
   
  }, [search, employData]);
  return (
    <div className={classes.col}>
      <h3 className={classes.subtitle}>{title}</h3>
      <Search
        onInput={onSearchDebounce}
        placeholder="Начните ввод"
        clazz={classes.search}
        initialValue={search}
        autoFocus={false}
        shadow={false}
        onCreate={onCreate}
        autocomplite={searchAutocomliteList}
        selected={selected}
        onClickAutocomplite={onSearchActive}
        clxs={{
          clxsAutocomplite: classes.listKnowledge,
          clxsAutocompliteList: classes.listKnowledge,
        }}
        multi
      />
      <ul className={classes.list}>
        {selected.map((item, index) => (
          <li key={index}>
            <FieldEditItemInfo
              title={item.name}
              onDelete={() => onDeleteField(item)}
              onEditSave={() => void 0}
              link=""
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FormColumn;
