interface IconProps {
  clazz?: string;
}

const Arrow = ({ clazz }: IconProps) => {
  return (
    <svg className={clazz} width="16" height="8" viewBox="0 0 16 8" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29286 5.76117C8.12434 5.90562 7.87567 5.90562 7.70715 5.76117L1.4881 0.430557C1.1736 0.16099 0.700127 0.197412 0.43056 0.511906C0.160993 0.8264 0.197415 1.29988 0.511909 1.56944L6.73096 6.90006C7.46121 7.52599 8.53879 7.52599 9.26904 6.90006L15.4881 1.56944C15.8026 1.29988 15.839 0.826401 15.5694 0.511907C15.2999 0.197412 14.8264 0.160991 14.5119 0.430558L8.29286 5.76117Z"
      />
    </svg>
  );
};

export default Arrow;
