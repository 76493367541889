import classes from "../ManegementForms.module.scss";
import FormColumn, { MOCK_LIST_ONE, MOCK_LIST_TWO } from "./FormColumn";

const FormUserExpertise = ({employData, employSkills, setEmployData} : {employData?: any, employSkills?: any, setEmployData?: (item: any) => void}) => {
  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn
              title={"Добавить область знания"}
              mock={employData}
              setEmployData={setEmployData}
              initValues={[
                { name: "HR-менеджмент", id: "4", education_link: "" },
                { name: "Лаврова Ирина", id: "5", education_link: "" },
              ]}
            />
            <FormColumn
              title={"Добавить навык"}
              mock={employSkills}
              initValues={[
                { name: "CSS", id: "3", education_link: "" },
                { name: "PHP", id: "4", education_link: "" },
                { name: "Figma", id: "5", education_link: "" },
              ]}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormUserExpertise;
