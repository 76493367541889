interface IconProps {
  clazz?: string;
}

const Projects = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 2.8125C11.435 2.8125 9.89594 4.35152 9.89594 6.25V7.09839H5C3.10152 7.09839 1.5625 8.63741 1.5625 10.5359V12.6043C1.5625 13.5561 1.95395 14.4373 2.60437 15.0697V21.2498C2.60437 24.5289 5.26268 27.1873 8.54187 27.1873H21.4585C24.7377 27.1873 27.396 24.5289 27.396 21.2498V15.0693C28.0462 14.4369 28.4375 13.5559 28.4375 12.6043V10.5359C28.4375 8.63741 26.8985 7.09839 25 7.09839H20.1043V6.25C20.1043 4.35152 18.5653 2.8125 16.6668 2.8125H13.3334ZM18.2293 7.09839V6.25C18.2293 5.38705 17.5297 4.6875 16.6668 4.6875H13.3334C12.4705 4.6875 11.7709 5.38706 11.7709 6.25V7.09839H18.2293ZM4.47937 21.2498V16.037L13.2939 18.6813C14.4068 19.0152 15.5932 19.0152 16.7061 18.6813L25.521 16.0369V21.2498C25.521 23.4934 23.7022 25.3123 21.4585 25.3123H8.54187C6.29821 25.3123 4.47937 23.4934 4.47937 21.2498ZM3.4375 10.5359C3.4375 9.67294 4.13706 8.97339 5 8.97339H25C25.8629 8.97339 26.5625 9.67294 26.5625 10.5359V12.6043C26.5625 13.2944 26.1099 13.9027 25.449 14.1009L16.1673 16.8854C15.4059 17.1139 14.5941 17.1139 13.8327 16.8854L4.55102 14.1009C3.89011 13.9027 3.4375 13.2944 3.4375 12.6043V10.5359ZM15.9375 12.3218C15.9375 11.804 15.5178 11.3843 15 11.3843C14.4822 11.3843 14.0625 11.804 14.0625 12.3218V14.4646C14.0625 14.9824 14.4822 15.4021 15 15.4021C15.5178 15.4021 15.9375 14.9824 15.9375 14.4646V12.3218Z"
      />
    </svg>
  );
};

export default Projects;
