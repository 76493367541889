import classes from "./BasicLayout.module.scss";
import Header from "components/common/header/Header";
import Sidebar from "components/common/sidebar/Sidebar";

interface AuxProps {
  children: React.ReactNode;
}

const BasicLayout = ({ children }: AuxProps) => {
  return (
    <>
      <Header />
      <main className={classes["main-container"]}>
        <Sidebar />
        {children}
      </main>
    </>
  );
};
export default BasicLayout;
