interface IconProps {
  clazz?: string;
}

const ArrowGrey = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.5C9.73478 13.5 9.48043 13.3946 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.70711 7.79289L10 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.5196 13.3946 10.2652 13.5 10 13.5Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default ArrowGrey;
