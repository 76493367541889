import { WidgetComponent } from "forms/components/widget";
import classes from "./EmailInput.module.scss";
import Mail from "components/ui/icons/Mail";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useState } from "react";

const EmailInput = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  required,
  resDataAfterSubmit,
  form,
}: WidgetComponent) => {
  const elId = `id_${name}`;

  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorAfterSubmit(null);
    onChange(e.target.value);
  };

  return (
    <section
      className={classes.container}
      data-error={errorAfterSubmit ? true : undefined}
    >
      {label ? (
        <label htmlFor={elId}>
          {placeholder === "Новый e-mail" && <Mail clazz={classes.icon} />}
          {label}
        </label>
      ) : null}
      <input
        form={form}
        className={classes.input}
        name={name}
        id={elId}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnly}
        {...(inputArgs || {})}
        type="email"
        placeholder={placeholder}
        required={required}
      ></input>
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default EmailInput;
