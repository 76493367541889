import { createContext } from "react";
import { ApiDataType } from "types";

interface UserRoleContextType {
  userRoleContext: ApiDataType;
}

const defaultValue = {
  userRoleContext: {
    data: null,
    errorMessage: null,
  },
};

export const UserRoleContext = createContext<UserRoleContextType>(defaultValue);
