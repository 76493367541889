import classes from "./MembersItem.module.scss";
import EmptyImage from "assets/img/empty-image.png";
import Delete from "components/ui/icons/Delete";
import AddUser from "components/ui/icons/AddUser";
import Cross from "components/ui/icons/Cross";
import Check from "components/ui/icons/Check";
import { useState } from "react";

interface MembersListType {
  user: {
    photo?: string;
    first_name: string;
    last_name?: string;
    position: string;
    department?: string;
    inProject?: boolean;
    id: string;
    idInProject?: string | number;
  };
  redact?: boolean;
  redactCallback?: (
    id: string,
    action: string,
    idInProject?: string | number,
  ) => void;
}

const MembersItem = ({
  user,
  redact = false,
  redactCallback = undefined,
}: MembersListType) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const confirm = (
    id: string,
    action: string,
    idInProject?: string | number,
  ) => {
    redactCallback && redactCallback(id, action, idInProject);
    setIsConfirm(false);
  };

  return (
    <div className={classes["members"]}>
      <div className={classes["members__image"]}>
        <img
          src={user.photo ? user.photo : EmptyImage}
          alt=""
          className={classes["members__photo"]}
        />
      </div>
      <div className={classes["members__info"]}>
        <p className={classes["members__name"]}>
          {user.first_name} {user.last_name}
        </p>
        {isConfirm ? (
          <p className={classes["confirm-text"]}>Удалить участника?</p>
        ) : (
          <>
            <p className={classes["members__role"]}>{user.position}</p>
            {user.department && (
              <p className={classes["members__office"]}>{user.department}</p>
            )}
          </>
        )}
      </div>
      {redact && (
        <div className={classes.controls}>
          {isConfirm ? (
            <div className={classes["confirm-container"]}>
              <button
                type="button"
                title="Подтвердить удаление"
                className={classes.confirm}
                onClick={() => confirm(user.id, "remove", user.idInProject)}
              >
                <Check clazz={classes.confirm__icon} />
              </button>
              <button
                type="button"
                title="Отменить удаление"
                className={classes.cancel}
                onClick={() => setIsConfirm(false)}
              >
                <Cross clazz={classes.cancel__icon} />
              </button>
            </div>
          ) : user.inProject ? (
            <button
              type="button"
              title="Удалить"
              className={classes.delete}
              onClick={() => setIsConfirm(true)}
            >
              <Delete clazz={classes.delete__icon} />
            </button>
          ) : (
            <button
              type="button"
              title="Добавить"
              className={classes.add}
              onClick={() => redactCallback && redactCallback(user.id, "add")}
            >
              <AddUser clazz={classes.add__icon} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MembersItem;
