import classes from "./Sorting.module.scss";
import Arrow2 from "components/ui/icons/Arrow2";
import AZ from "components/ui/icons/AZ";
import Сalendar from "components/ui/icons/Сalendar";
import Preview from "components/ui/icons/Preview";

interface SortingProps {
  changeSortByName: () => void;
  sortByName: string | undefined;
  changeSortByDate: () => void;
  sortByDate: string | undefined;
  changePreview: () => void;
  isSingleColumn: boolean;
}

const Sorting = ({
  changeSortByName,
  sortByName,
  changeSortByDate,
  sortByDate,
  changePreview,
  isSingleColumn,
}: SortingProps) => {
  const activeSortByNameClass =
    sortByName === "name"
      ? classes["active--asc"]
      : sortByName === "-name"
        ? classes["active--desc"]
        : false;
  const byNameContainerClass = activeSortByNameClass
    ? [classes["sort__button"], classes["byname"], activeSortByNameClass].join(
      " ",
    )
    : [classes["sort__button"], classes["byname"]].join(" ");
  const activeSortByDateClass =
    sortByDate === "-date_start"
      ? classes["active--asc"]
      : sortByDate === "date_start"
        ? classes["active--desc"]
        : false;
  const byDateContainerClass = activeSortByDateClass
    ? [classes.sort__button, activeSortByDateClass].join(" ")
    : classes.sort__button;
  return (
    <div className={classes.container}>
      <div className={classes["sort-container"]}>
        <button className={byNameContainerClass} onClick={changeSortByName}>
          <span className={classes["sort__button-text"]}>По алфавиту</span>
          <div className={classes["icons-list"]}>
            <Arrow2 clazz={classes["icons-list__icon-arrow"]} />
            <AZ clazz={classes["icons-list__icon-az"]} />
          </div>
        </button>
        <button className={byDateContainerClass} onClick={changeSortByDate}>
          <span className={classes["sort__button-text"]}>По дате</span>
          <div className={classes["icons-list"]}>
            <Сalendar clazz={classes["icons-list__icon-calendar"]} />
            <Arrow2 clazz={classes["icons-list__icon-arrow"]} />
          </div>
        </button>
      </div>
      <button className={classes.preview__button} onClick={changePreview}>
        <Preview
          clazz={`${classes["preview__button-icon"]} ${
            isSingleColumn ? classes.rotate : ""
          }`}
        />
      </button>
    </div>
  );
};

export default Sorting;
