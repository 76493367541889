export const endpoints = {
  //auth
  urlToken: `${process.env.REACT_APP_API_URL}api/account/token/`,
  urlTokenRefresh: `${process.env.REACT_APP_API_URL}api/account/token/refresh/`,
  urlGetConfirmCode: `${process.env.REACT_APP_API_URL}api/account/password_reset/token/`,
  urlVerifyConfirmCode: `${process.env.REACT_APP_API_URL}api/account/password_reset/token/verify/`,
  urlResetPassword: `${process.env.REACT_APP_API_URL}api/account/password_reset/`,
  //forms
  login: `${process.env.REACT_APP_API_URL}api/account/token/form/`,
  email: `${process.env.REACT_APP_API_URL}api/account/password_reset/token/form/`,
  confirmCode: `${process.env.REACT_APP_API_URL}api/account/password_reset/token/verify/form/`,
  resetPassword: `${process.env.REACT_APP_API_URL}api/account/password_reset/form/`,
  changePasswordForm: `${process.env.REACT_APP_API_URL}api/account/password_change/form/`,
  loggingAddTask: `${process.env.REACT_APP_API_URL}api/project/project_time_tracking/create_form/`,
  projectsCreate: `${process.env.REACT_APP_API_URL}api/project/projects/create_form/`,
  vacationForm: `${process.env.REACT_APP_API_URL}api/employee/employee_vacation/create_form/`,
  dayoffForm: `${process.env.REACT_APP_API_URL}api/employee/employee_days_off/create_form/`,
  //other
  shortCurrentUserInfo: `${process.env.REACT_APP_API_URL}api/employee/current/short/`,
  fullCurrentUserInfo: `${process.env.REACT_APP_API_URL}api/employee/current/full/`,
  skillsList: `${process.env.REACT_APP_API_URL}api/employee/competence_categories/`,
  currentUserCompetences: `${process.env.REACT_APP_API_URL}api/employee/employee_competence/`, //таблица области знаний
  changePassword: `${process.env.REACT_APP_API_URL}api/account/password_change/`,
  userLoggingList: `${process.env.REACT_APP_API_URL}api/project/project_time_tracking/?employee=`,
  addUserLogging: `${process.env.REACT_APP_API_URL}api/project/project_time_tracking/`,
  vacation: `${process.env.REACT_APP_API_URL}api/employee/employee_vacation/`,
  dayoff: `${process.env.REACT_APP_API_URL}api/employee/employee_days_off/`,
  user: `${process.env.REACT_APP_API_URL}api/employee/employees/`,
  userChoices: `${process.env.REACT_APP_API_URL}api/employee/employees/choices/`,
  project: `${process.env.REACT_APP_API_URL}api/project/project/`,
  projects: `${process.env.REACT_APP_API_URL}api/project/projects/`,
  projectJornal: `${process.env.REACT_APP_API_URL}api/project/project_diary_entries/`,
  projectMembers: `${process.env.REACT_APP_API_URL}api/project/project_employees/`,
  projectCreatePermit: `${process.env.REACT_APP_API_URL}api/permissions/permissions/current_department/`,
  projectEmployeesList: `${process.env.REACT_APP_API_URL}api/project/project_employees/?project=`,
  reports: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report/`,
  reportProject: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report/project/`,
  reportTaskOfProject: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report/task/`,
  reportTaskOfEmpoyee: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report/employee/`,
  permit: `${process.env.REACT_APP_API_URL}api/permissions/permissions/`,
  reportsDepartment: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report/department/`,
  userRoles: `${process.env.REACT_APP_API_URL}api/permissions/permissions/current_department/`,
  reportCsv: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report_csv/`,
  reportXLXS: `${process.env.REACT_APP_API_URL}api/project/time_tracking_report_xlsx/`,
  planningDayList: `${process.env.REACT_APP_API_URL}api/planning/day_list/`,
  planningForm: `${process.env.REACT_APP_API_URL}api/planning/employee_planning/create_form/`,
  planningEmployee: `${process.env.REACT_APP_API_URL}api/planning/employee_planning/`,
  planningEmployeeList: `${process.env.REACT_APP_API_URL}api/planning/employee_planning_list/`,
  planningProject: `${process.env.REACT_APP_API_URL}api/planning/project_planning/`,
  planningTotal: `${process.env.REACT_APP_API_URL}api/planning/total/`,
  planningChoices: `${process.env.REACT_APP_API_URL}api/planning/employee_planning_list/query_form/choices/`,
};
