import classes from "./ReportsTable.module.scss";
import ArrowDown from "components/ui/icons/ArrowDown";
import ArrowUp from "components/ui/icons/ArrowUp";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnResizeMode,
  RowPinningState,
} from "@tanstack/react-table";
import { SkeletonType } from "types";
import useApiTable from "hooks/useApiTable/useApiTable";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { TProject } from "../components/dto";

type TTableReportsProps = {
  apiUrl: string;
  sortByCallback?: React.Dispatch<React.SetStateAction<string | undefined>>;
  skeleton?: SkeletonType;
  onlyOnceSkeleton?: boolean;
};

const ReportsTable = ({
  apiUrl,
  sortByCallback,
  skeleton,
}: TTableReportsProps) => {
  const [isShowSkeleton] = useState<boolean>(true);

  const columnHelper = createColumnHelper<TProject>();

  const tableData = useApiTable(apiUrl);

  const columns = tableData.columns.map((column) =>
    columnHelper.accessor((row) => row[column.name], {
      id: column.name,
      header: () => `${column.label}`,
      footer: (info) => info.column.id,
      enableSorting: column.sortable,
    }),
  );

  const [data, setData] = useState<TProject[]>(
    tableData.data && tableData.data,
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (sortByCallback && sorting[0]?.id) {
      sortByCallback(sorting[0].id);
    }
  }, [sorting]);

  const [columnResizeMode] = useState<ColumnResizeMode>("onChange");
  const [rowPinning, setRowPinning] = useState<RowPinningState>({
    top: [],
    bottom: [],
  });

  useEffect(() => {
    if (tableData.data.length > 0)
      setRowPinning({
        ...rowPinning,
        bottom: [`${tableData.data.length - 1}`],
      });
    setData(tableData.data);
  }, [tableData.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowPinning,
    },
    columnResizeMode,
    onRowPinningChange: setRowPinning,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (tableData.isLoading && isShowSkeleton && skeleton) {
    const defaultStyle = {
      display: "block",
      width: "100%",
      height: "20px",
    };

    const style = { ...defaultStyle, ...skeleton?.style };
    const containerClassName = skeleton?.containerClassName
      ? [classes["skeleton-container"], skeleton.containerClassName].join(" ")
      : classes["skeleton-container"];

    return (
      <Skeleton
        containerClassName={containerClassName}
        count={skeleton?.count || 6}
        style={style}
        circle={skeleton?.circle}
      />
    );
  }

  return (
    <div>
      <table className={classes.table}>
        <thead className={classes.header}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={classes.header__tr}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    className={classes.header__th}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <ArrowDown />,
                            desc: <ArrowUp />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `${classes.header__item} ${
                              classes.resizer
                            } ${
                              header.column.getIsResizing()
                                ? classes.isResizing
                                : ""
                            }`,
                            style: {
                              transform: header.column.getIsResizing()
                                ? `translateX(${
                                  -1 *
                                    (table.getState().columnSizingInfo
                                      .deltaOffset ?? 0)
                                }px)`
                                : "",
                            },
                          }}
                        />
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getCenterRows().map((row) => (
            <tr key={row.id} className={classes.row}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classes.cell}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getBottomRows().map((row) => (
            <tr key={row.id} className={classes.row}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classes.cell}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
