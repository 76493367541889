import Paper from "components/ui/paper/Paper";
import classes from "./ManagementHeader.module.scss";
import InputRadioButton from "components/ui/input-radio-button/InputRadioButton";

type TManagementHeaderProps = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

const ManagementHeader = ({ setTab }: TManagementHeaderProps) => {
  return (
    <Paper>
      <div className={classes.tabs}>
        <InputRadioButton
          type="radio"
          name="tabs"
          defaultChecked
          onChange={() => setTab("skills")}
          className={classes.radio}
          label="Управление талантами"
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => setTab("analytics")}
          id="analytics"
          className={classes.radio}
          label="HR-аналитика"
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => setTab("archive")}
          id="archive"
          className={classes.radio}
          label="Архив соискателей"
          disabled
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => setTab("archive")}
          id="archive"
          className={classes.radio}
          label="САЧ"
          disabled
        />
      </div>
    </Paper>
  );
};

export default ManagementHeader;
