import classes from "./TopBlockMin.module.scss";
import EmptyImage from "assets/img/empty-image.png";
import Arrow from "components/ui/icons/Arrow";
import { ApiDataType, ProjectType } from "types";
import ErrorComponent from "components/common/error-component/ErrorComponent";

interface TopBlockMinType {
  closeBlock: (value?: boolean) => void;
  projectInfo: ApiDataType<ProjectType, string[]> | null;
}

const TopBlockMin = ({ closeBlock, projectInfo }: TopBlockMinType) => {
  return (
    <div className={classes.container}>
      {projectInfo?.errorMessage.length ? (
        projectInfo.errorMessage.map((i, idx) => {
          return <ErrorComponent key={idx}>{i}</ErrorComponent>;
        })
      ) : (
        <>
          <div className={classes.image}>
            <img
              src={projectInfo?.data.icon ? projectInfo.data.icon : EmptyImage}
              alt=""
              className={classes.image__photo}
            />
          </div>
          <h1 className={classes.title}>{projectInfo?.data.name}</h1>
          <button
            type="button"
            className={classes.open}
            onClick={() => closeBlock()}
          >
            <Arrow clazz={classes.open__icon} />
          </button>
        </>
      )}
    </div>
  );
};

export default TopBlockMin;
