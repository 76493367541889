import { useId } from "react";
import classes from "./InputRadioButton.module.scss";

type TInputRadioButtonProps = {
  label: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputRadioButton = ({ label, ...props }: TInputRadioButtonProps) => {
  const genId = useId();
  const id = props.id || genId;
  return (
    <div className={classes.container}>
      <input
        {...props}
        id={id}
        className={`${classes.radio} ${props.className || ""} ${
          props.disabled ? classes.disabled : ""
        }`}
      />
      <label className={`${classes.tab}`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default InputRadioButton;
