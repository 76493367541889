import { endpoints } from "API/endpoints";
import Birthday from "components/ui/icons/Birthday";
import Lock from "components/ui/icons/Lock";
import Mail from "components/ui/icons/Mail";
import Phone from "components/ui/icons/Phone";
import Telegram from "components/ui/icons/Telegram";
import Form from "forms/components/form";
import { useApi } from "hooks/useApi/useApi";
import classes from "./UserFormComponent.module.scss";
import FormData from "form-data";
import { ApiDataType, FormDataType } from "types";
import { UserInfoContext } from "context/UserInfoContext";
import { useContext, useEffect, useState } from "react";

const UserFormComponent = () => {
  const [userInfo, setUserInfo] = useState<ApiDataType | null>(null);
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);
  const [resDataAfterSubmitForm, setResDataAfterSubmitForm] =
    useState<ApiDataType | null>(null);
  const { userInfoContext, setUserInfoContext } = useContext(UserInfoContext);

  const { apiPut, apiGet, apiPost } = useApi();

  const onSubmitAvatar = async (files: FormDataType) => {
    if (files.data instanceof FileList) {
      const formData = new FormData();
      formData.append("photo", files.data[0]);
      const res = await apiPut(
        `${endpoints.user}${userInfoContext?.data.id}/photo/`,
        formData,
        undefined,
        true,
      );
      setResDataAfterSubmit(res);
      if (res.data && userInfoContext) {
        const newUserInfoContext = { ...userInfoContext };
        newUserInfoContext.data.photo = res.data.photo;
        setUserInfoContext?.(newUserInfoContext);
      }
    }
  };

  useEffect(() => {
    const getFullUserInfo = async () => {
      const res = await apiGet(endpoints.fullCurrentUserInfo);
      setUserInfo(res);
    };
    getFullUserInfo();
  }, []);

  const onSubmitPasswordForm = async (
    form: FormDataType,
    reset?: () => void,
  ) => {
    const res = await apiPost(endpoints.changePassword, form, undefined, true);
    setResDataAfterSubmitForm(res);
    if (res.data) {
      reset?.();
    }
  };

  const avatarSkeleton = {
    style: {
      width: "98px",
      height: "98px",
      margin: "0 auto",
    },
    circle: true,
    containerClassName: classes["avatat-skeleton"],
  };

  const passwordSkeleton = {
    count: 4,
    style: {
      width: "272px",
      height: "36px",
      margin: "0 auto",
    },
    containerClassName: classes["password-skeleton"],
  };

  const avatarUrl = `${endpoints.user}${userInfoContext?.data.id}/photo/update_form/`;

  return (
    <>
      <Form
        apiUrl={avatarUrl}
        classValueForm={classes.formAvatar}
        onSubmit={onSubmitAvatar}
        formId={"userAvatarForm"}
        showBtn={false}
        resDataAfterSubmit={resDataAfterSubmit}
        skeleton={avatarSkeleton}
      />
      <div className={classes.userName}>
        <span className={classes.name}>
          {`${userInfo?.data.first_name} ${userInfo?.data.last_name}`}
        </span>
        <span className={classes.job}>{userInfo?.data.position}</span>
      </div>
      <div className={classes.contacts}>
        <div className={classes.contacts__item}>
          <a href="mailTo:johndoe@gmail.com" target="_blank" rel="noreferrer">
            <Mail clazz={classes.icon} />
            <span className={classes.contacts__text}>
              {userInfo?.data.email}
            </span>
          </a>
        </div>
        <div className={classes.contacts__item}>
          <a
            href={`tel:${userInfo?.data.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            <Phone clazz={classes.icon} />
            <span className={classes.contacts__text}>
              {userInfo?.data.phone}
            </span>
          </a>
        </div>
        <div className={classes.contacts__item}>
          <a
            href={`https://t.me/${userInfo?.data.telegram}`}
            target="_blank"
            rel="noreferrer"
          >
            <Telegram clazz={`${classes.icon} ${classes["icon--fill"]}`} />
            <span
              className={classes.contacts__text}
            >{`@${userInfo?.data.telegram}`}</span>
          </a>
        </div>
        <div className={classes.contacts__item}>
          <Birthday clazz={classes.icon} />
          <div className={classes.birthday}>
            <span className={classes.contacts__text}>
              День Рождения {userInfo?.data.birthday}
            </span>
          </div>
        </div>
        <div className={classes.contacts__item}>
          <Lock clazz={classes.icon} />
          <span className={classes.contacts__text}>Пароль</span>
        </div>
      </div>
      <Form
        apiUrl={endpoints.changePasswordForm}
        btnText="Сохранить"
        classValueForm={classes.form}
        classValueBtn={classes.formBtn}
        onSubmit={onSubmitPasswordForm}
        skeleton={passwordSkeleton}
        formId={"userForm"}
        resDataAfterSubmit={resDataAfterSubmitForm}
      />
    </>
  );
};

export default UserFormComponent;
