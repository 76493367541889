import { useRef, useState } from "react";
import DeleteIcon from "../icons/DeleteIcon";
import DoubleLine from "../icons/DoubleIcon";
import PenIcon from "../icons/PenIcon";
import classes from "./FieldEditItemInfo.module.scss";
import SuccessIcon from "../icons/SuccessIcon";
import LinkIcone from "../icons/LinkIcon";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";

const FieldEditItemInfo = ({
  title,
  onDelete,
  onEditSave,
  onEdit,
  link,
}: {
  title: string;
  onEdit?: () => void;
  onDelete: () => void;
  onEditSave: () => void;
  link?: string;
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(link || "");
  const handlerEdit = () => {
    setOpen(true);
    onEdit && onEdit();
  };
  const handlerDelete = () => {
    onDelete();
  };
  return (
    <div className={classes.item}>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          {open && <DoubleLine />}
          <p className={classes.title}>{title}</p>
          {open && (
            <button
              type="button"
              className={classes.btnEditSave}
              onClick={() => {
                setOpen(false);
                onEditSave();
              }}
            >
              <SuccessIcon />
            </button>
          )}
        </div>
        {!open && (
          <div className={classes.actions}>
            <button
              type="button"
              className={classes.btnEdit}
              onClick={handlerEdit}
            >
              <PenIcon />
            </button>
            <button
              type="button"
              className={classes.btnDelete}
              onClick={handlerDelete}
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
      <div className={classes.body} ref={listRef} aria-hidden={!open}>
        <div>
          <div className={classes.link}>
            <LinkIcone />
            <TextInput
              value={value || ""}
              onChange={(text) => setValue(text)}
              name="link"
              placeholder="Ссылка"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldEditItemInfo;
