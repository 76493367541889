import classes from "./HeaderPlanningEmployee.module.scss";
import EmptyImage from "assets/img/empty-image.png";
import Display from "components/ui/icons/Display";
import calendar from "assets/img/icons/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { ru } from "date-fns/locale";
import { addDays } from "date-fns";
import "./Style.scss";
import SelectInput from "forms/components/simpleWidgets/select-input/SelectInput";
import { getSelectDefaultValue, getSelectWeeks } from "helpers/selectWeeks";
import { PlannedInfo } from "components/planning/dto";

type THeaderPlanningEmployeeProps = {
  userInfo?: PlannedInfo;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  checkedWeek: Date[];
};

const HeaderPlanningEmployee = ({
  userInfo,
  startDate,
  setStartDate,
  checkedWeek,
}: THeaderPlanningEmployeeProps) => {
  registerLocale("ru", ru);

  const ExampleCustomInput = (
    <img className={classes.calendar} src={calendar} alt="custom input" />
  );

  const highlightWithRanges = [
    addDays(checkedWeek[0], 0),
    addDays(checkedWeek[1], 0),
    addDays(checkedWeek[2], 0),
    addDays(checkedWeek[3], 0),
    addDays(checkedWeek[4], 0),
    addDays(checkedWeek[5], 0),
    addDays(checkedWeek[6], 0),
  ];

  return (
    <>
      {userInfo ? (
        <div className={classes["header-planning-employee"]}>
          <div className={classes.user}>
            <img
              src={
                userInfo?.employee.photo ? userInfo?.employee.photo : EmptyImage
              }
              alt="avatar"
            />
            <div className={classes["user-info"]}>
              <div className={classes["user-name"]}>
                {`${userInfo?.employee?.first_name}  ${userInfo?.employee?.last_name}`}
              </div>
              <div className={classes["user-position"]}>
                {userInfo?.employee?.position}
              </div>
            </div>
          </div>
          <div className={classes["progress-container"]}>
            <div className={classes.progress}>
              <div
                className={classes["progress--bar"]}
                style={{
                  width: `${
                    userInfo.employment_percentage <= 100
                      ? userInfo.employment_percentage
                      : 100
                  }%`,
                }}
              ></div>
            </div>
            <div
              className={classes["progress--text"]}
            >{`${userInfo.employment_percentage}%`}</div>
          </div>
          <div className={classes.projects}>
            <Display clazz={classes["projects--display"]} />
            <div className={classes["projects--text"]}>
              Проектов на неделю:
              <div className={classes["projects--number"]}>
                {userInfo.project_count}
              </div>
            </div>
          </div>
          <div className={classes.dates}>
            <div className={classes.calendarContainer}>
              <DatePicker
                calendarClassName="calendarContainer"
                selected={startDate}
                onChange={(date) => date instanceof Date && setStartDate(date)}
                customInput={ExampleCustomInput}
                locale="ru"
                dateFormat="dd.MM.yyyy"
                highlightDates={highlightWithRanges}
                showWeekPicker
              />
            </div>
            <SelectInput
              name="weeks"
              options={getSelectWeeks(checkedWeek)}
              onChange={(data) => setStartDate(data.value)}
              value={getSelectDefaultValue(checkedWeek)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HeaderPlanningEmployee;
