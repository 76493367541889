import { ReactNode } from "react";
import classes from "./Paper.module.scss";

interface IPaperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  cls?: {
    root?: string;
    paddingRoot?: string;
  };
}

const Paper = ({ cls, children, ...props }: IPaperProps) => {
  const { root: clsRoot = "", paddingRoot: clsPaddingRoot } = cls || {};
  return (
    <div
      {...props}
      className={`${classes.paper} ${clsRoot} ${
        clsPaddingRoot ? clsPaddingRoot : classes.paperPadding
      }`}
    >
      {children}
    </div>
  );
};

export default Paper;
