import Select, {
  components,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { OptionType } from "./SelectWrapper";
import { ReactNode, useMemo } from "react";
import { selectInputStyles } from "./SelectIntputStyles";
import IconClose from "components/ui/icons/Close";
import classes from "./SelectWrapper.module.scss";

type Props = {
  name: string;
  disabled?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: (data: any) => void;
  inputArgs?: object | undefined;
  placeholder?: string | undefined;
  error?: string;
  required?: boolean;
  multiple?: boolean;
  autoFocus?: boolean;
  form?: string;
  options?: OptionType[];
  id?: string;
  defaultValue?: OptionType | OptionType[];
  value?: OptionType | OptionType[] | null;
  searchable?: boolean;
  optionStyle?: React.CSSProperties;
  isClearable?: boolean;
  theme?: "shadow" | "outline";
  menuPortalTarget?: HTMLElement | null | undefined | "unset";
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  clazz?: string;
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<OptionType>) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

const ValueContainerCustom = ({
  propsContainer,
  startAdornment,
  endAdornment,
  multiple,
}: {
  propsContainer: ValueContainerProps<OptionType>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  multiple?: boolean;
}) => {
  if (startAdornment || endAdornment) {
    return (
      <div className={classes.valueWrap}>
        {startAdornment && (
          <div className={classes.startAdornment}>{startAdornment}</div>
        )}
        <components.ValueContainer {...propsContainer}>
          {propsContainer.children}
        </components.ValueContainer>
        {endAdornment && <div>{endAdornment}</div>}
      </div>
    );
  }

  return <ValueContainer {...propsContainer} />;
};

const SelectOption = (props: OptionProps<OptionType>) => {
  return (
    <div
      className={`${classes.option} ${
        props.isSelected ? classes.selected : ""
      }`}
    >
      <components.Option {...props} />
      <div
        onClick={() => props.selectOption(props.data)}
        className={`${props.isSelected ? classes.show : classes.hide}`}
      >
        <IconClose />
      </div>
    </div>
  );
};

const SelectInput = ({
  name,
  placeholder,
  disabled,
  required,
  autoFocus,
  form,
  multiple,
  onChange,
  options,
  id,
  value,
  defaultValue,
  error,
  searchable = true,
  optionStyle,
  isClearable,
  theme = "shadow",
  menuPortalTarget,
  startAdornment,
  endAdornment,
}: Props) => {
  const styles = useMemo(() => {
    return selectInputStyles({
      error,
      theme,
      optionStyle,
      multiple,
    });
  }, [theme, error, optionStyle]);

  const menuPortalElement =
    menuPortalTarget === "unset"
      ? undefined
      : menuPortalTarget
        ? menuPortalTarget
        : document.body;
  return isClearable ? (
    <CreatableSelect
      menuPortalTarget={menuPortalElement}
      formatCreateLabel={(userInput) => `Создать: ${userInput}`}
      menuPosition={"fixed"}
      styles={styles}
      isSearchable={searchable}
      isDisabled={disabled}
      autoFocus={autoFocus}
      isMulti={multiple}
      options={options}
      isOptionDisabled={(option) =>
        option.isdisabled ? option.isdisabled : false
      }
      id={id}
      placeholder={placeholder}
      name={name}
      required={required}
      defaultValue={defaultValue} //uncontrolled components
      value={value} //controlled components
      form={form}
      onChange={onChange}
      components={{
        Option: SelectOption,
        ValueContainer: (props) => (
          <ValueContainerCustom
            propsContainer={props}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
            multiple={multiple}
          />
        ),
      }}
    />
  ) : (
    <Select
      hideSelectedOptions={false}
      menuPortalTarget={menuPortalElement}
      noOptionsMessage={() => "Ничего не найдено"}
      menuPosition={"fixed"}
      styles={styles}
      isSearchable={searchable}
      isDisabled={disabled}
      autoFocus={autoFocus}
      isMulti={multiple}
      onChange={onChange}
      options={options}
      isOptionDisabled={(option) =>
        option.isdisabled ? option.isdisabled : false
      }
      id={id}
      placeholder={placeholder}
      name={name}
      required={required}
      defaultValue={defaultValue} //uncontrolled components
      value={value} //controlled components
      form={form}
      components={{
        Option: SelectOption,
        ValueContainer: (props) => (
          <ValueContainerCustom
            propsContainer={props}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
            multiple={multiple}
          />
        ),
      }}
    />
  );
};

export default SelectInput;
