interface IconProps {
  clazz?: string;
}

const Birthday = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12V21.2364C20 21.6581 19.6816 22 19.2889 22H4.71111C4.31838 22 4 21.6581 4 21.2364V12H20Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2364 7.72727C21.6581 7.72727 22 8.06916 22 8.49091V11C22 11.5523 21.5523 12 21 12H12H3C2.44772 12 2 11.5523 2 11V8.49091C2 8.06916 2.34189 7.72727 2.76364 7.72727H10.7859C9.54009 7.72727 7.67391 6.74382 6.96501 6.03491C6.04197 5.11187 6.04197 3.61532 6.96501 2.69228C7.88805 1.76924 9.3846 1.76924 10.3076 2.69228C11.0163 3.40097 11.9994 5.26621 12 6.51206C12.0006 5.26621 12.9837 3.40097 13.6924 2.69228C14.6154 1.76924 16.112 1.76924 17.035 2.69228C17.958 3.61532 17.958 5.11187 17.035 6.03491C16.3261 6.74382 14.4599 7.72727 13.2141 7.72727H21.2364Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3038 7.41932C12.0898 7.20535 12.0001 6.88596 11.9999 6.51001C11.9998 6.88596 11.91 7.20535 11.6961 7.41932C11.4819 7.63345 11.1622 7.72318 10.7859 7.72318H13.214C12.8377 7.72318 12.5179 7.63345 12.3038 7.41932Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.2457V22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Birthday;
