interface IconProps {
  clazz?: string;
}

const Delete = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4417 2.25C9.25804 2.25 8.20716 3.00743 7.83284 4.13037L7.45963 5.25H6.4184C4.39219 5.25 3.23559 7.56321 4.45131 9.18418C4.97019 9.87601 5.25068 10.7175 5.25068 11.5823V18C5.25068 20.6234 7.37733 22.75 10.0007 22.75H14.0007C16.624 22.75 18.7507 20.6234 18.7507 18V11.5823C18.7507 10.7175 19.0312 9.87601 19.55 9.18418C20.7658 7.56321 19.6092 5.25 17.583 5.25H16.5408L16.1676 4.13037C15.7933 3.00743 14.7424 2.25 13.5587 2.25H10.4417ZM15.9827 6.75C15.9943 6.75027 16.0059 6.75027 16.0175 6.75H17.583C18.3731 6.75 18.8241 7.65206 18.35 8.28418C17.6364 9.23566 17.2507 10.3929 17.2507 11.5823V18C17.2507 19.7949 15.7956 21.25 14.0007 21.25H10.0007C8.20576 21.25 6.75068 19.7949 6.75068 18V11.5823C6.75068 10.3929 6.36493 9.23566 5.65131 8.28418C5.17723 7.65206 5.62826 6.75 6.4184 6.75H7.98291C7.99454 6.75027 8.00614 6.75027 8.01771 6.75H15.9827ZM14.9596 5.25L14.7445 4.60472C14.5744 4.09429 14.0967 3.75 13.5587 3.75H10.4417C9.90368 3.75 9.42601 4.09429 9.25587 4.60472L9.04077 5.25H14.9596ZM14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11V17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17V11ZM10 10.25C10.4142 10.25 10.75 10.5858 10.75 11L10.75 17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17L9.25 11C9.25 10.5858 9.58579 10.25 10 10.25Z"
      />
    </svg>
  );
};

export default Delete;
