import axios from "axios";
import jwtDecode from "jwt-decode";
import { AuthContext } from "context/AuthContext";
import { TokenType } from "types";
import { useContext, useEffect } from "react";

export const useAuthTokens = () => {
  const { isAuth } = useContext(AuthContext);

  let refreshLongPolling: NodeJS.Timer;

  useEffect(() => {
    if (!isAuth) {
      clearInterval(refreshLongPolling);
    }
  }, [isAuth]);

  const refreshTokens = () => {
    const access = localStorage.getItem("accessToken");
    const refresh = localStorage.getItem("refreshToken");

    if (access && refresh) {
      const decodedAccessToken = jwtDecode<TokenType>(access);

      if (Date.now() >= decodedAccessToken.exp * 1000) {
        fetchTokens();
      } else {
        refreshLongPolling = setInterval(() => {
          fetchTokens();
        }, decodedAccessToken.exp * 1000 - Date.now());
      }
    }
  };

  const fetchTokens = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/account/token/refresh/`,
        {
          refresh: refreshToken,
        },
      );
      localStorage.setItem("accessToken", res.data.access);
      localStorage.setItem("refreshToken", res.data.refresh);
      clearInterval(refreshLongPolling);
      refreshTokens();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error(err);
        if (err.response?.status === 401) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.reload();
        }
      } else {
        console.error(err);
      }
    }
  };

  return {
    // accessToken,
    refreshTokens,
    fetchTokens,
  };
};
