import classes from "./Modal.module.scss";
import Cross from "components/ui/icons/Cross";
import ReactDOM from "react-dom";
import { useScrollbarSize } from "hooks/useScrollbarSize/useScrollbarSize";
import { ReactNode, useEffect } from "react";

interface ModalProps {
  children: React.ReactNode;
  clazz?: string;
  isShown: boolean;
  hide: () => void;
  closeIcon?: ReactNode;
  clsx?: {
    close?: string;
    body?: string;
  };
}

const Modal = ({
  children,
  clazz,
  isShown,
  hide,
  closeIcon,
  clsx,
}: ModalProps) => {
  const { checkScrollbarSize } = useScrollbarSize();

  const clickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.currentTarget === e.target) return hide();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) return hide();
  };

  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${checkScrollbarSize()}px`;
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "";
    }
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown]);

  const modal = (
    <div
      className={`${classes.modal} ${clazz ? clazz : ""}`}
      onClick={clickOutside}
    >
      <div
        className={`${classes.modal__body} ${clsx?.body ? clsx.body : ""}`}
        aria-modal
        tabIndex={-1}
        role="dialog"
      >
        <button
          type="button"
          className={`${classes.cross} ${clsx?.close ? clsx.close : ""}`}
          onClick={hide}
          data-dismiss="modal"
          aria-label="Close"
        >
          {closeIcon || <Cross clazz={classes.cross__icon} />}
        </button>
        {children}
      </div>
    </div>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
