import UserListIcon from "components/ui/icons/UserListIcon";
import classes from "./Dashboard.module.scss";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { useState, useEffect } from "react";
import UserCart from "../UserCart/UserCard";
import { useApi } from "hooks/useApi/useApi";
import { useSearchParams } from "react-router-dom";
import { ManagementEmployeeType } from "types";
import SearchIcon from "components/ui/icons/SearchIcon";

const Dashboard = () => {
  const [dataRows, setDataRows] = useState<ManagementEmployeeType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const { apiGet } = useApi();

  const onChangeSelectedUsers = (value: any) => {
    console.log(value);
    setSelectedUsers(value);
  };

  useEffect(() => {
    const getManagementList = async () => {
      if (searchParams.get("position")) {
        searchParams.set("role", searchParams?.get("position") || "");
        searchParams.delete("position");
      }
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}/api/employee/employees`,
        searchParams,
      );
      console.log(res);
      res.data?.data && setDataRows(res.data.data);
    };
    getManagementList();
  }, []);

  return (
    <div className={classes.dashboardContainer}>
      <aside className={classes.dashboardAside}>
        <div className={classes.header}>
          <SelectWrapper
            name="competences"
            placeholder="Найти сотрудника"
            value={selectedUsers}
            choices_url="api/employee/employees/choices/"
            onChange={onChangeSelectedUsers}
            searchable={true}
            optionStyle={SELECT_OPTIONS}
            isClearable={false}
            theme="outline"
            className={classes["select-wrapper"]}
            multiple={true}
            startAdornment={<SearchIcon clazz={classes.searchIcon} />}
          />
          <UserListIcon />
        </div>
        <div className={classes.asideBody}>
          {dataRows.map((item, index) => (
            <UserCart
              avatar={item.photo}
              name={item.first_name + " " + item.last_name}
              position={item.position_name}
              key={item.slug}
            />
          ))}
        </div>
      </aside>
      <main className={classes.dashboardMain}>
        <table className={classes.tabel}>
          <tr>
            <th>Всего дней</th>
            <th>Всего часов</th>
            <th>Простой</th>
            <th>Не отгружено</th>
            <th>Коммерческие</th>
            <th>Некомерческие</th>
            <th>Дата</th>
            <th>Кем изменено</th>
            <th>Стоимость</th>
            <th>Дата</th>
            <th>Кем изменено</th>
            <th>Стоимость</th>
            <th>Дата</th>
            <th>Кем изменено</th>
            <th>Стоимость</th>
          </tr>
          {dataRows.map((item, index) => (
            <tr key={index}>
              <td>{index}</td>
              <td>{index * 1000}</td>
              <td>{index / 3}</td>
              <td>{index}</td>
              <td>{index * 1000}</td>
              <td>{index / 3}</td>
              <td>{index}</td>
              <td>{index * 1000}</td>
              <td>{index / 3}</td>
              <td>{index}</td>
              <td>{index * 1000}</td>
              <td>{index / 3}</td>
              <td>{index}</td>
              <td>{index * 1000}</td>
              <td>{index / 3}</td>
            </tr>
          ))}
        </table>
      </main>
    </div>
  );
};

export default Dashboard;
