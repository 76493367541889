export const formatTime = (inputValue: string, time?: string) => {
  let timeArray;
  if (time) {
    timeArray = time.split(":");
  } else {
    timeArray = inputValue.split(":");
  }

  let hours, minutes, formattedTime;

  if (timeArray.length < 2) {
    formattedTime = timeArray[0];
  } else {
    if (timeArray[0][0] === "0") {
      hours = timeArray[0][1];
    } else {
      hours = timeArray[0];
    }

    if (timeArray[1] !== "00") {
      minutes = timeArray[1];
    }

    if (minutes) {
      hours !== "0"
        ? (formattedTime = `${hours}ч ${minutes}мин`)
        : (formattedTime = `${minutes}мин`);
    } else {
      formattedTime = `${hours}ч`;
    }
  }

  return formattedTime;
};
