interface IconProps {
  clazz?: string;
}

const Success = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 19.1213C10.3166 19.5118 9.68341 19.5118 9.29289 19.1213L3.62623 13.4546C3.2357 13.0641 3.2357 12.4309 3.62623 12.0404C4.01675 11.6499 4.64992 11.6499 5.04044 12.0404L9.99999 16.9999L20.6262 6.37371C21.0168 5.98319 21.6499 5.98319 22.0404 6.37371C22.431 6.76424 22.431 7.3974 22.0404 7.78793L10.7071 19.1213Z"
      />
    </svg>
  );
};

export default Success;
