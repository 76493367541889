interface IconProps {
  clazz?: string;
}

const Check = ({ clazz }: IconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clazz}
    >
      <ellipse cx="15.0007" cy="15" rx="14.1667" ry="14.1667" fill="#00C2FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0337 19.3947C14.3312 20.0972 13.2199 20.1249 12.5514 19.4565L9.12825 16.0333C8.45983 15.3649 8.48747 14.2535 9.18998 13.551C9.89249 12.8485 11.0038 12.8208 11.6723 13.4893L13.8852 15.7022L19.8087 9.77861C20.5112 9.0761 21.6226 9.04847 22.291 9.71688C22.9594 10.3853 22.9318 11.4967 22.2293 12.1992L15.0337 19.3947Z"
        fill="white"
      />
    </svg>
  );
};

export default Check;
