import { useEffect, useState } from "react";
import classes from "./TableHeader.module.scss";
import { ApiDataType, PlanningWeekDayType } from "types";
import { useSearchParams } from "react-router-dom";
import { addWeeks, format, previousMonday, subDays } from "date-fns";
import { ru } from "date-fns/locale/ru";
import { splitDate } from "helpers/dates";
import Arrow from "components/ui/icons/Arrow";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as swiper } from "swiper";
import { Controller, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { createParams } from "helpers/createParams";
import { monday, sunday } from "helpers/currentWeek";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";

const TableHeader = ({ thumb }: { thumb?: swiper[] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<string[]>();
  const [tableDays, setTableDays] = useState<ApiDataType<
    PlanningWeekDayType[],
    string[]
  > | null>(null);
  const unit = searchParams.get("time_unit") || "day";

  const { apiGet } = useApi();

  const fetchTableDays = async (monday: string, sunday: string) => {
    const res = await apiGet(endpoints.planningDayList, {
      date_start: monday,
      date_end: sunday,
    });
    setTableDays(res);
  };

  useEffect(() => {
    const start = searchParams.get("date_start") || monday;
    const end = searchParams.get("date_end") || sunday;
    fetchTableDays(start, end);
  }, [searchParams]);

  const handlePrev = () => {
    let start = new Date(searchParams.get("date_start") || monday),
      end = new Date(searchParams.get("date_end") || sunday);
    start = previousMonday(start);
    end = addWeeks(start, 1);
    end = subDays(end, 1);

    setSearchParams(
      createParams(searchParams, {
        date_start: splitDate(start.toLocaleDateString()),
        date_end: splitDate(end.toLocaleDateString()),
        time_unit: "day",
      }),
    );
  };

  const handleNext = () => {
    let start = new Date(searchParams.get("date_start") || monday),
      end = new Date(searchParams.get("date_end") || sunday);
    start = addWeeks(start, 1);
    end = addWeeks(start, 1);
    end = subDays(end, 1);

    setSearchParams(
      createParams(searchParams, {
        date_start: splitDate(start.toLocaleDateString()),
        date_end: splitDate(end.toLocaleDateString()),
        time_unit: "day",
      }),
    );
  };

  const toUpperFirstLetter = (str: string) =>
    str.replace(str[0], str[0].toUpperCase());

  useEffect(() => {
    let variable: PlanningWeekDayType,
      res: string[] = [];
    if (unit === "week" && tableDays?.data) {
      res = tableDays.data.reduce<string[]>((acc, item, index) => {
        if (index % 7 === 0) variable = item;
        if (index % 7 === 6) return [...acc, variable.date + " - " + item.date];
        return acc;
      }, []);
    }
    if (unit === "month" && tableDays?.data) {
      res = tableDays.data.reduce<string[]>((acc, item) => {
        const date = new Date(splitDate(item.date));
        let month = format(date, "LLLL", { locale: ru });
        month = toUpperFirstLetter(month);
        if (!acc.some((i) => i === month)) return [...acc, month];
        return acc;
      }, []);
    }
    setDate(res);
  }, [tableDays]);

  return (
    <div className={classes.container} data-unit={unit}>
      {unit === "day" && tableDays?.data && (
        <>
          <button type="button" className="prev-skill" onClick={handlePrev}>
            <Arrow clazz={classes.prev} />
          </button>
          {tableDays.data.map((day) => (
            <div
              key={day.date}
              className={day.is_workday ? classes.weekday : classes.dayoff}
            >
              <div className={classes.date}>{day.day_month}</div>
              <div className={classes.day}>{day.day_of_week}</div>
            </div>
          ))}
          <button type="button" className="next-skill" onClick={handleNext}>
            <Arrow clazz={classes.next} />
          </button>
        </>
      )}
      {unit === "week" &&
        date &&
        date.map((item, index) => (
          <div className={classes.weekday} key={index}>
            <div className={classes.date}>{item}</div>
          </div>
        ))}
      {unit === "month" && (
        <div className={classes.monthContainer}>
          <button type="button" className="prev-skill">
            <Arrow clazz={classes.prev} />
          </button>
          <Swiper
            className={classes.slider}
            watchOverflow={true}
            modules={[Navigation, Controller]}
            controller={{ control: thumb, by: "slide" }}
            navigation={{
              prevEl: ".prev-skill",
              nextEl: ".next-skill",
            }}
            slidesPerView={"auto"}
            spaceBetween={20}
          >
            {date &&
              date.map((item, index) => (
                <SwiperSlide
                  className={classes.weekday}
                  key={index}
                  data-unit={unit}
                >
                  <div className={classes.date}>{item}</div>
                </SwiperSlide>
              ))}
          </Swiper>

          <button type="button" className="next-skill">
            <Arrow clazz={classes.next} />
          </button>
        </div>
      )}
    </div>
  );
};

export default TableHeader;
