interface IconProps {
  clazz?: string;
}

const Clock = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75Z"
        stroke="#00C2FA"
        strokeWidth="1.5"
      />
      <path
        d="M9.97111 5.85303V9.99996L7.99707 11.7689"
        stroke="#00C2FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Clock;
