import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";
import TimeInput from "forms/components/simpleWidgets/time-input/TimeInput";
import { dateToRFC } from "helpers/dates";

type TProps = {
  indexCell: number;
  cell: string | number;
  setInputValue: (value: string) => void;
  taskId: number;
  setDateUpdate: (value: { indexCell: number; taskId: number } | null) => void;
};

export const getInputCellLoggingTable = (props: TProps) => {
  const { indexCell, cell, setInputValue, taskId, setDateUpdate } = props;

  let childrenInputComponent;

  switch (indexCell) {
  case 1:
    childrenInputComponent = (
      <TextInput
        autoFocus={true}
        name="textInputLogging"
        value={cell}
        onChange={setInputValue}
      />
    );
    break;
  case 2:
    childrenInputComponent = (
      <SelectWrapper
        name="selectLogging"
        onChange={(value) =>
          typeof value === "string" && setInputValue(value)
        }
        value={cell}
        placeholder={typeof cell === "string" ? cell : undefined}
        choices={undefined}
        choices_url={"api/project/projects/"}
        autoFocus={true}
      />
    );
    break;
  case 3:
    childrenInputComponent = (
      <DateInput
        name="dateLogging"
        onFocus={() => setDateUpdate({ indexCell, taskId })}
        onBlur={() => setDateUpdate(null)}
        onChange={(value) => {
          setInputValue(dateToRFC(value));
        }}
        value={null}
        placeholder={typeof cell === "string" ? cell : undefined}
        autoFocus={true}
      />
    );
    break;
  case 4:
    childrenInputComponent = (
      <SelectWrapper
        name="selectLogging"
        onChange={(value) => {
          if (typeof value === "string") setInputValue(value);
        }}
        value={cell}
        placeholder={typeof cell === "string" ? cell : undefined}
        choices_url={"api/project/action_type"}
        autoFocus={true}
      />
    );
    break;
  case 5:
    childrenInputComponent = (
      <TimeInput
        name="timeInputLogging"
        value=""
        onChange={setInputValue}
        autoFocus={true}
      />
    );
    break;
  case 6:
    childrenInputComponent = (
      <SelectWrapper
        name="taskStatus"
        onChange={(value) => {
          if (typeof value === "string") setInputValue(value);
        }}
        value={cell}
        placeholder={typeof cell === "string" ? cell : undefined}
        choices_url={
          "api/project/project_time_tracking/create_form/choices/task_status/"
        }
        autoFocus={true}
      />
    );
  }

  return childrenInputComponent;
};
