import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import classes from "./ProjectsList.module.scss";
import ProjectsItem from "../projects-item/ProjectsItem";
import NotFound from "components/common/not-found/NotFound";
import ProjectForm from "../projects-form/ProjectForm";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { ApiDataType, ProjectsData, ProjectsProps } from "types";
import { UserRoleContext } from "context/RoleContext";
import { useContext, useEffect, useState } from "react";

interface ProjectsListProps {
  projects: ApiDataType<ProjectsData, string[]> | null;
  isSingleColumn: boolean;
  fetchProducts: (searchQuery: string | null, ordering: string | null) => void;
}

const ProjectsList = ({
  projects,
  isSingleColumn,
  fetchProducts,
}: ProjectsListProps) => {
  const [permitForCreate, setPermitForCreate] = useState(false);

  const { userRoleContext } = useContext(UserRoleContext);

  const style =
    (projects?.data?.data?.length === 0 && !permitForCreate) ||
    (projects?.errorMessage.length && !permitForCreate)
      ? { gridTemplateColumns: "1fr" }
      : undefined;

  useEffect(() => {
    if (userRoleContext.data?.can_create_project) setPermitForCreate(true);
  }, [userRoleContext]);

  return projects ? (
    <div
      className={`${classes.container} ${
        isSingleColumn ? classes["container--single"] : ""
      }`}
      style={style}
    >
      {permitForCreate && (
        <ProjectForm
          isSingleColumn={isSingleColumn}
          updateProducts={fetchProducts}
        />
      )}
      {projects.errorMessage.length ? (
        projects.errorMessage.map((i, idx) => {
          return <ErrorComponent key={idx}>{i}</ErrorComponent>;
        })
      ) : projects.data?.data?.length ? (
        projects.data.data.map((i: ProjectsProps, idx: number) => {
          return (
            <ProjectsItem
              isSingleColumn={isSingleColumn}
              project={i}
              key={idx}
            />
          );
        })
      ) : (
        <NotFound title={"Ничего не найдено"} />
      )}
    </div>
  ) : (
    <Skeleton
      count={4}
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        minHeight: "181px",
      }}
      containerClassName={classes["skeleton-container"]}
    />
  );
};

export default ProjectsList;
