import classes from "./NotFound.module.scss";

interface NotFoundProps {
  title: string;
}

const NotFound = ({ title }: NotFoundProps) => {
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default NotFound;
