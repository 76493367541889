import classes from "./AuthNewPasswordPage.module.scss";
import Logo from "assets/img/logo.svg";
import AuthNewPassword from "components/auth/auth-new-password/AuthNewPassword";

const AuthNewPasswordPage = () => {
  return (
    <section className={classes.container}>
      <div className={classes["img-container"]}>
        <img
          src={Logo}
          alt="terabit logo"
          className={classes["img-container__logo"]}
        />
      </div>
      <AuthNewPassword />
    </section>
  );
};

export default AuthNewPasswordPage;
