interface IconProps {
  clazz?: string;
}

const Eye = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.75C8.4879 6.75 5.43153 8.69919 3.8586 11.5753C3.7138 11.8401 3.7138 12.1598 3.8586 12.4246C5.43151 15.3008 8.48791 17.25 12 17.25C15.5121 17.25 18.5685 15.3008 20.1414 12.4247C20.2862 12.1599 20.2862 11.8402 20.1414 11.5754C18.5685 8.69925 15.5121 6.75 12 6.75ZM2.54256 10.8556C4.36847 7.51686 7.91882 5.25 12 5.25C16.0812 5.25 19.6316 7.51694 21.4575 10.8557C21.8475 11.569 21.8475 12.4312 21.4574 13.1444C19.6315 16.4831 16.0812 18.75 12 18.75C7.91882 18.75 4.36843 16.4831 2.54254 13.1443C2.15248 12.431 2.15249 11.5688 2.54256 10.8556Z"
        fill="#B6B6B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};

export default Eye;
