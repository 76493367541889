import { OpenedBlocksContext } from "context/OpenedBlocks";
import classes from "./Mentor.module.scss";
import { ApiDataType } from "types";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import EmptyImage from "assets/img/empty-image.png";
import NoMentor from "./no-mentor/NoMentor";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UserInfoContext } from "context/UserInfoContext";
import { useContext, useEffect, useState } from "react";

const Mentor = () => {
  const { isBlockOpen } = useContext(OpenedBlocksContext);
  const { userInfoContext } = useContext(UserInfoContext);

  const [mentorInfo, setMentorInfo] = useState<ApiDataType | null>(null);

  const { apiGet } = useApi();

  useEffect(() => {
    const getMentorInfo = async () => {
      if (userInfoContext?.data.id) {
        const res = await apiGet(
          `${endpoints.user}${userInfoContext.data.id}/mentor/`,
        );

        setMentorInfo(res);
      }
    };

    getMentorInfo();
  }, [isBlockOpen, userInfoContext]);

  const speciality = [
    [["Роль:"], [mentorInfo?.data.role]],
    [["Должность:"], [mentorInfo?.data.position]],
    [["Основная специальность:"], [mentorInfo?.data.position]],
  ];

  let containerClassName,
    avatarClassName,
    mentorClassName,
    nameClassName,
    specialityClassName,
    noMentorSize;

  if (isBlockOpen.isProfileOpen) {
    containerClassName = classes["container--medSize"];
    avatarClassName = classes["avatar--medSize"];
    mentorClassName = classes["mentor--medSize"];
    nameClassName = classes["name--medSize"];
    specialityClassName = classes["speciality--medSize"];
    noMentorSize = "medium";
  } else {
    containerClassName = classes.container;
    avatarClassName = classes.avatar;
    mentorClassName = classes.mentor;
    nameClassName = classes.name;
    specialityClassName = classes.speciality;
    noMentorSize = "full";
  }

  return mentorInfo?.data ? (
    <div className={containerClassName}>
      {Object.entries(mentorInfo.data).length === 0 ? (
        <NoMentor size={noMentorSize} />
      ) : (
        <>
          <img
            src={mentorInfo.data.photo ? mentorInfo.data.photo : EmptyImage}
            alt="mentor avatar"
            className={avatarClassName}
          />
          <span className={mentorClassName}>Ментор</span>
          <span className={nameClassName}>
            {`${mentorInfo.data.first_name} ${mentorInfo.data.last_name}`}
          </span>
          <div className={specialityClassName}>
            {speciality.map((item, idx) => {
              return (
                <div key={idx} className={classes.speciality__item}>
                  <span className={classes.itemTitle}>{item[0]}</span>
                  <span className={classes.itemValue}>{item[1]}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  ) : (
    <Skeleton
      containerClassName={classes["skeleton-container"]}
      style={{
        display: "block",
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default Mentor;
