import { createContext } from "react";

interface AuthContextType {
  isAuth: boolean;
  setIsAuth?: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue = {
  isAuth: false,
};

export const AuthContext = createContext<AuthContextType>(defaultValue);
