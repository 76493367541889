import { TField, FormResponse } from "../dto";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "hooks/useApi/useApi";
import { FormDataType, TInputValue } from "types";

export type UseApiForm = {
  fields: TField[];
  isLoading: boolean;
  isSending: boolean;
  disabled: boolean;
  disable: () => void;
  enable: () => void;
  updateData: (field: TField, value: TInputValue) => void;
  data: FormDataType;
  reset: () => void;
};

const useApiForm = (url: string, testData?: () => FormResponse): UseApiForm => {
  const [fields, setFields] = useState<TField[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState<FormDataType>({});
  const { apiGet } = useApi();

  const updateData = useCallback((field: TField, value: TInputValue) => {
    setData((old) => {
      return { ...old, [field.name]: value };
    });
  }, []);

  const fetchForm = useCallback(async () => {
    setIsLoading(true);
    if (testData) {
      setFields(testData().fields);
    } else {
      try {
        const res = await apiGet(url);
        setFields(res.data);
      } catch (err) {
        console.error(err);
      }
    }
    setIsLoading(false);
  }, [testData]);

  useEffect(() => {
    fetchForm().then();
  }, []);

  return {
    fields,
    data,
    isLoading,
    isSending,
    disabled,
    disable: () => setDisabled(true),
    enable: () => setDisabled(false),
    updateData,
    reset: async () => {
      setData({});
      await fetchForm();
    },
  };
};

export default useApiForm;
