import { endpoints } from "API/endpoints";
import Button from "components/ui/button/Button";
import ArrowGrey from "components/ui/icons/ArrowGrey";
import { UserRoleContext } from "context/RoleContext";
import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";
import TimeInput from "forms/components/simpleWidgets/time-input/TimeInput";
import { dateToRFC } from "helpers/dates";
import { useApi } from "hooks/useApi/useApi";
import classes from "./TableMobile.module.scss";
import Delete from "components/ui/icons/Delete";
import Cross from "components/ui/icons/Cross";
import { useCallback, useContext, useEffect, useState } from "react";
import { PatchDataType, useApiTableType } from "types";

type TTableMobile = {
  rows: (string | number)[][];
  table: useApiTableType;
  setUpdateTable?: (value: boolean) => void;
};

const TableMobile = ({ rows, table, setUpdateTable }: TTableMobile) => {
  const [isBlockOpen, setIsBlockOpen] = useState<null | number>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [projectFields, setProjectFields] = useState<
    [{ value: number; name: string }] | null
  >(null);
  const [inputsValues, setInputsValues] = useState<
    PatchDataType & { id: number | null }
  >({
    id: null,
    task: null,
    project: null,
    date: null,
    action_type: null,
    spent_time_min: null,
  });

  const { userRoleContext } = useContext(UserRoleContext);

  const updateData = useCallback(async () => {
    const patchValues: PatchDataType & { id: number } = { id: 0 };

    for (const [key, value] of Object.entries(inputsValues)) {
      if (value !== null) {
        patchValues[key] = value;
      }
    }

    await table.updateData(patchValues.id, patchValues);

    setIsBlockOpen(null);
    setInputsValues({
      id: null,
      task: null,
      project: null,
      date: null,
      action_type: null,
      spent_time_min: null,
    });
    setUpdateTable?.(true);
  }, [inputsValues]);

  const { apiPost, apiGet } = useApi();

  const [formInputs, setFormInputs] = useState<PatchDataType>({
    task: null,
    project: null,
    date: null,
    action_type: null,
    spent_time_min: null,
  });

  const onSubmit = async () => {
    const loggingData = Object.assign({}, formInputs, {
      date: formInputs.date ? formInputs.date : dateToRFC(new Date()),
    });

    const res = await apiPost(
      endpoints.addUserLogging,
      loggingData,
      undefined,
      true,
    );

    if (res.data) {
      setUpdateTable?.(true);
      setIsFormOpen(!isFormOpen);
    }
  };

  const deleteData = useCallback(async (taskId: number) => {
    const res = await table.deleteData(taskId);
    if (res) {
      setUpdateTable?.(true);
    }
  }, []);

  const toTimerValue = (value: string) => {
    const time = value.split(" ");
    let hour: string | number = "00";
    let minute: string | number = "00";
    if (time[0].includes("ч")) {
      hour = parseInt(time[0]);
      hour = hour > 9 ? hour : "0" + hour;
      if (time[1]) {
        minute = parseInt(time[1]);
        minute = minute > 9 ? minute : "0" + minute;
      }
    } else {
      minute = parseInt(time[0]);
      minute = minute > 9 ? minute : "0" + minute;
    }
    return [hour, minute].join(":");
  };

  useEffect(() => {
    const getProjectFields = async () => {
      const res = await apiGet(endpoints.loggingAddTask + "choices/project");
      if (res.data) {
        setProjectFields(res.data);
      }
    };

    getProjectFields();
  }, []);

  return (
    <div className={classes.container}>
      {rows?.map((row, indexRow) => {
        return (
          <div className={classes.taskContainer} key={indexRow}>
            <div className={classes.task}>
              <div onClick={() => deleteData(Number(row[0]))}>
                <Delete clazz={classes.deleteIcon} />
              </div>
              <div className={classes.task__info}>
                {isBlockOpen === row[0] ? (
                  <div className={classes.inputContainer}>
                    <SelectWrapper
                      name="selectLogging"
                      onChange={(value) => {
                        setInputsValues({
                          ...inputsValues,
                          id: typeof row[0] === "number" ? row[0] : null,
                          project: typeof value === "number" ? value : null,
                        });
                      }}
                      value={row[2]}
                      placeholder={
                        typeof row[2] === "string" ? row[2] : undefined
                      }
                      choices={undefined}
                      choices_url={"api/project/projects/"}
                      className={classes.select}
                    />
                  </div>
                ) : (
                  <p className={classes.task__info__project}>{row[2]}</p>
                )}
                {isBlockOpen === row[0] &&
                userRoleContext.data.can_choose_date_project_time_tracking ? (
                    <div className={classes.dateContainer}>
                      <DateInput
                        name="dateLogging"
                        onChange={(date) =>
                          setInputsValues({
                            ...inputsValues,
                            id: typeof row[0] === "number" ? row[0] : null,
                            date: date instanceof Date ? dateToRFC(date) : null,
                          })
                        }
                        value={null}
                        placeholder={
                          typeof row[3] === "string" ? row[3] : undefined
                        }
                        fieldClass={classes.date}
                      />
                    </div>
                  ) : (
                    <p className={classes.task__info__date}>{row[3]}</p>
                  )}
              </div>
              <button
                type="button"
                className={
                  isBlockOpen === row[0]
                    ? classes["task__btn--open"]
                    : classes.task__btn
                }
                onClick={() =>
                  isBlockOpen === row[0]
                    ? setIsBlockOpen(null)
                    : setIsBlockOpen(typeof row[0] === "number" ? row[0] : null)
                }
              >
                <ArrowGrey clazz={classes.task__btn__icon} />
              </button>
            </div>
            {isBlockOpen === row[0] && (
              <>
                <div className={classes.editTask}>
                  <SelectWrapper
                    name="selectLogging"
                    onChange={(value) => {
                      setInputsValues({
                        ...inputsValues,
                        id: typeof row[0] === "number" ? row[0] : null,
                        action_type: typeof value === "string" ? value : null,
                      });
                    }}
                    value={row[4]}
                    placeholder={
                      typeof row[4] === "string" ? row[4] : undefined
                    }
                    choices_url={"api/project/action_type"}
                    label={"Тип работы"}
                    className={classes.select}
                  />
                  <TimeInput
                    name="timeInputLogging"
                    value={toTimerValue(
                      typeof row[5] === "string" ? row[5] : "",
                    )}
                    onChange={(value) =>
                      setInputsValues({
                        ...inputsValues,
                        id: typeof row[0] === "number" ? row[0] : null,
                        spent_time_min:
                          typeof value === "string" ? value : null,
                      })
                    }
                    label={"Время"}
                  />
                  <TextInput
                    name="textInputLogging"
                    value={row[1]}
                    onChange={(value) =>
                      setInputsValues({
                        ...inputsValues,
                        id: typeof row[0] === "number" ? row[0] : null,
                        task: typeof value === "string" ? value : null,
                      })
                    }
                    label={"Задача"}
                  />
                  <SelectWrapper
                    name="taskStatus"
                    onChange={(value) => {
                      setInputsValues({
                        ...inputsValues,
                        id: typeof row[0] === "number" ? row[0] : null,
                        project: projectFields?.find((el) => el.name === row[2])
                          ?.value,
                        task_status:
                          typeof value === ("number" || "string")
                            ? value
                            : null,
                      });
                    }}
                    choices_url={
                      "api/project/project_time_tracking/create_form/choices/task_status/"
                    }
                    value={null}
                    placeholder={
                      typeof row[6] === "string" ? row[6] : row[6] + ""
                    }
                    label={"Цель задачи"}
                    className={classes.select}
                  />
                </div>
                <button
                  className={classes.okBtn}
                  onClick={() => {
                    updateData();
                  }}
                />
              </>
            )}
          </div>
        );
      })}
      {isFormOpen && (
        <form className={classes.form}>
          <div
            className={classes.crossContainer}
            onClick={() => setIsFormOpen(false)}
          >
            <Cross clazz={classes.crossContainer__icon} />
          </div>
          <DateInput
            name="dateLogging"
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                date: value instanceof Date ? dateToRFC(value) : null,
              });
            }}
            value={null}
            placeholder={""}
            disabled={
              userRoleContext.data.can_choose_date_project_time_tracking
                ? false
                : true
            }
          />
          <SelectWrapper
            name="selectLogging"
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                project: typeof value === "number" ? value : null,
              });
            }}
            placeholder={"Выбрать проект"}
            choices={undefined}
            choices_url={"api/project/projects/"}
          />
          <SelectWrapper
            name="selectLogging"
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                action_type: typeof value === "string" ? value : null,
              });
            }}
            placeholder={"Тип работы"}
            choices_url={"api/project/action_type"}
          />
          <TimeInput
            name="timeInputLogging"
            value=""
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                spent_time_min: typeof value === "string" ? value : null,
              });
            }}
          />
          <TextInput
            name="textInputLogging"
            value={""}
            placeholder={"Название задачи"}
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                task: typeof value === "string" ? value : null,
              });
            }}
          />
          <SelectWrapper
            name="taskStatus"
            onChange={(value) => {
              setFormInputs({
                ...formInputs,
                task_status:
                  typeof value === ("number" || "string") ? value : null,
              });
            }}
            placeholder={"Цель задачи"}
            choices_url={
              "api/project/project_time_tracking/create_form/choices/task_status/"
            }
            autoFocus={true}
          />
          <Button clazz={classes.submitBtn} onClick={() => onSubmit()}>
            Сохранить
          </Button>
        </form>
      )}
      {!isFormOpen && (
        <Button
          clazz={classes.addBtn}
          type="submit"
          onClick={() => setIsFormOpen(true)}
        >
          Добавить
        </Button>
      )}
    </div>
  );
};

export default TableMobile;
