import { OpenedBlocksContext } from "context/OpenedBlocks";
import classes from "./DayOff.module.scss";
import Cross from "components/ui/icons/Cross";
import Button from "components/ui/button/Button";
import Form from "forms/components/form";
import { endpoints } from "API/endpoints";
import Check from "components/ui/icons/Check";
import CrossFill from "components/ui/icons/CrossFill";
import { useApi } from "hooks/useApi/useApi";
import { UserInfoContext } from "context/UserInfoContext";
import { splitDate } from "helpers/dates";
import Popup from "components/common/popup/Popup";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { FormDataType } from "types";
import { useContext, useState } from "react";

const DayOff = () => {
  const [isTimeInputsOpen, setIsTimeInputsOpen] = useState(false);
  const [error, setError] = useState<string[] | null>(null);

  const { isBlockOpen, setIsBlockOpen } = useContext(OpenedBlocksContext);
  const { userInfoContext } = useContext(UserInfoContext);

  let classNameForm, classNameHoursBtns;

  if (isTimeInputsOpen) {
    classNameForm = classes.formTimeInputsOpen;
    classNameHoursBtns = classes.hoursBtns;
  } else {
    classNameForm = classes.form;
    classNameHoursBtns = classes.hoursBtnsHidden;
  }

  const minimalSizeBlock =
    isBlockOpen.isProfileOpen || isBlockOpen.isVacationOpen;

  const changeDayOffState = () => {
    setIsBlockOpen?.({
      ...isBlockOpen,
      isDayOffOpen: !isBlockOpen.isDayOffOpen,
      isProfileOpen: false,
      isVacationOpen: false,
    });
  };

  const { apiPost } = useApi();

  const onSubmit = async (form: FormDataType) => {
    const userId = userInfoContext?.data.id;

    const dayOffData = Object.assign({}, { employee: userId }, form, {
      date: splitDate((form.date as Date).toLocaleDateString()),
    });

    const res = await apiPost(endpoints.dayoff, dayOffData);
    if (res.errorMessage.length) {
      setError(res.errorMessage);
    } else {
      changeDayOffState();
    }
  };

  return (
    <div
      className={
        minimalSizeBlock ? classes["container--minSize"] : classes.container
      }
    >
      {!isBlockOpen.isDayOffOpen ? (
        <Button clazz={classes.btn} onClick={() => changeDayOffState()}>
          Запланировать отгул
        </Button>
      ) : (
        <div
          className={
            isBlockOpen.isDayOffOpen
              ? [classes.calendar, classes["calendar--open"]].join(" ")
              : classes.calendar
          }
        >
          <div className={classes.header}>
            <button
              type="button"
              className={classes.cross}
              onClick={() => changeDayOffState()}
            >
              <Cross clazz={classes.cross__icon} />
            </button>
            <span className={classes.header__title}>Отгул</span>
          </div>
          <button
            onClick={() => {
              setIsTimeInputsOpen(!isTimeInputsOpen);
            }}
            className={classes.btnChooseHours}
          >
            Указать время
          </button>
          <div className={classNameHoursBtns}>
            <div
              onClick={() => {
                setIsTimeInputsOpen(false);
              }}
            >
              <Check clazz={classes.icon} />
            </div>
            <div
              onClick={() => {
                setIsTimeInputsOpen(false);
              }}
            >
              <CrossFill clazz={classes.icon} />
            </div>
          </div>
          <Form
            apiUrl={endpoints.dayoffForm}
            btnText="Отправить"
            classValueForm={classNameForm}
            classValueBtn={classes.formBtn}
            onSubmit={onSubmit}
          />
          {error && (
            <Popup clazz={classes.popup} clickCallback={setError}>
              <ErrorComponent clazz={classes.error}>
                {error.map((i) => {
                  return i;
                })}
              </ErrorComponent>
            </Popup>
          )}
        </div>
      )}
    </div>
  );
};

export default DayOff;
