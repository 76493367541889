interface IconProps {
  clazz?: string;
}

const RightArrow = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66537 6.66659L10.8606 4.47132C11.121 4.21097 11.121 3.78886 10.8606 3.52851L8.66537 1.33325M10.6654 3.99992L1.33203 3.99992"
        stroke="#181818"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightArrow;
