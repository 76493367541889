interface IconProps {
  clazz?: string;
}

const SuccessIcon = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#F2F2F2" />
      <path d="M4 7.5L7 10.5L12 5.5" stroke="#999999" strokeWidth="1.5" />
      <path
        d="M4 7.5L7 10.5L12 5.5"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SuccessIcon;
