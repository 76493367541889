import { splitDate } from "helpers/dates";
import { previousMonday, nextSunday, isMonday, isSunday } from "date-fns";

const currentDate = new Date();

export const monday = splitDate(
  isMonday(currentDate)
    ? currentDate.toLocaleDateString()
    : previousMonday(currentDate).toLocaleDateString(),
);
export const sunday = splitDate(
  isSunday(currentDate)
    ? currentDate.toLocaleDateString()
    : nextSunday(currentDate).toLocaleDateString(),
);
