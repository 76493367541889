import { createContext } from "react";
import { ApiDataType, userType } from "types";

interface UserInfoContextType {
  userInfoContext: ApiDataType<userType, string[]> | null;
  setUserInfoContext?: React.Dispatch<
    React.SetStateAction<UserInfoContextType["userInfoContext"]>
  >;
}

const defaultValue = {
  userInfoContext: null,
};

export const UserInfoContext = createContext<UserInfoContextType>(defaultValue);
