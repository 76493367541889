import { TBookings } from "components/planning/dto";
import classes from "./UserEmploymentSupervisor.module.scss";

interface Props {
  data: TBookings[];
}

const UserEmploymentSupervisor = ({ data }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.label}>{data[0].date}</div>
      <div className={classes.items}>
        {data.map((item, index) => (
          <div className={classes.item} key={index}>
            {item.total_hours}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserEmploymentSupervisor;
