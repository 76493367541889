import classes from "./Projects.module.scss";
import ProjectsHeader from "./projects-header/ProjectsHeader";
import ProjectsList from "./projects-list/ProjectsList";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { ApiDataType } from "types";
import { useEffect, useState } from "react";

const Projects = () => {
  const [isSingleColumn, setIsSingleColumn] = useState<boolean>(false);
  const { apiGet } = useApi();
  const [projects, setProjects] = useState<ApiDataType | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handlerPreview = () => {
    setIsSingleColumn(!isSingleColumn);
  };

  const getProjects = async (params?: object | string) => {
    const res = await apiGet(endpoints.projects, params);
    setProjects(res);
    if (res.data.data.length <= 1) setIsSingleColumn(true);
  };

  const handleRouterParams = (
    searchQuery?: string | null,
    ordering?: string[] | null,
  ) => {
    const params = Object.assign(
      {},
      searchQuery && { search: searchQuery },
      ordering && ordering.length && { ordering: ordering.join(",") },
    );
    if (params) {
      navigate({
        pathname: "/projects",
        search: createSearchParams(params).toString(),
      });
    }
  };

  useEffect(() => {
    const search = searchParams.get("search");
    const ordering = searchParams.get("ordering");
    fetchProducts(search, ordering);
  }, [searchParams]);

  const fetchProducts = (
    searchQuery: string | null,
    ordering: string | null,
  ) => {
    const params = Object.assign(
      {},
      searchQuery && { search: searchQuery },
      ordering && ordering.length && { ordering: ordering },
    );
    getProjects(params);
  };

  return (
    <div className={classes.container}>
      <ProjectsHeader
        handleRouterParams={handleRouterParams}
        isSingleColumn={isSingleColumn}
        handlerPreview={handlerPreview}
      />
      <ProjectsList
        projects={projects}
        isSingleColumn={isSingleColumn}
        fetchProducts={fetchProducts}
      />
    </div>
  );
};

export default Projects;
