import classes from "./SelectWrapper.module.scss";
import { Choice } from "forms/dto";
import { useApi } from "hooks/useApi/useApi";
import { ApiDataType } from "types";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import SelectInput from "./SelectInput";
import { ReactNode, useEffect, useRef, useState } from "react";
import { TSelctInputTheme } from "./SelectIntputStyles";

type Props = {
  name: string;
  label?: string;
  helpText?: string;
  value?: string | null | number | string[] | Date;
  defaultValue?: OptionType;
  disabled?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: (value: string | number | Date | string[], name?: string) => void;
  inputArgs?: object | undefined;
  choices?: Choice[];
  choices_url?: string;
  placeholder?: string | undefined;
  error?: string;
  required?: boolean;
  onClick?: (value: unknown) => void;
  autoFocus?: boolean;
  resDataAfterSubmit?: ApiDataType | null;
  form?: string;
  searchable?: boolean;
  multiple?: boolean;
  optionStyle?: React.CSSProperties;
  reset?: boolean;
  resetCallback?: (value: boolean) => void;
  isClearable?: boolean;
  widgetArgs?: Record<string, boolean>;
  className?: string;
  theme?: TSelctInputTheme;
  menuPortalTarget?: HTMLElement | null | undefined | "unset";
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

export interface OptionType {
  isdisabled?: boolean;
  name?: string;
  label: string;
  value: string | number | Date;
}

const SelectWrapper = ({
  label,
  name,
  placeholder,
  value,
  defaultValue,
  inputArgs,
  helpText,
  readOnly,
  choices,
  choices_url,
  multiple,
  disabled,
  onChange,
  required,
  autoFocus,
  resDataAfterSubmit,
  form,
  searchable,
  optionStyle,
  reset,
  resetCallback,
  isClearable,
  widgetArgs,
  className,
  theme = "shadow",
  menuPortalTarget,
  startAdornment,
  endAdornment,
}: Props) => {
  const elId = `id_${name}`;

  const [selectedOption, setSelectedOption] = useState<
    OptionType | OptionType[] | null
  >(null);
  const [error, setError] = useState<string[] | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);

  const defaultControlledValue = useRef<OptionType | OptionType[] | null>();
  let defaultUncontrolledValue;

  const { apiGet } = useApi();

  const getChoices = async (choicesUrl: string, choices?: OptionType[]) => {
    const res = await apiGet(`${process.env.REACT_APP_API_URL}${choicesUrl}`);
    const newOptions = choicesUrl.includes("project_status_list")
      ? []
      : [...options, ...(choices || [])];

    if (res.data) {
      const data = Array.isArray(res.data)
        ? res.data
        : Array.isArray(res.data.data)
          ? res.data.data
          : [];
      data.forEach((item: OptionType & { id: number; title: string }) => {
        newOptions.push({
          name: item.name || item.title || "",
          label: item.name || item.title || "",
          value: multiple
            ? (item.value || item.id).toString()
            : item.value || item.id,
        });
      });

      setOptions(newOptions);
      setError(null);
    } else if (res.errorMessage && res.errorMessage.length !== 0) {
      setError(res.errorMessage);
    }
  };

  useEffect(() => {
    if (choices && choices_url) {
      const newOptions = [...options];

      choices.forEach((item) => {
        newOptions.push({
          name: item.name,
          label: item.name || "",
          value: item.value || item.id || "",
          isdisabled: item.isdisabled,
        });
      });
      getChoices(choices_url, newOptions);
    } else if (choices) {
      const newOptions = [...options];

      choices.forEach((item) => {
        if (item.employee) {
          newOptions.push({
            name: item.employee.first_name + " " + item.employee.last_name,
            label: item.employee.first_name + " " + item.employee.last_name,
            value: item.employee.id,
            isdisabled: item.isdisabled,
          });
        } else {
          newOptions.push({
            name: item.name,
            label: item.name || "",
            value: item.value || item.id || "",
            isdisabled: item.isdisabled,
          });
        }
      });
      setOptions(newOptions);
    } else if (choices_url) {
      getChoices(choices_url);
    }
  }, [choices_url]);

  useEffect(() => {
    if (selectedOption && onChange) {
      if (Array.isArray(selectedOption)) {
        const values = selectedOption.map((i) => i.value + "");
        onChange(values);
      } else {
        onChange(selectedOption?.["value"], selectedOption?.["name"]);

        if (error && selectedOption) {
          if (selectedOption?.["value"] !== value) setError(null);
        }
      }
    }
  }, [selectedOption]);

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setError(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  if (options.length > 0 && value) {
    let defaultObject: OptionType | OptionType[] | undefined = undefined;
    if (Array.isArray(value)) {
      defaultObject = value.reduce((acc: OptionType[], i: string) => {
        const option = options.find((item) => item.value === i);
        if (option) {
          return [...acc, option];
        }
        return acc;
      }, []);
    } else {
      defaultObject = options.find((i: OptionType) => i.value === value);
    }

    // bekas form api send only value we mast manually define what we need - defaultValue || value
    if (form) {
      if (defaultObject) defaultUncontrolledValue = defaultObject;
    } else {
      if (defaultObject) defaultControlledValue.current = defaultObject;
    }
  }

  const labelAndValue = form === "projectEditForm";

  useEffect(() => {
    if (reset) {
      setSelectedOption(null);
      defaultControlledValue.current = null;
      resetCallback?.(false);
    }
  }, [reset]);

  return (
    <section
      className={
        name === "workType"
          ? classes["container--workType"]
          : [classes.container, className, classes["container-" + theme]].join(
            " ",
          )
      }
      data-error={error ? true : undefined}
    >
      {labelAndValue ? (
        <label>
          <span>{label}</span>
          <span>
            {Array.isArray(defaultUncontrolledValue)
              ? defaultUncontrolledValue.map((i) => i.name).join(", ")
              : defaultUncontrolledValue?.name}
          </span>
        </label>
      ) : (
        label && <label htmlFor={elId}>{label}</label>
      )}
      {options && (
        <SelectInput
          key={
            form && value
              ? Array.isArray(defaultUncontrolledValue)
                ? defaultUncontrolledValue.map((i) => i.label).join(", ")
                : defaultUncontrolledValue?.label
              : undefined
          } // for update component when we have defaultUncontrolledValue from form, because we don't have current option object and we create it dynamically
          disabled={disabled}
          autoFocus={autoFocus}
          onChange={(data) => setSelectedOption(data)}
          options={options}
          id={elId}
          multiple={multiple}
          placeholder={placeholder}
          name={name}
          required={required}
          value={defaultControlledValue.current} //controlled components
          defaultValue={
            defaultUncontrolledValue ? defaultUncontrolledValue : defaultValue
          } //uncontrolled components
          form={form}
          error={error ? error[0] : undefined}
          searchable={searchable || widgetArgs?.searchable}
          isClearable={isClearable || widgetArgs?.isClearable}
          optionStyle={optionStyle}
          theme={theme}
          menuPortalTarget={menuPortalTarget}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
        />
      )}
      {error?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default SelectWrapper;
