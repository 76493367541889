import classes from "./MaskInput.module.scss";
import { WidgetComponent } from "forms/components/widget";
import { InputMask } from "@react-input/mask";
import Phone from "components/ui/icons/Phone";

const MaskInput = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  required,
  resDataAfterSubmit,
  form,
  theme = "shadow",
  mask,
  className,
}: WidgetComponent) => {
  const elId = `id_${name}`;

  return (
    <section
      className={`${classes.container} ${
        classes["container-" + theme]
      } ${className}`}
    >
      {label ? (
        <label htmlFor={elId}>
          {placeholder === "Новый телефон" ? (
            <Phone clazz={classes.icon} />
          ) : (
            <></>
          )}
          {label}
        </label>
      ) : null}
      <InputMask
        mask={mask?.mask}
        replacement={mask?.replacement}
        form={form}
        className={`${classes.input} ${classes["input-" + theme] || ""}`}
        placeholder={placeholder}
        name={name}
        id={elId}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        {...(inputArgs || {})}
      />
    </section>
  );
};

export default MaskInput;
