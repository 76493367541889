interface IconProps {
  clazz?: string;
}

const Bug = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2C17 2.55228 16.5523 3 16 3C15.8512 3 15.71 2.96749 15.583 2.90919L13.481 4.22297C15.5194 4.8542 17 6.75419 17 9C17 9.24254 16.9827 9.48105 16.9494 9.71436C18.8073 11.1793 20 13.4504 20 16C20 19.413 17.8628 22.3268 14.8536 23.4761L14.8237 23.5735C14.7811 23.5605 14.739 23.5473 14.6971 23.5339C13.8545 23.8357 12.9464 24 12 24C11.0535 24 10.1454 23.8356 9.30268 23.5339C9.261 23.5472 9.21898 23.5604 9.17662 23.5734L9.14666 23.4762C6.13738 22.327 4 19.4131 4 16C4 13.4504 5.19272 11.1793 7.05065 9.71436C7.01727 9.48105 7 9.24254 7 9C7 6.75419 8.48065 4.8542 10.519 4.22297L8.41697 2.90919C8.29005 2.96749 8.14882 3 8 3C7.44772 3 7 2.55228 7 2C7 1.44772 7.44772 1 8 1C8.54434 1 8.9871 1.43493 8.99972 1.97624L11.2315 3.3711C11.7017 3.66497 12.2983 3.66497 12.7685 3.3711L15.0003 1.97624C15.0129 1.43493 15.4557 1 16 1C16.5523 1 17 1.44772 17 2ZM10.9253 22.8003C11.2755 22.8552 11.6344 22.8837 12 22.8837C12.3645 22.8837 12.7223 22.8554 13.0715 22.8008C12.6631 22.5284 12.3235 22.1952 12.0601 21.7687C12.0385 21.7336 12.0174 21.698 11.997 21.6619C11.9767 21.6978 11.9558 21.7332 11.9343 21.7681C11.6716 22.1946 11.3328 22.5279 10.9253 22.8003ZM11.396 18.8497C11.3695 19.9672 11.2076 20.6598 10.9125 21.1388C10.6095 21.6307 10.1131 21.9795 9.21558 22.2973C6.80091 21.2281 5.11628 18.8108 5.11628 16C5.11628 12.4004 7.87918 9.44607 11.4 9.14207V17.9882C11.3998 17.9974 11.3995 18.0067 11.3993 18.0159L11.393 18.016C11.3942 18.0622 11.3953 18.1078 11.3962 18.1528C11.3916 18.3944 11.3913 18.6266 11.396 18.8497ZM12.6 18.5706C12.6001 18.5352 12.6001 18.4996 12.6 18.4637V9.14207C16.1208 9.44607 18.8837 12.4004 18.8837 16C18.8837 18.811 17.1989 21.2284 14.7839 22.2975C13.8833 21.9795 13.3851 21.6304 13.0812 21.1382C12.796 20.6764 12.6349 20.016 12.6 18.9672V18.5706ZM16 9C16 9.02335 15.9998 9.04666 15.9994 9.06991C14.8228 8.38945 13.4569 8 12 8C10.5431 8 9.17716 8.38945 8.0006 9.06991C8.0002 9.04666 8 9.02335 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4166 23.8335C22.1496 23.8335 21.9332 23.6171 21.9332 23.3502V21.031L20.2151 19.8856C19.993 19.7375 19.9329 19.4374 20.081 19.2153C20.2291 18.9932 20.5292 18.9332 20.7513 19.0812L22.5412 20.2745C22.7653 20.4239 22.8999 20.6754 22.8999 20.9448V23.3502C22.8999 23.6171 22.6835 23.8335 22.4166 23.8335Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.48334 23.8335C1.75029 23.8335 1.96669 23.6171 1.96669 23.3502V21.031L3.68483 19.8856C3.90694 19.7375 3.96696 19.4374 3.81889 19.2153C3.67082 18.9932 3.37072 18.9332 3.14861 19.0812L1.35872 20.2745C1.13461 20.4239 1 20.6754 1 20.9448V23.3502C1 23.6171 1.2164 23.8335 1.48334 23.8335Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49999 7C1.77613 7 1.99998 7.22386 1.99998 7.49999L1.99998 9.89904L3.7773 11.0839C4.00706 11.2371 4.06915 11.5475 3.91597 11.7773C3.7628 12.0071 3.45237 12.0691 3.22261 11.916L1.37108 10.6816C1.13925 10.5271 1 10.2669 1 9.98824L1 7.49999C1 7.22386 1.22385 7 1.49999 7Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 7C22.2239 7 22 7.22386 22 7.49999L22 9.89904L20.2227 11.0839C19.9929 11.2371 19.9309 11.5475 20.084 11.7773C20.2372 12.0071 20.5476 12.0691 20.7774 11.916L22.6289 10.6816C22.8608 10.5271 23 10.2669 23 9.98824L23 7.49999C23 7.22386 22.7761 7 22.5 7Z"
        fill="#181818"
      />
    </svg>
  );
};

export default Bug;
