import { WidgetComponent } from "forms/components/widget";
import classes from "./NumberInput.module.scss";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useState } from "react";

const NumberInput = ({
  label,
  name,
  placeholder,
  value,
  readOnly,
  disabled,
  onChange,
  required,
  autoFocus,
  resDataAfterSubmit,
  form,
  widgetArgs,
  theme = "shadow",
}: WidgetComponent) => {
  const elId = `id_${name}`;

  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorAfterSubmit(null);
    onChange(e.target.value);
  };
  const labelAndValue = form === "projectEditForm";

  return (
    <section
      className={[
        classes.container,
        classes["container-" + theme],
        classes[name],
      ].join(" ")}
      data-error={errorAfterSubmit ? true : undefined}
    >
      {labelAndValue ? (
        <label>
          <span>{label}</span>
          <span>{`${value} ч.`}</span>
        </label>
      ) : (
        label && <label htmlFor={elId}>{label}</label>
      )}
      <input
        form={form}
        autoFocus={autoFocus}
        className={`${classes.input} ${classes["input-" + theme] || ""}`}
        placeholder={placeholder}
        name={name}
        id={elId}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        defaultValue={!(value instanceof Date) && value ? value : ""}
        type="number"
        {...(widgetArgs || {})}
      />
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default NumberInput;
