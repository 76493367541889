import Form from "forms/components/form";
import classes from "./AuthRecoveryPassword.module.scss";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthApi } from "API/authApi/AuthAPI";
import { AuthFormContext } from "context/AuthFormContext";
import { endpoints } from "API/endpoints";
import { AuthContext } from "context/AuthContext";
import { ApiDataType, FormDataType } from "types";
import { useContext, useState } from "react";

const AuthRecoveryPassword = () => {
  const navigate = useNavigate();
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const { isAuth } = useContext(AuthContext);

  const { setUser } = useContext(AuthFormContext);

  const onSubmit = async (formData: FormDataType) => {
    setUser?.(typeof formData.user === "string" ? formData.user : "");

    const res = await AuthApi.getConfirmCode(formData);

    if ("status" in res && res.status && res.status === 200) {
      navigate("/auth/confirm-code");
    } else {
      setResDataAfterSubmit(res?.data || res);
    }
  };

  if (isAuth) {
    return <Navigate to="/" replace={true} />;
  }

  const skeleton = {
    count: 2,
    style: {
      height: "36px",
    },
  };

  return (
    <div className={classes.container}>
      <Form
        formId="recoveryForm"
        apiUrl={endpoints.email}
        classValueForm={classes.form}
        classValueBtn={classes.formBtn}
        btnText="Отправить код"
        onSubmit={onSubmit}
        skeleton={skeleton}
        resDataAfterSubmit={resDataAfterSubmit}
      >
        <span onClick={() => navigate("/auth")} className={classes.link}>
          Вернуться к авторизации
        </span>
      </Form>
    </div>
  );
};

export default AuthRecoveryPassword;
