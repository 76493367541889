import Auth from "components/auth/Auth";
import Logo from "assets/img/logo.svg";
import classes from "./AuthPage.module.scss";
import { useEffect } from "react";

const AuthPage = () => {
  useEffect(() => {
    document.title = "ERP - Auth";
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes["img-container"]}>
        <img
          src={Logo}
          alt="terabit logo"
          className={classes["img-container__logo"]}
        />
      </div>
      <Auth />
    </section>
  );
};

export default AuthPage;
