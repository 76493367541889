interface IconProps {
  clazz?: string;
}

const ArrowUp = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(180 )"
    >
      <path
        d="M4.66732 9.33342L6.58817 11.2543C6.81598 11.4821 7.18532 11.4821 7.41313 11.2543L9.33398 9.33342M7.00065 11.0834L7.00065 2.91675"
        stroke="#3D3C3C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowUp;
