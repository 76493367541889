import classes from "./Main.module.scss";
import TableHeader from "components/planning/planning-manager/table-header/TableHeader";
import TableItem from "../table-item/TableItem";
import PageInfo from "../page-info/PageInfo";
import ProjectInfo from "../project-info/ProjectInfo";
import { useSearchParams } from "react-router-dom";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType } from "types";
import { monday, sunday } from "helpers/currentWeek";
import { useContext, useEffect, useState } from "react";
import { TPageInfo, TProjectInfo } from "components/planning/dto";
import Skeleton from "react-loading-skeleton";
import CalendarDatePicker from "../calendar-date-picker/CalendarDatePicker";
import Swiper from "swiper";
import { PlannedInfoContext } from "context/PlanningPageContext";
import { createParams } from "helpers/createParams";

interface Props {
  pageInfo: TPageInfo | null;
}

const Main = ({ pageInfo }: Props) => {
  const [thumb, setThumb] = useState<Swiper[]>([]);
  const [projectInfo, setProjectInfo] = useState<
    TProjectInfo | null | undefined
  >(null);
  const [isUnassigned, setIsUnassigned] = useState(false);

  const { plannedInfo, setPlannedInfo } = useContext(PlannedInfoContext);

  const [searchParams] = useSearchParams();
  const { apiGet } = useApi();

  const fetchData = async () => {
    let projectInfoRes: ApiDataType<TProjectInfo, string[]> | null = null;

    const params = createParams(searchParams, {
      date_start: searchParams.get("date_start") || monday,
      date_end: searchParams.get("date_end") || sunday,
    });

    if (searchParams.get("project"))
      projectInfoRes = await apiGet(endpoints.planningProject, params);

    const plannedInfoRes = await apiGet(endpoints.planningEmployeeList, params);

    setPlannedInfo && setPlannedInfo(plannedInfoRes.data);
    setProjectInfo(projectInfoRes?.data || null);
  };

  useEffect(() => {
    setPlannedInfo && setPlannedInfo(null);
    if (searchParams.get("project")) {
      setProjectInfo(undefined);
    } else {
      setProjectInfo(null);
    }

    fetchData();
  }, [searchParams]);

  const skeleton = {
    count: 4,
    style: {
      height: "100px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const projectSkeleton = {
    count: 1,
    style: {
      height: "70px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const tableMaxHeight = projectInfo
    ? { maxHeight: "calc(100vh - 442px)" }
    : { maxHeight: "calc(100vh - 342px)" };

  return (
    <div className={classes.container}>
      {projectInfo ? (
        <ProjectInfo data={projectInfo} />
      ) : (
        projectInfo === undefined && (
          <Skeleton
            containerClassName={projectSkeleton.containerClassName}
            count={projectSkeleton.count}
            style={projectSkeleton.style}
          />
        )
      )}
      <div className={classes.header}>
        <CalendarDatePicker />
        <TableHeader thumb={thumb} />
      </div>
      <div className={classes.table} style={tableMaxHeight}>
        {plannedInfo ? (
          plannedInfo.map((i, idx) => {
            return (
              <TableItem
                key={idx}
                data={i}
                setThumb={setThumb}
                isUnassigned={isUnassigned}
              />
            );
          })
        ) : (
          <Skeleton
            containerClassName={skeleton.containerClassName}
            count={skeleton.count}
            style={skeleton.style}
          />
        )}
      </div>
      <PageInfo
        setIsUnassigned={setIsUnassigned}
        plannedHours={pageInfo?.total_hours || 0}
        unassignedHours={pageInfo?.free_hours || 0}
        weeklyProjects={pageInfo?.total_projects || 0}
      />
    </div>
  );
};

export default Main;
