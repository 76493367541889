import React, { Dispatch, SetStateAction } from "react";
import classes from "./Popup.module.scss";

interface PopupType {
  clazz?: string;
  children: React.ReactNode | React.ReactNode[];
  clickCallback?: Dispatch<SetStateAction<string[] | null>>;
  isOpen?: boolean;
}

const Popup = ({
  clazz,
  children,
  clickCallback,
  isOpen = true,
}: PopupType) => {
  const onClickContainer = () => {
    if (clickCallback) clickCallback(null);
  };

  return isOpen ? (
    <div
      className={
        clazz ? [classes.container, clazz].join(" ") : classes.container
      }
      onClick={onClickContainer}
    >
      {children}
    </div>
  ) : null;
};
export default Popup;
