import useApiTable from "hooks/useApiTable/useApiTable";
import classes from "./SkillsTable.module.scss";

type Props = {
  apiUrl: string;
  params?: URLSearchParams;
};

const SkillsTable = ({ apiUrl, params }: Props) => {
  const table = useApiTable(apiUrl, params);
  const rows: Array<string | number>[] = [];

  table.data.forEach((item) => {
    const oneRow: Array<string | number> = [];

    for (const key in item) {
      oneRow.push(item[key]);
    }
    rows.push(oneRow);
  });

  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.header}>
          {table.columns.map((item) => {
            return (
              <th key={item.name} className={classes.header__item}>
                <p>{item.label}</p>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={classes.rows}>
        {rows.map((item, indexRow) => {
          return (
            <tr className={classes.oneRow} key={item[0]}>
              {item.map((cell: string | number, indexCell: number) => {
                return (
                  <td className={classes.cell} key={indexRow + indexCell}>
                    {typeof cell === "string" &&
                    cell.length &&
                    cell.includes("https://") ? (
                        <a
                          href={cell}
                          target="_blank"
                          className={classes.cellContainer}
                          rel="noreferrer"
                        >
                        Ссылка
                        </a>
                      ) : (
                        <div className={classes.cellContainer}>{cell}</div>
                      )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SkillsTable;
