import PageInfo from "../page-info/PageInfo";
import classes from "./Supervisor.module.scss";
import { useEffect, useState } from "react";
import { PlannedInfo, TPageInfo } from "components/planning/dto";
import { useSearchParams } from "react-router-dom";
import { useApi } from "hooks/useApi/useApi";
import { createParams } from "helpers/createParams";
import { monday, sunday } from "helpers/currentWeek";
import { endpoints } from "API/endpoints";
import UserEmploymentSupervisor from "../user-employment-supervisor/UserEmploymentSupervisor";
import User from "../user/User";
import Skeleton from "react-loading-skeleton";

interface Props {
  pageInfo: TPageInfo | null;
}

const Supervisor = ({ pageInfo }: Props) => {
  const [data, setData] = useState<PlannedInfo[] | null>(null);
  const [searchParams] = useSearchParams();
  const [isUnassigned, setIsUnassigned] = useState(false);

  const { apiGet } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const plannedInfoParams = createParams(searchParams, {
        date_start: searchParams.get("date_start") || monday,
        date_end: searchParams.get("date_end") || sunday,
      });

      const plannedInfoRes = await apiGet(
        endpoints.planningEmployeeList,
        plannedInfoParams,
      );

      setData(plannedInfoRes.data);
    };
    fetchData();
  }, [searchParams]);

  const skeleton = {
    count: 5,
    style: {
      height: "100px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeleton,
  };

  const preparedBookings = (data: PlannedInfo[] | null) => {
    if (!data?.[0]?.bookings) return [];
    return data[0].bookings.map((_, colIndex) =>
      data.map((row) => row.bookings[colIndex]),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.table}>
        {data ? (
          <>
            <div className={classes.tableUser}>
              {data.map((item) => (
                <User
                  key={item.employee.id}
                  data={item.employee}
                  employmentPercentage={item.employment_percentage}
                  projectCount={item.project_count}
                  isUnassigned={isUnassigned}
                />
              ))}
            </div>
            <div className={classes.tableColumns}>
              {preparedBookings(data).map((item, idx) => (
                <UserEmploymentSupervisor key={idx} data={item} />
              ))}
            </div>
          </>
        ) : (
          <Skeleton
            containerClassName={skeleton.containerClassName}
            count={skeleton.count}
            style={skeleton.style}
          />
        )}
      </div>
      <PageInfo
        plannedHours={pageInfo?.total_hours || 0}
        unassignedHours={pageInfo?.free_hours || 0}
        weeklyProjects={pageInfo?.total_projects || 0}
        setIsUnassigned={setIsUnassigned}
      />
    </div>
  );
};

export default Supervisor;
