import classes from "./ManagementSkills.module.scss";
import { useEffect, useState } from "react";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import Search from "components/common/search/Search";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import { createParams } from "helpers/createParams";
import Button from "components/ui/button/Button";
import Plus from "components/ui/icons/Plus";
import ManagementList from "./ManagementList/ManagementList";
import Paper from "components/ui/paper/Paper";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import CloseModal from "components/ui/icons/CloseModal";
import Modal from "components/common/modal/Modal";
import { ManegementForm, QualificationForm } from "../ManegmentForms";
import { useApi } from "hooks/useApi/useApi";

const ManagementSkills = () => {
  const [resetSelect, setResetSelect] = useState(false);
  const [employData, setEmployData] = useState<any[]>([]);

  useEffect(() => {
    console.log(employData);
  },[employData]);



  const [employSkills, setEmploySkills] = useState([]);
  const { apiGet } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOpenQualification, setModalOpenQualification] =
    useState<boolean>(false);

  const onHideModal = () => setModalOpen(false);
  const onHideModalQualification = () => setModalOpenQualification(false);

  // const isToggleGraduate = () => setToggleGraduate(!toggleGraduate);
  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams(
        createParams(searchParams, { search: e.target.value.trim() }),
      );
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };
  const search = useDebounce(_search, 500);

  useEffect(() => {
    setResetSelect(true);
  }, [searchParams]);

  useEffect(() => {
    const getEmployeesList = async () => {
     
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}api/employee/competence_category/`);

      res.data?.data && setEmployData(res.data.data);
    };
    getEmployeesList();
  }, []);
  
  useEffect(() => {
    const getEmployeesList = async () => {
     
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}api/employee/competence/`);

      res.data?.data && setEmploySkills(res.data.data);
    };
    getEmployeesList();
  }, []);



  const onChangeDepartments = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangePositions = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { position: value.toString() }),
      );
    }
  };

  const onChangeOffices = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(createParams(searchParams, { office: value.toString() }));
    }
  };

  const onChangeCompetences = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, {
          competence: value.toString(),
        }),
      );
    }
  };

  useEffect(() => {
    if (resetSelect) setResetSelect(false);
  });

  return (
    <div className={classes.container}>
      <Paper cls={{ paddingRoot: classes.paperPadding }}>
        <div className={classes.header}>
          <div className={classes.selects}>
            <Search
              onInput={search}
              placeholder="Поиск"
              clazz={classes.search}
              initialValue={searchParams.get("search") || ""}
              autoFocus={searchParams.get("search") ? true : false}
              shadow={false}
              clxs={{ clxsContainer: classes.searchWrap }}
            />
            <SelectWrapper
              reset={resetSelect}
              name="positions"
              placeholder="Должность"
              value={searchParams.get("position")}
              choices={[
                { name: "Все должности", label: "all", value: "all" },
                {
                  name: "Топ менеджер",
                  label: "top_manager",
                  value: "top_manager",
                },
                {
                  name: "Операционный менеджер",
                  label: "op_manager",
                  value: "op_manager",
                },
                {
                  name: "Проектный менеджер",
                  label: "project_manager",
                  value: "project_manager",
                },
                { name: "Разработчик", label: "developer", value: "developer" },
              ]}
              onChange={onChangePositions}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              isClearable={true}
              className={classes["select-wrapper"]}
            />
            <SelectWrapper
              reset={resetSelect}
              name="competences"
              placeholder="Сотрудники"
              value={searchParams.get("competence")}
              choices={[{ name: "Все сотрудники", label: "all", value: "all" }]}
              choices_url="api/employee/employees/choices/"
              onChange={onChangeCompetences}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              isClearable={true}
              theme="outline"
              className={classes["select-wrapper"]}
            />
            <SelectWrapper
              reset={resetSelect}
              name="departments"
              placeholder="Подразделение"
              value={searchParams.get("department")}
              choices={[
                { name: "Все подразделения", label: "all", value: "all" },
                { name: "Белгород", label: "bel", value: "2" },
              ]}
              onChange={onChangeDepartments}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              className={classes["select-wrapper"]}
            />

            <SelectWrapper
              reset={resetSelect}
              name="offices"
              placeholder="Офис"
              value={searchParams.get("office")}
              choices={[{ name: "Все офисы", label: "all", value: "all" }]}
              onChange={onChangeOffices}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              className={classes["select-wrapper"]}
            />
          </div>
          <div className={classes.buttons}>
            <Button
              type="button"
              clazz={classes.button}
              theme="outline-shadow"
              onClick={() => setModalOpenQualification(true)}
            >
              Настройка квалификации
            </Button>
            <Button
              type="button"
              clazz={classes.button}
              theme="outline-shadow"
              onClick={() => setModalOpen(true)}
            >
              <>
                <Plus clazz={classes.icon} />
                Добавить сотрудника
              </>
            </Button>
          </div>
        </div>
      </Paper>
      <ManagementList />
      <Modal
        hide={onHideModal}
        isShown={modalOpen}
        closeIcon={<CloseModal />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <ManegementForm/>
      </Modal>
      <Modal
        hide={onHideModalQualification}
        isShown={modalOpenQualification}
        closeIcon={<CloseModal />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <QualificationForm  employData={employData} setEmployData={setEmployData} employSkills={employSkills}/>
      </Modal>
    </div>
  );
};

export default ManagementSkills;
