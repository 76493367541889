import { useEffect } from "react";
import classes from "./ProjectCasePage.module.scss";
import ProjectCase from "components/project-case/ProjectCase";

const ProjectCasePage = () => {
  useEffect(() => {
    document.title = "ERP - Project";
  }, []);

  return (
    <section className={classes.mainContainer}>
      <ProjectCase />
    </section>
  );
};

export default ProjectCasePage;
