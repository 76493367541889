interface IconProps {
  clazz?: string;
}

const DeleteIcon = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78788 0C5.86311 0 5.0421 0.591167 4.74966 1.46761L4.45812 2.34138H3.64459C2.06159 2.34138 1.15798 4.14681 2.10778 5.41196C2.51316 5.95193 2.7323 6.60868 2.7323 7.28365V12.2926C2.7323 14.3401 4.39376 15.9999 6.44329 15.9999H9.56833C11.6179 15.9999 13.2793 14.3401 13.2793 12.2926V7.28365C13.2793 6.60868 13.4985 5.95193 13.9038 5.41196C14.8536 4.14681 13.95 2.34138 12.367 2.34138H11.5528L11.2613 1.46761C10.9688 0.591167 10.1478 0 9.22304 0H6.78788ZM11.1136 3.51211C11.1248 3.51243 11.136 3.51244 11.1473 3.51211H12.367C12.9843 3.51211 13.3367 4.21616 12.9663 4.70952C12.4088 5.45214 12.1074 6.35537 12.1074 7.28365V12.2926C12.1074 13.6935 10.9706 14.8292 9.56833 14.8292H6.44329C5.04098 14.8292 3.90419 13.6935 3.90419 12.2926V7.28365C3.90419 6.35537 3.60281 5.45214 3.0453 4.70952C2.67491 4.21616 3.02728 3.51211 3.64459 3.51211H4.86366C4.87492 3.51244 4.88615 3.51243 4.89734 3.51211H11.1136ZM10.3175 2.34138L10.1495 1.83783C10.0166 1.43944 9.64339 1.17073 9.22304 1.17073H6.78788C6.36753 1.17073 5.99434 1.43944 5.86142 1.83783L5.6934 2.34138H10.3175ZM10.1538 6.82923C10.1538 6.50594 9.89149 6.24386 9.56788 6.24386C9.24427 6.24386 8.98194 6.50594 8.98194 6.82923V11.5122C8.98194 11.8354 9.24427 12.0975 9.56788 12.0975C9.89149 12.0975 10.1538 11.8354 10.1538 11.5122V6.82923ZM6.44274 6.24386C6.76635 6.24386 7.02869 6.50594 7.02869 6.82923V11.5122C7.02869 11.8354 6.76635 12.0975 6.44274 12.0975C6.11913 12.0975 5.8568 11.8354 5.8568 11.5122V6.82923C5.8568 6.50594 6.11913 6.24386 6.44274 6.24386Z"
        fill="#000"
      />
    </svg>
  );
};
export default DeleteIcon;
