import classes from "./Logging.module.scss";
import PlusFill from "components/ui/icons/PlusFill";
import CrossFill from "components/ui/icons/CrossFill";
import { endpoints } from "API/endpoints";
import { UserInfoContext } from "context/UserInfoContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoggingTable from "components/tables/LoggingTable/LoggingTable";
import { useContext, useState } from "react";
import TableDesktop from "components/tables/LoggingTable/TableDesktop/TableDesktop";

const Logging = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState<boolean>(false);

  const { userInfoContext } = useContext(UserInfoContext);

  let classNameAddTask, iconComponent;

  if (isFormOpen) {
    classNameAddTask = classes["addTask--open"];
    iconComponent = <CrossFill clazz={classes.cross} />;
  } else {
    classNameAddTask = classes.addTask;
    iconComponent = <PlusFill clazz={classes.plus} />;
  }

  const userId = userInfoContext?.data.id;

  const skeletonTable = {
    count: 10,
    style: {
      height: "40px",
      marginBottom: "16px",
      display: "block",
      width: "100%",
    },
    containerClassName: classes.skeletonTable,
  };

  return (
    <div className={classes.container}>
      {userId ? (
        <LoggingTable
          apiUrl={`${endpoints.userLoggingList}${userId}`}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
          pagination={true}
          editable={!isFormOpen}
          skeleton={skeletonTable}
          onlyOnceSkeleton={true}
        >
          {
            <div className={classes.formContainer}>
              <div className={classNameAddTask}>
                <div onClick={() => setIsFormOpen(!isFormOpen)}>
                  {iconComponent}
                </div>
              </div>
            </div>
          }
          {isFormOpen && (
            <TableDesktop
              setUpdateTable={setUpdateTable}
              setIsFormOpen={setIsFormOpen}
            />
          )}
        </LoggingTable>
      ) : (
        <Skeleton
          containerClassName={skeletonTable.containerClassName}
          count={skeletonTable.count}
          style={skeletonTable.style}
        />
      )}
    </div>
  );
};

export default Logging;
