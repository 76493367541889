interface IconProps {
  clazz?: string;
}

const Telegram = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09992 11.0269C9.39488 9.05119 12.2589 7.74867 13.6919 7.11936C17.7834 5.32254 18.6335 5.01042 19.1877 5.00011C19.3095 4.99784 19.5821 5.02973 19.7586 5.18096C19.9076 5.30866 19.9486 5.48116 19.9683 5.60223C19.9879 5.7233 20.0123 5.9991 19.9929 6.2146C19.7712 8.6743 18.8118 14.6433 18.3237 17.3983C18.1172 18.564 17.7105 18.9548 17.3169 18.9931C16.4613 19.0762 15.8116 18.3961 14.9829 17.8225C13.6862 16.9251 12.9537 16.3664 11.695 15.4907C10.2404 14.4786 11.1834 13.9223 12.0124 13.0132C12.2293 12.7753 15.999 9.15509 16.0719 8.82668C16.0811 8.7856 16.0895 8.6325 16.0034 8.55166C15.9172 8.47081 15.7901 8.49846 15.6983 8.52045C15.5683 8.55161 13.4968 9.99723 9.48389 12.8573C8.89591 13.2836 8.36333 13.4913 7.88616 13.4804C7.36012 13.4684 6.34822 13.1664 5.59598 12.9082C4.67333 12.5915 3.94002 12.4241 4.00388 11.8863C4.03714 11.6062 4.40248 11.3197 5.09992 11.0269Z"
      />
    </svg>
  );
};

export default Telegram;
