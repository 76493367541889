interface IconProps {
  clazz?: string;
}

const SearchIcon = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="16"
      height="116"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.3335C12 9.91082 9.9107 12.0002 7.33337 12.0002C4.75605 12.0002 2.66671 9.91082 2.66671 7.3335C2.66671 4.75617 4.75605 2.66683 7.33337 2.66683C9.9107 2.66683 12 4.75617 12 7.3335ZM11.0785 12.0214C10.0521 12.8425 8.75005 13.3335 7.33337 13.3335C4.01967 13.3335 1.33337 10.6472 1.33337 7.3335C1.33337 4.01979 4.01967 1.3335 7.33337 1.3335C10.6471 1.3335 13.3334 4.01979 13.3334 7.3335C13.3334 8.75017 12.8424 10.0522 12.0213 11.0786L14.4715 13.5288C14.7318 13.7891 14.7318 14.2112 14.4715 14.4716C14.2111 14.7319 13.789 14.7319 13.5287 14.4716L11.0785 12.0214Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
