interface IconProps {
  clazz?: string;
}

const Reports = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5625 8.75C1.5625 4.78045 4.78045 1.5625 8.75 1.5625H21.25C25.2195 1.5625 28.4375 4.78045 28.4375 8.75V21.25C28.4375 25.2195 25.2195 28.4375 21.25 28.4375H8.75C4.78045 28.4375 1.5625 25.2195 1.5625 21.25V8.75ZM8.75 3.4375C5.81599 3.4375 3.4375 5.81599 3.4375 8.75V21.25C3.4375 24.184 5.81599 26.5625 8.75 26.5625H21.25C24.184 26.5625 26.5625 24.184 26.5625 21.25V8.75C26.5625 5.81599 24.184 3.4375 21.25 3.4375H8.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 22.1875C9.48223 22.1875 9.0625 21.7678 9.0625 21.25L9.0625 17.5C9.0625 16.9822 9.48223 16.5625 10 16.5625C10.5178 16.5625 10.9375 16.9822 10.9375 17.5L10.9375 21.25C10.9375 21.7678 10.5178 22.1875 10 22.1875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 22.1875C14.4822 22.1875 14.0625 21.7678 14.0625 21.25L14.0625 8.75C14.0625 8.23223 14.4822 7.8125 15 7.8125C15.5178 7.8125 15.9375 8.23223 15.9375 8.75L15.9375 21.25C15.9375 21.7678 15.5178 22.1875 15 22.1875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 22.1875C19.4822 22.1875 19.0625 21.7678 19.0625 21.25L19.0625 12.5C19.0625 11.9822 19.4822 11.5625 20 11.5625C20.5178 11.5625 20.9375 11.9822 20.9375 12.5L20.9375 21.25C20.9375 21.7678 20.5178 22.1875 20 22.1875Z"
      />
    </svg>
  );
};

export default Reports;
