import { useEffect } from "react";
import classes from "./ProjectsPage.module.scss";
import Projects from "components/projects/Projects";

const ProjectsPage = () => {
  useEffect(() => {
    document.title = "ERP - My projects";
  }, []);

  return (
    <section className={classes.mainContainer}>
      <Projects />
    </section>
  );
};

export default ProjectsPage;
